import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getProfileForStudent = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentProfile/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentProfile - " + error.message)
            console.log('error caught in service : getStudentProfile')
        },
    );

export const getStudentProfile = (studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentProfile/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentProfile - " + error.message)
            console.log('error caught in service : getStudentProfile')
        },
    );
export const getStudentDetailsBySectionDetailsId = (batchMasterId: any, sectionDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetailsBySectionDetailsId/${getLicenseKey}/${batchMasterId}/${sectionDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsBySectionDetailsId - " + error.message)
            console.log('error caught in service : getStudentDetailsBySectionDetailsId')
        },
    );

export const getAllStudent = (staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetails/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetails - " + error.message)
            console.log('error caught in service : getStudentDetails')
        },
    );

export const addTempStudentDetails = (tempStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addTempStudentDetails`, tempStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addTempStudentDetails')
        }
    );

export const getTempStudentList = (tempStudentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTempStudentList/${getLicenseKey}/${tempStudentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getTempStudentList - " + error.message)
            console.log('error caught in service : getTempStudentList')
        },
    );

export const updateTempStudentDetails = (tempStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateTempStudentDetails`, tempStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateTempStudentDetails')
        }
    );

export const deleteTempStudentDetails = (tempStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteTempStudentDetails`, tempStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteTempStudentDetails')
        }
    );

export const addStudentDetails = (studentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentDetails`, studentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentDetails')
        }
    );

export const getStudentTempListByStandardDetailsId = (mediumId: number, standardDetailsId: any, courseDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentTempListByStandardDetailsId/${getLicenseKey}/${mediumId}/${standardDetailsId}/${courseDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentTempListByStandardDetailsId - " + error.message)
            console.log('error caught in service : getStudentTempListByStandardDetailsId')
        },
    );

export const checkClassCapacity = (courseCapacityStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `checkClassCapacity`, courseCapacityStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : checkClassCapacity')
        }
    );

export const deleteStudentDetails = (deleteStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStudentDetails`, deleteStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStudentDetails')
        }
    );
export const getStudentTransportDetails = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentTransportDetails/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentTransportDetails - " + error.message)
            console.log('error caught in service : getStudentTransportDetails')
        },
    );

export const addStudentTransportDetails = (addStudentTransportDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentTransportDetails`, addStudentTransportDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentTransportDetails')
        }
    );
export const getStudentListByGenderId = (genderId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentListByGenderId/${getLicenseKey}/${genderId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentListByGenderId - " + error.message)
            console.log('error caught in service : getStudentListByGenderId')
        },
    );

export const getDiscontinuedStudentList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDiscontinuedStudentList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDiscontinuedStudentList - " + error.message)
            console.log('error caught in service : getDiscontinuedStudentList')
        },
    );

export const rejoinStudentDetails = (rejoinedStudentFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `rejoinStudentDetails`, rejoinedStudentFormPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : rejoinStudentDetails')
        }
    );

export const getAluminiStudentDetails = (batchMasterId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAluminiStudentDetails/${getLicenseKey}/${batchMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAluminiStudentDetails - " + error.message)
            console.log('error caught in service : getAluminiStudentDetails')
        },
    );

export const updateRollNumberForStudent = (editRollNumberPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateRollNumberForStudent`, editRollNumberPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateRollNumberForStudent')
        }
    );

export const updateStudentDetails = (updateStudentDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateStudentDetails`, updateStudentDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentDetails')
        }
    );

export const getStudentDetailsByStudentDetailsId = (studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetailsByStudentDetailsId/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsByStudentDetailsId - " + error.message)
            console.log('error caught in service : getStudentDetailsByStudentDetailsId')
        },
    );

export const updateStudentStandard = (updateStudentStandardPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentStandard`, updateStudentStandardPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentStandard')
        }
    );

export const getQuizDetailsForStudent = (studentDetailsId: any, subjectPaperDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getQuizDetailsForStudent/${getLicenseKey}/${studentDetailsId}/${subjectPaperDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getQuizDetailsForStudent - " + error.message)
            console.log('error caught in service : getQuizDetailsForStudent')
        },
    );

export const addStudentQuizMarkDetails = (addStudentQuizMarkPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentQuizMarkDetails`, addStudentQuizMarkPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentQuizMarkDetails')
        }
    );

export const getComplaintDetails = (userTypeId: any, staffOrStudentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getComplaintDetails/${getLicenseKey}/${userTypeId}/${staffOrStudentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getComplaintDetails - " + error.message)
            console.log('error caught in service : getComplaintDetails')
        },
    );

export const getStudentExamMarkDetails = (standardDetailsId: any, sectionDetailsId: any, studentDetailsId: any, examDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentExamMarkDetails/${getLicenseKey}/${standardDetailsId}/${sectionDetailsId}/${studentDetailsId}/${examDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentExamMarkDetails - " + error.message)
            console.log('error caught in service : getStudentExamMarkDetails')
        },
    );

export const getStudentSkills = (studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentSkills/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentSkills - " + error.message)
            console.log('error caught in service : getStudentSkills')
        },
    );

export const deleteStudentSkills = (studentSkillsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStudentSkills`, studentSkillsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStudentSkills')
        }
    );

export const addCalenderEventForStudent = (calenderPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addCalenderEventForStudent`, calenderPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addCalenderEventForStudent')
        }
    );

export const getCalenderEventForStudent = (studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getCalenderEventForStudent/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getCalenderEventForStudent - " + error.message)
            console.log('error caught in service : getCalenderEventForStudent')
        },
    );

export const updateCalenderEventForStudent = (eventPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateCalenderEventForStudent`, eventPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateCalenderEventForStudent')
        }
    );

export const deleteStudentLeave = (studentLeavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStudentLeave`, studentLeavePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStudentLeave')
        }
    );

export const addStudentLeave = (studentLeavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentLeave`, studentLeavePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentLeave')
        }
    );

export const getStudentLeaveDetails = (studentDetailsId: any, studentLeaveDetailId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getStudentLeaveDetails/${getLicenseKey}/${studentDetailsId}/${studentLeaveDetailId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentLeaveDetails - " + error.message)
            console.log('error caught in service : getStudentLeaveDetails')
        },
    );

export const updateStudentLeave = (studentLeavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateStudentLeave`, studentLeavePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentLeave')
        }
    );

export const deleteStudentHobbieDetails = (StudentHobbyPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStudentHobbieDetails`, StudentHobbyPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStudentHobbieDetails')
        }
    );
export const deleteCalenderEventForStudent = (eventPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteCalenderEventForStudent`, eventPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteCalenderEventForStudent')
        }
    );