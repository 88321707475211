import { useContext, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { toasts } from '../../../services/toast.service';
import Card from '../../bootstrap/Card';
import './newsContainer.css'
import { getAnnouncementDetails } from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';

function Announcement() {

    const { userAccountId, userData, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        getAnnouncementList();
    }, []);

    const [announcementData, setAnnouncementData] = useState<any>([])

    function getAnnouncementList() {
        getAnnouncementDetails(userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.announcementDetails;
                    if (data != undefined) {
                        let staffAnnouncementData = data.filter((item: any) => (item.userTypeId != 9 && item.userTypeId != 10) || item.userTypeId == 0)
                        let announcementData = data.filter((item: any) => (item.userTypeId == 9 || item.userTypeId == 10))
                        setAnnouncementData(userTypeId == 9 || userTypeId == 10 ? announcementData : staffAnnouncementData);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAnnouncementData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    const { darkModeStatus } = useDarkMode();

    return (
        <>
            <Card className='border border-light rounded-0'>
                <div className="news-container">
                    <div className="title">
                        Announcement
                    </div>
                    <ul>
                        {announcementData != '' ?
                            <>
                                {announcementData.map((item: any) =>
                                    <li key={item.announcementDetailsId}>
                                        <span style={{ fontFamily: '-moz-initial' }} className={`fs-5 fw-bold ${darkModeStatus ? 'text-white' : 'text-dark'}`}>Dear {item.userTypeId > 0 ? item.userType : 'Staff'}, {item.title}</span>
                                    </li>
                                )}
                            </>
                            :
                            <li>
                                <b>No New Announcements</b>
                            </li>
                        }
                    </ul>
                </div>
            </Card>
        </>
    )
}
export default Announcement;