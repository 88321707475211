import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getAdmissionFormFee = (mediumId: number, standardDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAdmissionFormFee/${getLicenseKey}/${mediumId}/${standardDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAdmissionFormFee - " + error.message)
            console.log('error caught in service : getAdmissionFormFee')
        },
    );

export const addAdmissionFormFee = (admissionFeeMasterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addAdmissionFormFee`, admissionFeeMasterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addAdmissionFormFee')
        }
    );

export const updateAdmissionFormFee = (admissionFeeMasterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateAdmissionFormFee`, admissionFeeMasterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateAdmissionFormFee')
        }
    );

export const deleteAdmissionFormFee = (admissionFeeMasterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteAdmissionFormFee`, admissionFeeMasterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteAdmissionFormFee')
        }
    );


export const addFeeMaster = (feeMasterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addFeeMaster`, feeMasterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addFeeMaster')
        }
    );

export const getFeeMaster = (mediumId: any, standardDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getFeeMaster/${getLicenseKey}/${mediumId}/${standardDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getFeeMaster - " + error.message)
            console.log('error caught in service : getFeeMaster')
        },
    );

export const updateFeeMaster = (editFeeMasterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateFeeMaster`, editFeeMasterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateFeeMaster')
        }
    );

export const deleteFeeMaster = (deletePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteFeeMaster`, deletePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteFeeMaster')
        }
    );

export const addFeeType = (feeTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addFeeType`, feeTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addFeeType')
        }
    );

export const getFeeType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getFeeType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getFeeType - " + error.message)
            console.log('error caught in service : getFeeType')
        },
    );

export const getStudentTotalFeeCollection = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentTotalFeeCollection/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentTotalFeeCollection - " + error.message)
            console.log('error caught in service : getStudentTotalFeeCollection')
        },
    );

export const updateFeeType = (feeTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateFeeType`, feeTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateFeeType')
        }
    );

export const deleteFeeType = (feeTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteFeeType`, feeTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteFeeType')
        }
    );

export const getStudentFeeCollection = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentFeeCollection/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentFeeCollection - " + error.message)
            console.log('error caught in service : getStudentFeeCollection')
        },
    );

export const getStudentFeeDetails = (userTypeId: number, studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentFeeDetails/${getLicenseKey}/${userTypeId}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentFeeDetails - " + error.message)
            console.log('error caught in service : getStudentFeeDetails')
        },
    );

export const getNotAssignedTermFees = (studentDetailsId: number, feeTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getNotAssignedTermFees/${getLicenseKey}/${studentDetailsId}/${feeTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getNotAssignedTermFees - " + error.message)
            console.log('error caught in service : getNotAssignedTermFees')
        },
    );

export const addStudentFeeCollection = (payFeesPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentFeeCollection`, payFeesPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentFeeCollection')
        }
    );

export const addStudentFineFee = (payFeesPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentFineFee`, payFeesPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentFineFee')
        }
    );

export const addStudentAdvanceFee = (payAdvanceFeesPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentAdvanceFee`, payAdvanceFeesPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentAdvanceFee')
        }
    );

export const getFineFeeType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getFineFeeType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getFineFeeType - " + error.message)
            console.log('error caught in service : getFineFeeType')
        },
    );

export const getExtraFeeType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExtraFeeType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getExtraFeeType - " + error.message)
            console.log('error caught in service : getExtraFeeType')
        },
    );

export const getStudentFineFeeList = (studentDetailsId: number, fineTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentFineFeeList/${getLicenseKey}/${studentDetailsId}/${fineTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentFineFeeList - " + error.message)
            console.log('error caught in service : getStudentFineFeeList')
        },
    );

export const addExtraFeeDetails = (extraFeePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addExtraFeeDetails`, extraFeePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addExtraFeeDetails')
        }
    );

export const getFeeCollectionDetailsByFeeCollectionId = (studentFeeCollectionId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getFeeCollectionDetailsByFeeCollectionId/${getLicenseKey}/${studentFeeCollectionId}`,
        response => {
            success(response)
        },
        error => {
            failure("getFeeCollectionDetailsByFeeCollectionId - " + error.message)
            console.log('error caught in service : getFeeCollectionDetailsByFeeCollectionId')
        },
    );

export const deleteReceiptDetails = (feeReceiptPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteReceiptDetails`, feeReceiptPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteReceiptDetails')
        }
    );

export const updReceiptDetailsByAdmin = (updateFeeReceiptPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updReceiptDetailsByAdmin`, updateFeeReceiptPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updReceiptDetailsByAdmin')
        }
    );

export const addFineFeeType = (fineFeePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addFineFeeType`, fineFeePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addFineFeeType')
        }
    );

export const updateFineFeeType = (fineTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateFineFeeType`, fineTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateFineFeeType')
        }
    );

export const deleteFineFeeType = (fineTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteFineFeeType`, fineTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteFineFeeType')
        }
    );



export const getAluminiStudentFeeCollection = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentFeeCollection/${getLicenseKey}/${studentDetailsId}/1`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentFeeCollection - " + error.message)
            console.log('error caught in service : getStudentFeeCollection')
        },
    );

export const getStudentSchoolSubject = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentSchoolSubject/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentSchoolSubject - " + error.message)
            console.log('error caught in service : getStudentSchoolSubject')
        },
    );

export const addStudentSchoolSubject = (subjectPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentSchoolSubject`, subjectPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentSchoolSubject')
        }
    );

export const updateStudentSchoolSubject = (subjectPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentSchoolSubject`, subjectPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentSchoolSubject')
        }
    );

export const deleteStudentSchoolSubject = (subjectPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStudentSchoolSubject`, subjectPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStudentSchoolSubject')
        }
    );

export const addExtraFeeType = (fineFeePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addExtraFeeType`, fineFeePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addExtraFeeType')
        }
    );

export const updateExtraFeeType = (fineTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateExtraFeeType`, fineTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateExtraFeeType')
        }
    );

export const deleteExtraFeeType = (fineTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteExtraFeeType`, fineTypePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteExtraFeeType')
        }
    );

export const getAttendanceReportByStudentDetailsId = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAttendanceReportByStudentDetailsId/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAttendanceReportByStudentDetailsId - " + error.message)
            console.log('error caught in service : getAttendanceReportByStudentDetailsId')
        },
    );

export const getStudentFeeDetailsHistory = (studentFeeDetailsId: number, isFeesDetails: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentFeeDetailsHistory/${getLicenseKey}/${studentFeeDetailsId}/${isFeesDetails}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentFeeDetailsHistory - " + error.message)
            console.log('error caught in service : getStudentFeeDetailsHistory')
        },
    );

export const addOrUpdStudentFeeDetails = (addOrUpdateStudentFeeDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addOrUpdStudentFeeDetails`, addOrUpdateStudentFeeDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addOrUpdStudentFeeDetails')
        }
    );
