import { useContext, useEffect, useRef, useState } from "react";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Button from "../../../bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Input from "../../../bootstrap/forms/Input";
import { useFormik } from "formik";
import AlertService from "../../../../services/AlertService";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../../bootstrap/OffCanvas";
import { getCity, getCourseDetails, getGenderDetails, getMedium, getStandardDetails, getStates, onlyAllowNumber, pictNotLoading, profilePic } from "../../../../services/common.service";
import AuthContext from "../../../../contexts/authContext";
import { getLicenseKey } from "../../../../services/application.settings";
import { addAdmissionFormFee, getAdmissionFormFee } from "../../../../services/fee.service";
import Spinner from "../../../bootstrap/Spinner";
import { useHoverDirty } from "react-use";
import { showLoader } from "../../../../services/loader.services";
import { toasts } from "../../../../services/toast.service";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import Icon from "../../../icon/Icon";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import { addTempStudentDetails } from "../../../../services/student.service";

const TempStudentCreation = () => {

    useEffect(() => {
        getMediumList();
        getGender();
        getStatesList();
        getCityList(31)
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [stateId, setStateId] = useState<any>({ value: 31, label: 'Tamil Nadu' })
    const [cityId, setCityId] = useState<any>('')
    const [genderId, setGenderId] = useState<any>([])

    const [genderData, setGenderData] = useState<any>([])
    const [statesData, setStatesData] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    const [alreadyExitStudentModal, setAlreadyExitStudentModal] = useState(false)

    //ForCanvas
    const [addAdmissionCanvas, setAddAdmissionCanvas] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);

    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef<HTMLAnchorElement>(null);
    const isHovering = useHoverDirty(ref);

    const [alreadyExitStudentMsg, setAlreadyExitStudentMsg] = useState('')
    const [addDuplicateRecord, setAddDuplicateRecord] = useState(0)

    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [mediumId, setMediumId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([])
    const [courseDetailsId, setCourseDetailsId] = useState<any>('')
    const [courseDetailsData, setCourseDetailsData] = useState<any>([])

    const addTempStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: '',
            lastname: '',
            genderId: '',
            mobile: '',
            mediumId: '',
            standardDetailsId: '',
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: 0,
            commAddr_DistrictId: 0,
            commAddr_pincode: '',
            regNumber: '',
            scoredMark: '',
            govtOrPvt: '',
            schoolNameId: 0,
            collegeNameId: 0,
            collegeName: '',
            schoolName: '',
            addDuplicateRecord: 0,
            courseDetailsId: ''
        },
        validate: (values: any) => {
            const errors: {
                firstname?: string;
                genderId?: any;
                standardDetailsId?: any;
                mediumId?: any;
                mobile?: any;
                commAddr_FlatNo?: string;
                commAddr_Street?: string;
                commAddr_Area?: string;
                commAddr_StateId?: any;
                commAddr_DistrictId?: any;
                commAddr_pincode?: string;
                courseDetailsId?: string;
            } = {};

            if (!values.firstname) {
                errors.firstname = 'Required';
            } else if (values.firstname.length < 3) {
                errors.firstname = 'Must be 3 characters or more';
            } else if (values.firstname.length > 20) {
                errors.firstname = 'Must be 20 characters or less';
            }
            if (!genderId) {
                errors.genderId = 'Required';
            }
            if (!mediumId) {
                errors.mediumId = 'Required';
            }
            if (!standardDetailsId) {
                errors.standardDetailsId = 'Required';
            }
            if (!courseDetailsId && (standardDetailsId?.value == 11 || standardDetailsId?.value == 12)) {
                errors.courseDetailsId = 'Required';
            }
            if (!values.mobile) {
                errors.mobile = 'Required';
            } else if (values.mobile.length < 10 || values.mobile.length > 10) {
                errors.mobile = 'Must be 10 Numbers'
            } else if (!/^[6-9]\d{9}$/i.test(values.mobile)) {
                errors.mobile = 'Please enter valid mobile number'
            }
            if (!values.commAddr_Street) {
                errors.commAddr_Street = 'Required';
            }
            if (!values.commAddr_FlatNo) {
                errors.commAddr_FlatNo = 'Required';
            }
            if (!values.commAddr_Area) {
                errors.commAddr_Area = 'Required';
            }
            if (!stateId) {
                errors.commAddr_StateId = 'Required';
            }
            if (!cityId) {
                errors.commAddr_DistrictId = 'Required';
            }
            if (!values.commAddr_pincode) {
                errors.commAddr_pincode = 'Required';
            }
            else if (!/^\d{6}$/.test(values.commAddr_pincode)) {
                errors.commAddr_pincode = 'Must be 6 Numbers';
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: (values) => { addTempStudentSubmit() },
    });

    const admissionFeeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            mediumId: '',
            standardDetailsId: '',
            amount: '',
        },
        validate: (values: any) => {
            const errors: {
                mediumId?: string;
                standardDetailsId?: string;
                amount?: any;
            } = {};

            if (!mediumId) {
                errors.mediumId = 'Required';
            }
            if (!standardDetailsId) {
                errors.standardDetailsId = 'Required';
            }
            if (!values.amount) {
                errors.amount = 'Required';
            }

            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { admissionFeeMasterSubmit() },
    });

    const selectMedium = (e: any) => {
        setCourseDetailsId('')
        setStandardData([])
        setStandardDetailsId('')
        let mediumId = e
        setMediumId(mediumId)
        if (mediumId?.value != undefined) {
            getStandardList();
        }
    }

    const selectStandard = (e: any) => {
        setCourseDetailsId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (standardDetailsId?.value != undefined) {
            checkAdmissionFees(mediumId?.value, standardDetailsId?.value)

            if (standardDetailsId?.value == 11 || standardDetailsId?.value == 12) {
                getCourseList()
            }
        }
    }

    const selectCourse = (e: any) => {
        let courseDetailsId = e
        setCourseDetailsId(courseDetailsId)
    }

    const selectState = (e: any) => {
        setCityId('')
        setCityData([])
        let commAddr_StateId = e
        setStateId(commAddr_StateId)
        if (commAddr_StateId?.value != undefined) {
            getCityList(commAddr_StateId?.value)
        }
    }

    const selectCity = (e: any) => {
        let commAddr_DistrictId = e
        setCityId(commAddr_DistrictId)
    }

    const selectGender = (e: any) => {
        let genderId = e
        setGenderId(genderId)
    }

    function setTempStudentDetails() {
        return ({
            firstname: addTempStudentForm.values.firstname,
            lastname: addTempStudentForm.values.lastname,
            genderId: genderId?.value,
            standardDetailsId: standardDetailsId?.value,
            courseDetailsId: courseDetailsId?.value != undefined ? courseDetailsId?.value : null,
            mediumId: mediumId?.value,
            mobile: addTempStudentForm.values.mobile,
            commAddr_FlatNo: addTempStudentForm.values.commAddr_FlatNo,
            commAddr_Street: addTempStudentForm.values.commAddr_Street,
            commAddr_Area: addTempStudentForm.values.commAddr_Area,
            commAddr_StateId: stateId?.value,
            commAddr_DistrictId: cityId?.value,
            commAddr_pincode: addTempStudentForm.values.commAddr_pincode,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function addTempStudentSubmit() {
        showLoader(true)
        if (addTempStudentForm.isValid) {
            let tempStudentPostData = setTempStudentDetails()
            addTempStudentDetails(tempStudentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeModal();
                        setIsNavigate(`../academic/tempStudentList`)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlreadyExitStudentModal(true)
                        setAlreadyExitStudentMsg(data.message)
                        setAddDuplicateRecord(1)
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addTempStudentForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList() {
        getCourseDetails(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.courseDetails;
                    if (data != undefined) {
                        setCourseDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setCourseDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGender() {
        getGenderDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderDetails;
                    if (data != undefined) {
                        setGenderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGenderData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function checkAdmissionFees(mediumId: any, standardDetailsId: any) {
        getAdmissionFormFee(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.admissionFormFee;
                    if (data != undefined) {
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setAddAdmissionCanvas(true);
                    admissionFeeForm.setValues({
                        mediumId: mediumId?.value != null ? mediumId?.value : '',
                        standardDetailsId: standardDetailsId?.value != null ? standardDetailsId?.value : '',
                        amount: '',
                    })
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setAdmissionFeeFormDetails() {
        return {
            mediumId: mediumId?.value,
            standardDetailsId: standardDetailsId?.value,
            amount: admissionFeeForm.values.amount,
            particulars: "Application Form Fees",
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
        }
    }

    function admissionFeeMasterSubmit() {
        showLoader(true);
        if (admissionFeeForm.isValid) {
            let admissionFeeMasterPostData = setAdmissionFeeFormDetails()
            addAdmissionFormFee(admissionFeeMasterPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(null)
                        admissionFeeForm.resetForm();
                        setAddAdmissionCanvas(false)

                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )
        } else if (admissionFeeForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    function closeModal() {
        addTempStudentForm.resetForm();
        admissionFeeForm.resetForm();
        setAlreadyExitStudentModal(false);
        resetState();
    }

    function resetState() {
        setStandardDetailsId('')
        setMediumId('')
        setStateId('')
        setCityId('')
        setGenderId('')
        setCourseDetailsId('')
    }

    function closePopup() {
        setAlreadyExitStudentModal(false);
        setAddDuplicateRecord(0)
    }

    return (
        <PageWrapper title="Temp Student Creation">
            <Page container='fluid'>
                <Card stretch data-tour='list'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='PersonAdd' iconColor='info'>
                            <CardTitle tag='div' className='h5'>
                                Temp Student Creation
                            </CardTitle>
                        </CardLabel>
                        <CardActions>
                            <Button color='info' isLight icon={isLoading ? undefined : 'PublishedWithChanges'} onClick={() => { ref?.current?.blur(); closeModal() }}>
                                {isLoading && (
                                    <Spinner color={isHovering ? 'light' : 'info'} inButton isSmall>
                                        Loading...
                                    </Spinner>
                                )}
                                Refresh
                            </Button>
                        </CardActions>
                    </CardHeader>

                    <CardBody>
                        <form noValidate onSubmit={addTempStudentForm.handleSubmit}>
                            {/* <div className='row g-4'>
                                <div className="col-md-12">
                                    <CardLabel icon='AccountBox'>
                                        <CardTitle>Basic Info</CardTitle>
                                    </CardLabel>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup id='firstname' label='First Name' isFloating>
                                        <Input
                                            placeholder='First Name'
                                            onChange={addTempStudentForm.handleChange}
                                            onBlur={addTempStudentForm.handleBlur}
                                            value={addTempStudentForm.values.firstname}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.firstname}
                                            invalidFeedback={addTempStudentForm.errors.firstname}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup id='lastname' label='Last Name' isFloating>
                                        <Input
                                            placeholder='Last Name'
                                            onChange={addTempStudentForm.handleChange}
                                            value={addTempStudentForm.values.lastname}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup id='genderId' label='Gender' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Gender'
                                            onChange={selectGender}
                                            onBlur={addTempStudentForm.handleBlur}
                                            value={genderId}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.genderId}
                                            invalidFeedback={addTempStudentForm.errors.genderId}
                                            list={genderData.map((data: any) => (
                                                { value: data.genderId, label: data.gender }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup id='mobile' label='Mobile No ' isFloating>
                                        <Input
                                            placeholder='Mobile No'
                                            onKeyDown={(e: any) => onlyAllowNumber(e)}
                                            onChange={addTempStudentForm.handleChange}
                                            onBlur={addTempStudentForm.handleBlur}
                                            value={addTempStudentForm.values.mobile}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.mobile}
                                            invalidFeedback={addTempStudentForm.errors.mobile}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup id='mediumId' label='Medium' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Medium'
                                            onChange={selectMedium}
                                            onBlur={addTempStudentForm.handleBlur}
                                            value={mediumId}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.mediumId}
                                            invalidFeedback={addTempStudentForm.errors.mediumId}
                                            list={mediumData.map((data: any) => (
                                                { value: data.mediumId, label: data.medium }
                                            ))}
                                            required />
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Standard'
                                            onChange={selectStandard}
                                            onBlur={addTempStudentForm.handleBlur}
                                            value={standardDetailsId}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.standardDetailsId}
                                            invalidFeedback={addTempStudentForm.errors.standardDetailsId}
                                            list={standardData.map((data: any) => (
                                                { value: data.standardDetailsId, label: data.standardName }
                                            ))}
                                            required />
                                    </FormGroup>
                                </div>

                                {standardDetailsId?.value == 11 || standardDetailsId?.value == 12 ?
                                    <div className='col-md-3'>
                                        <FormGroup id='courseDetailsId' label='Course' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Course'
                                                onChange={selectCourse}
                                                onBlur={addTempStudentForm.handleBlur}
                                                value={courseDetailsId}
                                                isValid={addTempStudentForm.isValid}
                                                isTouched={addTempStudentForm.touched.courseDetailsId}
                                                invalidFeedback={addTempStudentForm.errors.courseDetailsId}
                                                list={courseDetailsData.map((data: any) => (
                                                    { value: data.courseDetailsId, label: data.courseName }
                                                ))}
                                                required />
                                        </FormGroup>
                                    </div> : null
                                }
                                <div className="col-md-12">
                                    <CardLabel icon='ReceiptLong'>
                                        <CardTitle>Address Info</CardTitle>
                                    </CardLabel>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup id='commAddr_FlatNo' label='Flat No\Door No' isFloating>
                                        <Input
                                            placeholder='Flat No\Door No'
                                            onChange={addTempStudentForm.handleChange}
                                            value={addTempStudentForm.values.commAddr_FlatNo}
                                            onBlur={addTempStudentForm.handleBlur}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.commAddr_FlatNo}
                                            invalidFeedback={addTempStudentForm.errors.commAddr_FlatNo}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup id='commAddr_Street' label='Street' isFloating>
                                        <Input
                                            placeholder='Street'
                                            onChange={addTempStudentForm.handleChange}
                                            value={addTempStudentForm.values.commAddr_Street}
                                            onBlur={addTempStudentForm.handleBlur}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.commAddr_Street}
                                            invalidFeedback={addTempStudentForm.errors.commAddr_Street}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <FormGroup id='commAddr_Area' label='Area' isFloating>
                                        <Input
                                            placeholder='Area'
                                            onChange={addTempStudentForm.handleChange}
                                            value={addTempStudentForm.values.commAddr_Area}
                                            onBlur={addTempStudentForm.handleBlur}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.commAddr_Area}
                                            invalidFeedback={addTempStudentForm.errors.commAddr_Area}
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-md-4'>
                                    <FormGroup id='commAddr_StateId' label='State' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='State'
                                            onChange={selectState}
                                            onBlur={addTempStudentForm.handleBlur}
                                            value={stateId}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.commAddr_StateId}
                                            invalidFeedback={addTempStudentForm.errors.commAddr_StateId}
                                            list={statesData.map((data: any) => (
                                                { value: data.stateId, label: data.stateName }
                                            ))}
                                            required />
                                    </FormGroup>
                                </div>

                                <div className='col-md-4'>
                                    <FormGroup id='commAddr_DistrictId' label='City' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='City'
                                            onChange={selectCity}
                                            value={cityId}
                                            onBlur={addTempStudentForm.handleBlur}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.commAddr_DistrictId}
                                            invalidFeedback={addTempStudentForm.errors.commAddr_DistrictId}
                                            list={cityData.map((data: any) => (
                                                { value: data.cityId, label: data.cityName }
                                            ))}
                                            required />
                                    </FormGroup>
                                </div>

                                <div className='col-md-4'>
                                    <FormGroup id='commAddr_pincode' label='Pincode' isFloating>
                                        <Input
                                            placeholder='Pincode'
                                            onKeyDown={(e: any) => onlyAllowNumber(e)}
                                            onChange={addTempStudentForm.handleChange}
                                            onBlur={addTempStudentForm.handleBlur}
                                            value={addTempStudentForm.values.commAddr_pincode}
                                            isValid={addTempStudentForm.isValid}
                                            isTouched={addTempStudentForm.touched.commAddr_pincode}
                                            invalidFeedback={addTempStudentForm.errors.commAddr_pincode}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-md-lg-12 d-flex justify-content-end">
                                    <Button icon="Save" color="info" type="submit" isDisable={!addTempStudentForm.isValid && !!addTempStudentForm.submitCount}>
                                        Save
                                    </Button>
                                </div>
                            </div> */}
                            <div className='row g-4'>
                                <div className='col-6'>
                                    <Card className='rounded-1 mb-2' borderSize={2} stretch>
                                        <CardHeader>
                                            <CardLabel icon='AccountBox'>
                                                <CardTitle>Basic Info</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row g-4">
                                                <div className='col-md-6'>
                                                    <FormGroup id='firstname' label='First Name' isFloating>
                                                        <Input
                                                            placeholder='First Name'
                                                            onChange={addTempStudentForm.handleChange}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            value={addTempStudentForm.values.firstname}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.firstname}
                                                            invalidFeedback={addTempStudentForm.errors.firstname}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-md-6'>
                                                    <FormGroup id='lastname' label='Last Name' isFloating>
                                                        <Input
                                                            placeholder='Last Name'
                                                            onChange={addTempStudentForm.handleChange}
                                                            value={addTempStudentForm.values.lastname}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-md-6'>
                                                    <FormGroup id='genderId' label='Gender' isFloating>
                                                        <SearchableSelect isFloating
                                                            ariaLabel='Gender'
                                                            onChange={selectGender}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            value={genderId}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.genderId}
                                                            invalidFeedback={addTempStudentForm.errors.genderId}
                                                            list={genderData.map((data: any) => (
                                                                { value: data.genderId, label: data.gender }
                                                            ))}
                                                            required />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-md-6'>
                                                    <FormGroup id='mobile' label='Mobile No ' isFloating>
                                                        <Input
                                                            placeholder='Mobile No'
                                                            onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                            onChange={addTempStudentForm.handleChange}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            value={addTempStudentForm.values.mobile}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.mobile}
                                                            invalidFeedback={addTempStudentForm.errors.mobile}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-md-6'>
                                                    <FormGroup id='mediumId' label='Medium' isFloating>
                                                        <SearchableSelect isFloating
                                                            ariaLabel='Medium'
                                                            onChange={selectMedium}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            value={mediumId}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.mediumId}
                                                            invalidFeedback={addTempStudentForm.errors.mediumId}
                                                            list={mediumData.map((data: any) => (
                                                                { value: data.mediumId, label: data.medium }
                                                            ))}
                                                            required />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-md-6'>
                                                    <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                                        <SearchableSelect isFloating
                                                            ariaLabel='Standard'
                                                            onChange={selectStandard}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            value={standardDetailsId}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.standardDetailsId}
                                                            invalidFeedback={addTempStudentForm.errors.standardDetailsId}
                                                            list={standardData.map((data: any) => (
                                                                { value: data.standardDetailsId, label: data.standardName }
                                                            ))}
                                                            required />
                                                    </FormGroup>
                                                </div>

                                                {/* {standardDetailsId?.value == 11 || standardDetailsId?.value == 12 ? */}
                                                <div className='col-md-6'>
                                                    <FormGroup id='courseDetailsId' label='Course' isFloating>
                                                        <SearchableSelect isFloating
                                                            ariaLabel='Course'
                                                            className={standardDetailsId?.value != 11 && standardDetailsId?.value != 12 ? "bg-light" : ''}
                                                            onChange={selectCourse}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            value={courseDetailsId}
                                                            disabled={standardDetailsId?.value != 11 && standardDetailsId?.value != 12}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.courseDetailsId}
                                                            invalidFeedback={addTempStudentForm.errors.courseDetailsId}
                                                            list={courseDetailsData.map((data: any) => (
                                                                { value: data.courseDetailsId, label: data.courseName }
                                                            ))}
                                                            required />
                                                    </FormGroup>
                                                </div>
                                                {/* : null
                                                } */}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-6'>
                                    <Card className='rounded-1 mb-2' borderSize={2} stretch>
                                        <CardHeader>
                                            <CardLabel icon='Place'>
                                                <CardTitle>Address Info</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row g-4">
                                                <div className='col-md-6'>
                                                    <FormGroup id='commAddr_FlatNo' label='Flat No\Door No' isFloating>
                                                        <Input
                                                            placeholder='Flat No\Door No'
                                                            onChange={addTempStudentForm.handleChange}
                                                            value={addTempStudentForm.values.commAddr_FlatNo}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.commAddr_FlatNo}
                                                            invalidFeedback={addTempStudentForm.errors.commAddr_FlatNo}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-md-6'>
                                                    <FormGroup id='commAddr_Street' label='Street' isFloating>
                                                        <Input
                                                            placeholder='Street'
                                                            onChange={addTempStudentForm.handleChange}
                                                            value={addTempStudentForm.values.commAddr_Street}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.commAddr_Street}
                                                            invalidFeedback={addTempStudentForm.errors.commAddr_Street}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-md-12'>
                                                    <FormGroup id='commAddr_Area' label='Area' isFloating>
                                                        <Input
                                                            placeholder='Area'
                                                            onChange={addTempStudentForm.handleChange}
                                                            value={addTempStudentForm.values.commAddr_Area}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.commAddr_Area}
                                                            invalidFeedback={addTempStudentForm.errors.commAddr_Area}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className='col-md-6'>
                                                    <FormGroup id='commAddr_StateId' label='State' isFloating>
                                                        <SearchableSelect isFloating
                                                            ariaLabel='State'
                                                            onChange={selectState}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            value={stateId}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.commAddr_StateId}
                                                            invalidFeedback={addTempStudentForm.errors.commAddr_StateId}
                                                            list={statesData.map((data: any) => (
                                                                { value: data.stateId, label: data.stateName }
                                                            ))}
                                                            required />
                                                    </FormGroup>
                                                </div>

                                                <div className='col-md-6'>
                                                    <FormGroup id='commAddr_DistrictId' label='City' isFloating>
                                                        <SearchableSelect isFloating
                                                            ariaLabel='City'
                                                            onChange={selectCity}
                                                            value={cityId}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.commAddr_DistrictId}
                                                            invalidFeedback={addTempStudentForm.errors.commAddr_DistrictId}
                                                            list={cityData.map((data: any) => (
                                                                { value: data.cityId, label: data.cityName }
                                                            ))}
                                                            required />
                                                    </FormGroup>
                                                </div>

                                                <div className='col-md-6'>
                                                    <FormGroup id='commAddr_pincode' label='Pincode' isFloating>
                                                        <Input
                                                            placeholder='Pincode'
                                                            onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                            onChange={addTempStudentForm.handleChange}
                                                            onBlur={addTempStudentForm.handleBlur}
                                                            value={addTempStudentForm.values.commAddr_pincode}
                                                            isValid={addTempStudentForm.isValid}
                                                            isTouched={addTempStudentForm.touched.commAddr_pincode}
                                                            invalidFeedback={addTempStudentForm.errors.commAddr_pincode}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>

                                <div className="col-md-lg-12 d-flex justify-content-end">
                                    <Button icon="Save" color="info" type="submit" isDisable={!addTempStudentForm.isValid && !!addTempStudentForm.submitCount}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Page>

            <OffCanvas
                setOpen={setAddAdmissionCanvas}
                isOpen={addAdmissionCanvas}
                titleId='addAdmissionCanvas'
                isNotClose
                tag="form" noValidate onSubmit={admissionFeeForm.handleSubmit}>
                <OffCanvasHeader>
                    <OffCanvasTitle id='addAdmissionCanvas'>Add Admission Form Fee</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-md-lg-12'>
                            <FormGroup id='mediumId' label='Medium' onBlur={admissionFeeForm.handleBlur}>
                                <SearchableSelect ariaLabel='Medium' placeholder="Medium"
                                    onChange={selectMedium}
                                    onBlur={admissionFeeForm.handleBlur}
                                    value={mediumId}
                                    isValid={admissionFeeForm.isValid}
                                    isTouched={admissionFeeForm.touched.mediumId} invalidFeedback={admissionFeeForm.errors.mediumId}
                                    list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} disabled />

                            </FormGroup>
                        </div>
                        <div className='col-md-lg-12'>
                            <FormGroup id='standardDetailsId' label='Standard' onBlur={admissionFeeForm.handleBlur}>
                                <SearchableSelect ariaLabel='Standard' placeholder="Standard"
                                    onChange={selectStandard}
                                    onBlur={admissionFeeForm.handleBlur}
                                    value={standardDetailsId}
                                    isValid={admissionFeeForm.isValid}
                                    isTouched={admissionFeeForm.touched.standardDetailsId} invalidFeedback={admissionFeeForm.errors.standardDetailsId}
                                    list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} disabled />

                            </FormGroup>
                        </div>
                        <div className="col-md-lg-12">
                            <FormGroup id="amount" label='Fee Amount'>
                                <Input type="text"
                                    onChange={admissionFeeForm.handleChange}
                                    onBlur={admissionFeeForm.handleBlur}
                                    value={admissionFeeForm.values.amount}
                                    isValid={admissionFeeForm.isValid}
                                    isTouched={admissionFeeForm.touched.amount}
                                    invalidFeedback={admissionFeeForm.errors.amount}
                                    onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-md-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button color='info' className='w-100' type="submit" icon="Save" isDisable={!admissionFeeForm.isValid && !!admissionFeeForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <Modal
                setIsOpen={setAlreadyExitStudentModal}
                isOpen={alreadyExitStudentModal}
                titleId='alreadyExitStudentModal'
                isStaticBackdrop
                fullScreen='md'
            >
                <ModalHeader className="'modal-header'"
                    setIsOpen={() => { setAlreadyExitStudentModal(false) }} onClick={closePopup}>
                    <ModalTitle id="alreadyExitStudentModal"> {""}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className='row g-2'>
                        <div className='col-12'>
                            <div className='d-flex align-items-center'>
                                <div className='flex-shrink-0'>
                                    <img style={{ borderRadius: '50%' }}
                                        src={profilePic(genderId?.value)}
                                        height='80' width='80' onError={(e: any) => pictNotLoading(e, genderId?.value)}
                                    />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='h2 fw-bold'>
                                        {addTempStudentForm.values.firstname} {addTempStudentForm.values.lastname}
                                    </div>
                                    <div className='h5 text-muted'>Student Name</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row g-2'>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon={genderId?.value == 1 ? 'Male' : 'Female'} size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {genderId?.label}
                                            </div>
                                            <div className='text-muted'>
                                                Gender
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon
                                                icon='PhoneIphone'
                                                size='3x'
                                                color='info'
                                            />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {addTempStudentForm.values.mobile}
                                            </div>
                                            <div className='text-muted'>Phone</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='school' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {mediumId?.value != undefined ? mediumId.label : ''}
                                            </div>
                                            <div className='text-muted'>Medium</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='HomeWork' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {standardDetailsId?.value != undefined ? standardDetailsId.label : ''}
                                            </div>
                                            <div className='text-muted'>Standard</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='SpeakerGroup' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {courseDetailsId?.value != undefined ? courseDetailsId.label : ''}
                                            </div>
                                            <div className='text-muted'>Course</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Badge' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {addTempStudentForm.values.regNumber}
                                            </div>
                                            <div className='text-muted'>Register No</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Subject' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {addTempStudentForm.values.scoredMark != '' ? addTempStudentForm.values.scoredMark : 0}
                                            </div>
                                            <div className='text-muted'>Scored Mark</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon
                                                icon='Book'
                                                size='3x'
                                                color='info'
                                            />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {addTempStudentForm.values.govtOrPvt != '' ? addTempStudentForm.values.govtOrPvt : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                            </div>
                                            <div className='text-muted'>Government / Private</div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* {showCollegeInfo ?
                                    <div className='col-12'>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <Icon
                                                    icon='AccountBalance'
                                                    size='3x'
                                                    color='info'
                                                />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 mb-0'>
                                                    {collegeNameId?.value != undefined ? collegeNameId?.label : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                </div>
                                                <div className='text-muted'>College Name</div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-12'>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <Icon
                                                    icon='AccountBalance'
                                                    size='3x'
                                                    color='info'
                                                />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 mb-0'>
                                                    {schoolNameId?.value != undefined ? schoolNameId?.label : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                </div>
                                                <div className='text-muted'>School Name</div>
                                            </div>
                                        </div>
                                    </div>} */}

                                <div className='col-12'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Home' size='3x' color='info' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>
                                                {`${addTempStudentForm.values.commAddr_FlatNo},`}
                                                {`${addTempStudentForm.values.commAddr_Street},`}
                                                {`${addTempStudentForm.values.commAddr_Area},`}
                                                {`${stateId?.label},`}
                                                {`${cityId?.label},`}
                                                {`${addTempStudentForm.values.commAddr_pincode},`}
                                            </div>
                                            <div className='text-muted'>
                                                Address
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-12 align-middle'>
                            <h5 className="main-content-label mb-1 fw-bold text-center text-danger">{alreadyExitStudentMsg}</h5>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='info' isOutline className='border-0' onClick={closePopup}>Cancel</Button>
                    <Button color='info' icon='Save'
                        onClick={addTempStudentSubmit}
                    >Create </Button>
                </ModalFooter>
            </Modal>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

        </PageWrapper>
    )
}

export default TempStudentCreation;