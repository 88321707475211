import { AxiosResponse } from 'axios';
import service from '../api/api.services';
import { getAPIURL, getLicenseKey } from './application.settings';

const api = service();

export const checkIsWorkingDay = (dateInMilliSec: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `checkIsWorkingDay/${getLicenseKey}/${dateInMilliSec}`,
        response => {
            success(response)
        },
        error => {
            failure("checkIsWorkingDay - " + error.message)
            console.log('error caught in service : checkIsWorkingDay')
        },
    );

export const addStudentAttendanceForWeb = (studentAttendancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentAttendanceForWeb`, studentAttendancePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentAttendanceForWeb')
        }
    );

export const getStudentAttendance = (dateInMilliSec: any, sectionDetailsId: any, periodDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentAttendance/${getLicenseKey}/${dateInMilliSec}/${sectionDetailsId}/${periodDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentAttendance - " + error.message)
            console.log('error caught in service : getStudentAttendance')
        },
    );

export const getStudentAttendanceSummaryReport = (dateInMilliSec: any, sectionDetailsId: any, shiftId: any, periodDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentAttendanceSummaryReport/${getLicenseKey}/${dateInMilliSec}/${sectionDetailsId}/${shiftId}/${periodDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentAttendanceSummaryReport - " + error.message)
            console.log('error caught in service : getStudentAttendanceSummaryReport')
        },
    );

export const getStudentAttendanceReport = (dateInMilliSec: any, sectionDetailsId: any, periodDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentAttendanceReportList/${getLicenseKey}/${dateInMilliSec}/${sectionDetailsId}/${periodDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentAttendanceReportList - " + error.message)
            console.log('error caught in service : getStudentAttendanceReportList')
        },
    );

export const getStudentLateAttendanceList = (staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentLateAttendanceList/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentLateAttendanceList - " + error.message)
            console.log('error caught in service : getStudentLateAttendanceList')
        },
    );

export const addStudentLateAttendance = (attendancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentLateAttendance`, attendancePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentLateAttendance')
        }
    );

export const updateLateAttendance = (attendanceReasonPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateLateAttendance`, attendanceReasonPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateLateAttendance')
        }
    );

export const getLateAttendanceReason = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLateAttendanceReason/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getLateAttendanceReason - " + error.message)
            console.log('error caught in service : getLateAttendanceReason')
        },
    );

export const updateLateAttendanceReason = (attendanceReasonPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateLateAttendanceReason`, attendanceReasonPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateLateAttendanceReason')
        }
    );

export const addStudentLateAttendanceReason = (attendanceReasonPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentLateAttendanceReason`, attendanceReasonPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentLateAttendanceReason')
        }
    );

export const getStudentAttendanceDetails = (studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentAttendanceDetails/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentAttendanceDetails - " + error.message)
            console.log('error caught in service : getStudentAttendanceDetails')
        },
    );

export const addStaffAttendance = (attendancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffAttendance`, attendancePostData,
        response => {
            success(response)
        },
        error => {
            failure("addStaffAttendance - " + error.message)
            console.log('error caught in service : addStaffAttendance')
        },
    );


export const getStaffAttendanceReportList = (dateOfAttendance: any, userTypeId: any, isReport: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffAttendanceReportList/${getLicenseKey}/${dateOfAttendance}/${userTypeId}/${isReport}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffAttendanceReportList - " + error.message)
            console.log('error caught in service : getStaffAttendanceReportList')
        },
    );

export const getStaffAttendanceSummaryReport = (dateOfAttendance: any, userTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffAttendanceSummaryReport/${getLicenseKey}/${dateOfAttendance}/${userTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffAttendanceSummaryReport - " + error.message)
            console.log('error caught in service : getStaffAttendanceSummaryReport')
        },
    );

export const deleteLateAttendanceReason = (attendanceReasonPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteLateAttendanceReason`, attendanceReasonPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteLateAttendanceReason')
        }
    );

export const getStaffAttendanceDetails = (staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffAttendanceDetails/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffAttendanceDetails - " + error.message)
            console.log('error caught in service : getStaffAttendanceDetails')
        },
    );
export const getStaffAttendancePercentageForMonth = (staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffAttendancePercentageForMonth/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffAttendancePercentageForMonth - " + error.message)
            console.log('error caught in service : getStaffAttendancePercentageForMonth')
        },
    );