import React, { useEffect, useState } from 'react';

import Card, {
  CardActions,
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../../components/bootstrap/Card';
import useDarkMode from '../../../../hooks/useDarkMode';
import Icon from '../../../../components/icon/Icon';
import { toasts } from '../../../../services/toast.service';
import { getStudentFeeTransactionDetails } from '../../../../services/dashboard.service';
import Button from '../../../../components/bootstrap/Button';
import useSortableData from '../../../../hooks/useSortableData';
import { dataPagination } from '../../../../components/PaginationButtons';
import NoDataMsg from '../../../../common/components/NoDataMsg';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { priceFormat } from '../../../../helpers/helpers';

const StudentFeeTransaction = () => {

  useEffect(() => {
    getStudentFeeTransaction();
  }, []);

  const { darkModeStatus } = useDarkMode();
  const [dataStatus, setDataStatus] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [studentFeeTransactionData, setStudentFeeTransactionData] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const { items, requestSort, getClassNamesFor } = useSortableData(studentFeeTransactionData);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noDataMsg, setNoDataMsg] = useState('')

  const navigate = useNavigate();

  const handleOpenModal = () => {
    navigate(`../reports/allTransaction`);
  };

  const colors = ['info', 'success', 'warning', 'primary', 'secondary'];

  function getStudentFeeTransaction() {
    setDataStatus(false);
    getStudentFeeTransactionDetails(0,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.studentFeeTransactionDetails;
          if (data != undefined) {
            setIsLoader(false);
            setDataStatus(true);
            for (let i = 0; i < data.length; i++) {
              data[i].color = colors[i];
            }
            setStudentFeeTransactionData(data);
          } else {
            setDataStatus(true);
            toasts('Undefined Data', 'Error');
          }
        } else if (response.data.success === false) {
          setDataStatus(true);
          // toasts(response.data.message, 'Error');
          setStudentFeeTransactionData([]);
          setNoDataMsg(response.data.message)
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          setDataStatus(true);
          toasts(errorDescription, 'Error');
        }
      },
      (error) => {
        setDataStatus(true);
        toasts(error, 'Error');
      },
    );
  }

  return (
    <>
      <Card className='rounded-1 border border-light'>
        <CardHeader borderSize={1} >
          <CardLabel icon='MenuOpen' iconColor='info'>
            <CardTitle>Latest Transaction</CardTitle>
          </CardLabel>
          <CardActions>
            <Button
              color='info'
              isLink
              icon='Summarize'
              onClick={handleOpenModal}>
              All Transaction
            </Button>
          </CardActions>
        </CardHeader>
        <CardBody style={{ height: '50vh' }} className='table-responsive' isScrollable>
          {studentFeeTransactionData != '' ? <>
            {onCurrentPageData.map((i: any, index: any) => (
              <div key={i.studentFeeCollectionId} className={`col-12 ${index > 0 ? 'mt-4' : ''}`}>
                <div className='row'>
                  <div className='col d-flex align-items-center'>
                    <div className='flex-shrink-0'>
                      <div
                        style={{ width: 100 }}
                        className={classNames(
                          `bg-l${darkModeStatus ? 'o25' : '10'
                          }-${i.color} text-${i.color} fw-bold py-2 rounded-pill me-3 text-center`,
                        )}>
                        {i.paymentType}
                      </div>
                    </div>
                    <div className='flex-grow-1'>
                      <div className='fs-6'>{i.receiptNo}</div>
                      <div className='text-muted'>
                        <small>{i.studentName} - {i.admissionNo}</small>
                      </div>
                    </div>
                  </div>
                  <div className='col-auto text-end'>
                    <div>
                      <strong>{priceFormat(i.feesCollected)}</strong>
                    </div>
                    <div className='text-muted'>
                      <small>{i.receiptDateForView}</small>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </> : <div className="d-flex justify-content-center text-uppercase fw-bold text-danger">{noDataMsg}</div>}
        </CardBody>
      </Card>
    </>
  );
};

export default StudentFeeTransaction;
