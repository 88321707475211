import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { toasts } from "../../../services/toast.service";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import AlertService from "../../../services/AlertService";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getBatchMasterDetails, getSectionDetails } from "../../../services/master.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import { getColumnsForDataTable, getMedium, getStandardDetails, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import NoDataMsg from "../../../common/components/NoDataMsg";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { getClassWiseMarkList, getExamDetails, } from "../../../services/exam.service";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import Collapse from "../../bootstrap/Collapse";
import { TableLoader } from "../../../services/loader.services";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";


function ExamMarks() {

    const { userAccountId, userTypeId, userData } = useContext(AuthContext);

    useEffect(() => {
        getBatchMasterList()
        getStandardList()

        if (userData.academicPeriodId > 0 && userData.classInchargeStandardDetailsId > 0) {
            getExam(userData.academicPeriodId)
        }
    }, [])

    const { themeStatus, darkModeStatus } = useDarkMode();
    const [classWiseMarkData, setClassWiseMarkData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)

    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [examDetailsId, setExamDetailsId] = useState<any>('');
    const [examTypeData, setExamTypeData] = useState<any>([]);
    const [batchMasterId, setBatchMasterId] = useState<any>('');
    const [batchByCourseMasterData, setBatchByCourseMasterData] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([]);
    const [sectionDetailsData, setSectionDetailsData] = useState([]);

    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isExamTypeLoader, setIsExamTypeLoader] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(classWiseMarkData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const [mediumId, setMediumId] = useState<any>('')
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')

    const selectBatch = (e: any) => {
        setDataSuccess(false);
        setExamDetailsId('')
        setStandardDetailsId('')
        setAcademicPeriodId('')
        setSectionDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        setAcademicPeriodId(batchMasterId?.value)
        getMediumList()
    }

    const selectMedium = (e: any) => {
        setDataSuccess(false);
        setStandardDetailsId('');
        setSectionDetailsId('');
        let mediumId = e;
        setMediumId(mediumId);
        getStandardList();
    };

    const selectStandard = (e: any) => {
        setDataSuccess(false);
        setExamDetailsId('')
        setSectionDetailsId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
            getSectionList(mediumId?.value, standardDetailsId?.value);
        }
    }

    const selectSection = (e: any) => {
        setExamDetailsId('')
        setDataSuccess(false);
        let sectionDetailsId = e;
        setSectionDetailsId(sectionDetailsId);
        if (academicPeriodId != '') {
            getExam(academicPeriodId);
        }
    };

    const selectExamType = (e: any) => {
        setDataSuccess(false);
        let examDetailsId = e
        setExamDetailsId(examDetailsId)

    }

    function getBatchMasterList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchByCourseMasterData(data);
                        //setAcademicPeriodId(data[0].academicPeriodId)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchByCourseMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getExam(academicPeriodId: any) {
        setIsExamTypeLoader(true)
        getExamDetails(academicPeriodId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examDetails;
                    if (data != undefined) {
                        setExamTypeData(data);
                        setIsExamTypeLoader(false)
                        if (userData != undefined && userData?.classInchargeStandardDetailsId > 0) {
                            setExamDetailsIdByClassIncharge(data[0].examDetailsId)
                            setExamNameByClassIncharge(data[0].examTypeName)
                            getClassWiseMark(0, userData?.classInchargeStandardDetailsId, userData?.classInchargeSectionDetailsId, data[0].examDetailsId);
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setExamTypeData([]);
                    setIsExamTypeLoader(false)
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setIsExamTypeLoader(false)
                }
            }, error => {
                toasts(error, "Error")
                setIsExamTypeLoader(false)
            }
        )
    }

    function getSectionList(mediumId: any, standardDetailsId: any) {
        getSectionDetails(
            mediumId,
            standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getClassWiseMark(batchMasterId: any, standardDetailsId: any, sectionDetailsId: any, examDetailsId: any) {
        setIsLoader(true);
        getClassWiseMarkList(batchMasterId, standardDetailsId, sectionDetailsId, examDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.classWiseMarkList;
                    if (data != undefined) {
                        setIsLoader(false);
                        setClassWiseMarkData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        getColumnsForTable('getClassWiseMarkList', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getClassWiseMarkList', 'get');
                    setClassWiseMarkData([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function viewExamList() {
        setIsLoader(true);
        setDataSuccess(false);
        setClassWiseMarkData([]);
        getClassWiseMark(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value, examDetailsId?.value)
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getClassWiseMarkList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [examDetailsIdByClassIncharge, setExamDetailsIdByClassIncharge] = useState<any>('')
    const [examNameByClassIncharge, setExamNameByClassIncharge] = useState<any>('')

    const selectExamForClassIncharge = (i: any) => {

        if (i != undefined && userData != undefined) {
            setExamDetailsIdByClassIncharge(i.examDetailsId)
            setExamNameByClassIncharge(i.examTypeName)
            getClassWiseMark(0, userData?.classInchargeStandardDetailsId, userData?.classInchargeSectionDetailsId, i.examDetailsId);
        }
    }

    return (
        <>
            <PageWrapper title={`Exam List`}>                
                <Page container='fluid'>
                    {userData.classInchargeSectionDetailsId > 0 ?
                        <div className="row">
                            <div className="col-lg-3">
                                <Card stretch>
                                    <CardHeader borderSize={1}>
                                        <CardLabel icon='AccountBalance' iconColor='info'>
                                            <CardTitle tag='div' className='h5'>
                                                Exam
                                            </CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody isScrollable>
                                        {isExamTypeLoader ? <TableLoader /> :
                                            <div className='row g-3'>
                                                {examTypeData.map((item: any) => (
                                                    <div className='col-12'>
                                                        <Button
                                                            icon='Article'
                                                            color='info'
                                                            className='w-100 p-3'
                                                            isLight={examDetailsIdByClassIncharge !== item.examDetailsId}
                                                            onClick={() => selectExamForClassIncharge(item)}
                                                        >
                                                            {item.examTypeName}
                                                        </Button>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-lg-9'>
                                <Card stretch data-tour='list' ref={componentRef}>
                                    <CardHeader borderSize={1}>
                                        <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                            <CardTitle tag='div' className='h5'>Class Wise Mark List - {examNameByClassIncharge != '' ? <span className="text-primary"> {examNameByClassIncharge}</span> : null}</CardTitle>
                                        </CardLabel>&nbsp;&nbsp;

                                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                        </ButtonGroup>

                                        <CardActions className="d-print-none">
                                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                                <DropdownToggle>
                                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                                </DropdownToggle>
                                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                                    <DropdownItem>
                                                        <div className='container py-2'>
                                                            <form className='row g-3'>
                                                                <div className='col-12'>
                                                                    <FormGroup>
                                                                        <h6>Select All</h6>
                                                                        <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getClassWiseMarkList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                                    <FormGroup>
                                                                        <h6>Columns</h6>
                                                                        <ChecksGroup>
                                                                            {allColumnsData.map((i: any) => (
                                                                                <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                            ))}
                                                                        </ChecksGroup>
                                                                    </FormGroup>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                            <Dropdown isButtonGroup>
                                                <DropdownToggle>
                                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                                </DropdownToggle>
                                                <DropdownMenu isAlignmentEnd>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel(`${examNameByClassIncharge}_${userData.classInchargeStandardName}-${userData.classInchargeSectionName}_ Mark List`, classWiseMarkData, columnVisibilityData)}> Excel </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(classWiseMarkData, columnVisibilityData, `${examNameByClassIncharge}_${userData.classInchargeStandardName}-${userData.classInchargeSectionName}_ Mark List`)}> PDF
                                                        </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile(`${examNameByClassIncharge}_${userData.classInchargeStandardName}-${userData.classInchargeSectionName}_ Mark List`, classWiseMarkData, columnVisibilityData)}> CSV </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </CardActions>
                                    </CardHeader>
                                    <CardBody style={{ height: '55vh' }} className='table-responsive' isScrollable>
                                        {isLoader ? <TableLoader /> :
                                            <table className='table table-modern table-hover text-nowrap'>
                                                <thead>
                                                    <tr>
                                                        <th scope='col' onClick={() => requestSort('sno')}
                                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                        </th>
                                                        {columnVisibilityData.map((column: any) => (
                                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                                className='cursor-pointer text-decoration-underline'>
                                                                {column.columnName}
                                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredData != "" ?
                                                        <>
                                                            {filteredData.map((rowData: any, index: any) => (
                                                                <tr key={index}>
                                                                    <td>{rowData.sno}</td>
                                                                    {columnVisibilityData.map((column: any) => (
                                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                                            <td key={`${rowData.studentExamMarkTotalId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                                    ))}
                                                                </tr>
                                                            ))
                                                            }</>
                                                        :
                                                        <NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}
                                                </tbody>
                                            </table>
                                        }
                                    </CardBody>
                                    {!isLoader ?
                                        <PaginationButtons
                                            className="d-print-none"
                                            data={items}
                                            label='items'
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}
                                            perPage={perPage}
                                            setPerPage={setPerPage}
                                        /> : null
                                    }
                                </Card>
                            </div>
                        </div> :

                        <>
                            <Collapse isOpen={isOpenListCard}>
                                <Card stretch data-tour='list'>
                                    <CardHeader borderSize={1}>
                                        <CardLabel icon='List' iconColor='info'>
                                            <CardTitle tag='div' className='h5'>
                                                Mark List
                                            </CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row g-4">
                                            <div className='col-md-2'>
                                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Batch Type' placeholder="Batch Type" onChange={selectBatch} value={batchMasterId} list={batchByCourseMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} />
                                                </FormGroup>
                                            </div>

                                            {batchMasterId ?
                                                <div className='col-2'>
                                                    <FormGroup id='mediumId' label='Medium' isFloating>
                                                        <SearchableSelect
                                                            isFloating
                                                            ariaLabel='Medium'
                                                            onChange={selectMedium}
                                                            value={mediumId}
                                                            list={mediumData.map((data: any) => ({
                                                                value: data.mediumId,
                                                                label: data.medium,
                                                            }))}
                                                        />
                                                    </FormGroup>
                                                </div> : null
                                            }

                                            {mediumId ?
                                                <div className='col-md-2'>
                                                    <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='Standard' placeholder="Standard"
                                                            onChange={selectStandard}
                                                            value={standardDetailsId}
                                                            list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />
                                                    </FormGroup>
                                                </div> : null
                                            }

                                            {standardDetailsId ?
                                                <div className='col-2'>
                                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                        <SearchableSelect
                                                            isFloating
                                                            ariaLabel='Section'
                                                            onChange={selectSection}
                                                            value={sectionDetailsId}
                                                            list={sectionDetailsData.map((data: any) => ({
                                                                value: data.sectionDetailsId,
                                                                label: data.sectionName,
                                                            }))}
                                                        />
                                                    </FormGroup>
                                                </div> : null
                                            }

                                            {sectionDetailsId ?
                                                <div className=" col-md-2">
                                                    <FormGroup id='examDetailsId' label='Exam Type' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='Exam Type' placeholder="Exam Type"
                                                            value={examDetailsId}
                                                            onChange={selectExamType}
                                                            list={examTypeData.map((data: any) => (
                                                                { value: data.examDetailsId, label: data.examTypeName }
                                                            ))} required />
                                                    </FormGroup>
                                                </div> : null
                                            }

                                            {examDetailsId ?
                                                <div className='col-md-2 align-self-center'>
                                                    <Button icon='ArrowDownward' color='primary' onClick={viewExamList}>View</Button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Collapse>

                            <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                            {dataSuccess ? (
                                <Card stretch data-tour='list' ref={componentRef}>
                                    <CardHeader borderSize={1}>
                                        <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                            <CardTitle tag='div' className='h5'>Class Wise Mark List</CardTitle>
                                        </CardLabel>&nbsp;&nbsp;

                                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                        </ButtonGroup>

                                        <CardActions className="d-print-none">
                                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                                <DropdownToggle>
                                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                                </DropdownToggle>
                                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                                    <DropdownItem>
                                                        <div className='container py-2'>
                                                            <form className='row g-3'>
                                                                <div className='col-12'>
                                                                    <FormGroup>
                                                                        <h6>Select All</h6>
                                                                        <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getClassWiseMarkList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                                    <FormGroup>
                                                                        <h6>Columns</h6>
                                                                        <ChecksGroup>
                                                                            {allColumnsData.map((i: any) => (
                                                                                <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                            ))}
                                                                        </ChecksGroup>
                                                                    </FormGroup>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                            <Dropdown isButtonGroup>
                                                <DropdownToggle>
                                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                                </DropdownToggle>
                                                <DropdownMenu isAlignmentEnd>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel(`${examTypeData[0]?.examTypeName}_${standardDetailsId?.label}-${sectionDetailsId?.label}_Mark List`, classWiseMarkData, columnVisibilityData)}> Excel </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(classWiseMarkData, columnVisibilityData, `${examTypeData[0]?.examTypeName}_${standardDetailsId?.label}-${sectionDetailsId?.label}_Mark List`)}> PDF
                                                        </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile(`${examTypeData[0]?.examTypeName}_${standardDetailsId?.label}-${sectionDetailsId?.label}_Mark List`, classWiseMarkData, columnVisibilityData)}> CSV </Button>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </CardActions>
                                    </CardHeader>

                                    <CardBody className='table-responsive' isScrollable>
                                        <table className='table table-modern table-hover text-nowrap'>
                                            <thead>
                                                <tr>
                                                    <th scope='col' onClick={() => requestSort('sno')}
                                                        className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                        <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                    </th>
                                                    {columnVisibilityData.map((column: any) => (
                                                        <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                            className='cursor-pointer text-decoration-underline'>
                                                            {column.columnName}
                                                            <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredData != "" ?
                                                    <>
                                                        {filteredData.map((rowData: any, index: any) => (
                                                            <tr key={index}>
                                                                <td>{rowData.sno}</td>
                                                                {columnVisibilityData.map((column: any) => (
                                                                    (column.isDisplay && rowData[column.keyName]) ?
                                                                        <td key={`${rowData.studentExamMarkTotalId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                                ))}
                                                            </tr>
                                                        ))
                                                        }</>
                                                    :
                                                    <NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}
                                            </tbody>
                                        </table>
                                    </CardBody>
                                    <PaginationButtons
                                        className="d-print-none"
                                        data={items}
                                        label='items'
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                        perPage={perPage}
                                        setPerPage={setPerPage}
                                    />
                                </Card>
                            ) : null} </>
                    }
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
} export default ExamMarks;