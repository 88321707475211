import { useContext, useEffect, useRef, useState } from 'react';
import useDarkMode from '../../../../hooks/useDarkMode';
import useSortableData from '../../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../../PaginationButtons';
import { useFormik } from 'formik';
import { toasts } from '../../../../services/toast.service';
import Page from '../../../../layout/Page/Page';
import Collapse from '../../../bootstrap/Collapse';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTabItem,
	CardTitle,
} from '../../../bootstrap/Card';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Select from '../../../bootstrap/forms/Select';
import Button, { ButtonGroup } from '../../../bootstrap/Button';
import Icon from '../../../icon/Icon';
import Input from '../../../bootstrap/forms/Input';
import AlertService from '../../../../services/AlertService';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../bootstrap/OffCanvas';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Textarea from '../../../bootstrap/forms/Textarea';
import { getProfileForStudent } from '../../../../services/student.service';
import {
	getCertificateTrackingReport,
	getOriginalCertificateListByUserAccountId,
	updateCertificateTracker,
} from '../../../../services/certificate.service';
import {
	getColumnsForDataTable,
	pictNotLoading,
	profilePic,
	updateColumnsForDataTable,
	updateFilter,
} from '../../../../services/common.service';
import AuthContext from '../../../../contexts/authContext';
import { getLicenseKey } from '../../../../services/application.settings';
import { TableLoader, showLoader } from '../../../../services/loader.services';
import SearchableSelect from '../../../../common/components/SearchableSelect';
import NoDataMsg from '../../../../common/components/NoDataMsg';

const CertificateTrackingReport = () => {
	const [studentDetailsId, setStudentDetailsId] = useState<any>(Number);

	useEffect(() => {
		getCertificateTrackingReportList(9);
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);

	const [studentProfileData, setStudentProfileData] = useState<any>([]);

	const { darkModeStatus } = useDarkMode();
	const [studentOrStaffOriginalCertificateId, setStudentOrStaffOriginalCertificateId] =
		useState<any>(Number);

	const componentRef = useRef(null);
	const [dataSuccess, setDataSuccess] = useState(false);

	const [studentOrStaffCertificateReportData, setStudentOrStaffCertificateReportData] = useState(
		[],
	);

	const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([]);
	const [isChecked, setIsChecked] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(
		studentOrStaffCertificateReportData,
	);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const [certificateTrackerDetailsData, setCertificateTrackerDetailsData] = useState([]);
	const [isLoader, setIsLoader] = useState(false);

	//OffCanvas
	const [editCertificateTrackerOffCanvas, setEditCertificateTrackerOffCanvas] = useState(false);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');

	const editCertificateTrackerForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			studentOrStaffDetailsId: '',
			originalCertificateId: '',
			studentOrStaffCertificateTrackerId: '',
			dueDate: '',
			status: '',
			returnDate: '',
			actualReturnDate: '',
			remarks: '',
		},
		validate: (values) => {
			const errors: {
				returnDate?: string;
				dueDate?: string;
				actualReturnDate?: string;
				status?: string;
				originalCertificateId?: string;
			} = {};
			if (!values.dueDate) {
				errors.dueDate = 'Required';
			}
			if (!values.returnDate) {
				errors.returnDate = 'Required';
			}
			if (!values.actualReturnDate) {
				errors.actualReturnDate = 'Required';
			}
			if (!studentOrStaffOriginalCertificateId?.value) {
				errors.originalCertificateId = 'Required';
			}
			if (!values.status) {
				errors.status = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			updateCertificateTrackerSubmit();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => {},

		//validateOnChange: false,
		onSubmit: () => {},
	});

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function getCertificateTrackingReportList(userTypeId: any) {
		setDataSuccess(false);
		getCertificateTrackingReport(
			userTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.certificateTrackingReport;
					if (data != undefined) {
						getColumnsForTable('getCertificateTrackingReport', 'get');
						setStudentOrStaffCertificateReportData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataSuccess(true);
					} else {
						setDataSuccess(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataSuccess(true);
					getColumnsForTable('getCertificateTrackingReport', 'get');
					setStudentOrStaffCertificateReportData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataSuccess(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataSuccess(true);
				toasts(error, 'Error');
			},
		);
	}

	function getOriginalCertificateListByUserAccountIdList(
		userTypeId: number,
		studentDetailsId: number,
	) {
		getOriginalCertificateListByUserAccountId(
			userTypeId,
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.originalCertificateListByUserAccountId;
					if (data != undefined) {
						setCertificateTrackerDetailsData(data);
					} else {
						//toasts("Undefined Data", "Error")
						setAlertStatus({ message: 'Undefined Data', type: 'error' });
						setIsOpen(true);
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					// setAlertStatus({ message: response.data.message, type: "error" });
					// setIsOpen(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					//toasts(errorDescription, "Error")
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				//toasts(error, "Error")
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
	}

	function setUpdateCertificateTracker() {
		return {
			studentOrStaffCertificateTrackerId:
				editCertificateTrackerForm.values.studentOrStaffCertificateTrackerId,
			status: editCertificateTrackerForm.values.status,
			studentOriginalCertificateId: studentOrStaffOriginalCertificateId?.value,
			dueDate: editCertificateTrackerForm.values.dueDate,
			returnDate: editCertificateTrackerForm.values.returnDate,
			actualReturnDate: editCertificateTrackerForm.values.actualReturnDate,
			remarks: editCertificateTrackerForm.values.remarks,
			userTypeId: 9,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		};
	}

	function updateCertificateTrackerSubmit() {
		showLoader(true);
		if (editCertificateTrackerForm.isValid) {
			let studentCertificateTrackerDetailsPostData = setUpdateCertificateTracker();
			updateCertificateTracker(
				studentCertificateTrackerDetailsPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getCertificateTrackingReportList(9);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editCertificateTrackerForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function onActivate(i: any, type: any) {
		if (i != undefined) {
			if (type == 'edit') {
				setEditCertificateTrackerOffCanvas(true);
			}

			let selectedCertificate = i;
			setStudentDetailsId(i.studentOrStaffDetailsId);
			getStudentProfile(i.studentOrStaffDetailsId);
			setStudentOrStaffOriginalCertificateId(
				selectedCertificate.studentOrStaffOriginalCertificateId != null
					? {
							value: selectedCertificate.studentOrStaffOriginalCertificateId,
							label: selectedCertificate.certificateName,
						}
					: 0,
			);
			editCertificateTrackerForm.setValues({
				studentOrStaffDetailsId: selectedCertificate.studentOrStaffDetailsId,
				studentOrStaffCertificateTrackerId:
					selectedCertificate.studentOrStaffCertificateTrackerId,
				originalCertificateId: selectedCertificate.originalCertificateId,
				remarks: selectedCertificate.remarks,
				dueDate: selectedCertificate.dueDate,
				returnDate: selectedCertificate.returnDate,
				actualReturnDate: selectedCertificate.actualReturnDate,
				status: selectedCertificate.status,
			});
		}
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
							{
								columnVisibilityId: columnVisibilityId,
								isDisplay: isDisplay ? 0 : 1,
							},
						],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getCertificateTrackingReport', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	function getStudentProfile(studentDetailsId: any) {
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile;
					if (data != undefined) {
						setStudentProfileData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						getOriginalCertificateListByUserAccountIdList(9, studentDetailsId);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function closeAndReset() {
		editCertificateTrackerForm.resetForm;
	}

	return (
		<>
			<PageWrapper title='Certificate Tracking Report'>
				<Page container='fluid'>
					{dataSuccess ? (
						<Card stretch data-tour='list' ref={componentRef} id='pdf'>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info' className='col-lg-5'>
									<CardTitle tag='div' className='h5'>
										Certificate Tracking Report
									</CardTitle>
								</CardLabel>
								&nbsp;&nbsp;
								<CardActions className='d-print-none'>
									<ButtonGroup className=' d-print-none' color='primary'>
										<Icon
											className='mt-1'
											icon='Search'
											size='2x'
											color='primary'
										/>
										<Input
											id='searchInput'
											type='search'
											placeholder='Search...'
											onChange={columnVisibilityForm.handleChange}
											value={columnVisibilityForm.values.searchInput}
										/>
									</ButtonGroup>
								</CardActions>
							</CardHeader>

							<CardBody className='table-responsive' isScrollable>
								{isLoader ? (
									<TableLoader />
								) : (
									<table className='table table-modern table-hover text-nowrap'>
										<thead>
											<tr>
												<th
													scope='col'
													onClick={() => requestSort('sno')}
													className='cursor-pointer text-decoration-underline'>
													S.No{' '}
													<Icon
														size='lg'
														className={getClassNamesFor('sno')}
														icon='FilterList'
													/>
												</th>
												{columnVisibilityData.map((column: any) => (
													<th
														key={column.keyName}
														scope='col'
														onClick={() => requestSort(column.keyName)}
														className='cursor-pointer text-decoration-underline'>
														{column.columnName}
														<Icon
															size='lg'
															className={getClassNamesFor(
																column.keyName,
															)}
															icon='FilterList'
														/>
													</th>
												))}
												<th className='d-print-none' colSpan={2}>
													Action
												</th>
											</tr>
										</thead>
										<tbody>
											{filteredData != '' ? (
												<>
													{filteredData.map((rowData: any) => (
														<tr key={rowData.studentOrStaffDetailsId}>
															<td>{rowData.sno}</td>
															{columnVisibilityData.map(
																(column: any) =>
																	column.isDisplay &&
																	rowData[column.keyName] ? (
																		<td
																			key={`${rowData.studentOrStaffDetailsId}-${column.keyName}`}>
																			{
																				rowData[
																					column.keyName
																				]
																			}
																		</td>
																	) : (
																		<td
																			key={`empty-${column.keyName}`}
																		/>
																	),
															)}

															{rowData.status == 'DUE' ? (
																<td className='d-print-none'>
																	<Button
																		color='dark'
																		isLight
																		aria-label='Edit'
																		icon='Edit'
																		onClick={() => {
																			onActivate(
																				rowData,
																				'edit',
																			);
																		}}
																	/>
																</td>
															) : (
																<td />
															)}
														</tr>
													))}
												</>
											) : (
												<NoDataMsg
													columnsCount={columnVisibilityData.length + 2}
													msg={noDataMsg}
												/>
											)}
										</tbody>
									</table>
								)}
							</CardBody>
							<PaginationButtons
								className='d-print-none'
								data={items}
								label='items'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</Card>
					) : null}

					<OffCanvas
						setOpen={setEditCertificateTrackerOffCanvas}
						isOpen={editCertificateTrackerOffCanvas}
						titleId='editCertificateTrackerOffCanvas'
						isBodyScroll
						tag='form'
						noValidate
						onSubmit={editCertificateTrackerForm.handleSubmit}>
						<OffCanvasHeader
							setOpen={setEditCertificateTrackerOffCanvas}
							onClick={closeAndReset}>
							<OffCanvasTitle id='editCertificateTrackerOffCanvas'>
								Edit Tracking Details
							</OffCanvasTitle>
						</OffCanvasHeader>
						<OffCanvasBody>
							<div className='row g-4'>
								{studentProfileData.map((student: any) => (
									<div className='col-12' key={student.studentDetailsId}>
										<Card
											className={`bg-l${
												darkModeStatus ? 'o25' : '25'
											}-info bg-l${
												darkModeStatus ? 'o50' : '10'
											}-info-hover transition-base rounded-2 mb-4`}
											shadow='sm'>
											<CardBody>
												<div className='d-flex align-items-center pb-3'>
													<div className='flex-shrink-0 ms-2'>
														<img
															style={{ borderRadius: '50%' }}
															src={
																student.profilePath != null
																	? student.profilePath
																	: profilePic(student.genderId)
															}
															height={60}
															width={60}
															onError={(e: any) =>
																pictNotLoading(e, student.genderId)
															}
														/>
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-4 text-dark mb-0'>
															{student.studentName}
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold mb-0 '>
																{student.studentRollNo ? (
																	student.studentRollNo
																) : (
																	<Icon
																		icon='Remove'
																		className='mb-0 text-dark fw-bold h2'
																	/>
																)}{' '}
															</div>
															<div className='text-muted'>
																Roll No
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								))}
								<div className='col-12'>
									<FormGroup
										id='originalCertificateId'
										label='Original Certificate Type'>
										<SearchableSelect
											disabled
											ariaLabel='Original Certificate Type'
											placeholder='Select OriginalCertificate Type'
											onChange={(e: any) =>
												setStudentOrStaffOriginalCertificateId(e)
											}
											value={studentOrStaffOriginalCertificateId}
											onBlur={editCertificateTrackerForm.handleBlur}
											isValid={editCertificateTrackerForm.isValid}
											isTouched={
												editCertificateTrackerForm.touched
													.originalCertificateId
											}
											invalidFeedback={
												editCertificateTrackerForm.errors
													.originalCertificateId
											}
											list={certificateTrackerDetailsData.map(
												(data: any) => ({
													value: data.originalCertificateId,
													label: data.certificateName,
												}),
											)}
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='dueDate' label='Date Of Issue'>
										<Input
											type='date'
											placeholder='Enter Date'
											onChange={editCertificateTrackerForm.handleChange}
											value={editCertificateTrackerForm.values.dueDate}
											onBlur={editCertificateTrackerForm.handleBlur}
											isValid={editCertificateTrackerForm.isValid}
											isTouched={editCertificateTrackerForm.touched.dueDate}
											invalidFeedback={
												editCertificateTrackerForm.errors.dueDate
											}
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='returnDate' label='Return Date'>
										<Input
											type='date'
											placeholder='Enter Date'
											onChange={editCertificateTrackerForm.handleChange}
											value={editCertificateTrackerForm.values.returnDate}
											onBlur={editCertificateTrackerForm.handleBlur}
											isValid={editCertificateTrackerForm.isValid}
											isTouched={
												editCertificateTrackerForm.touched.returnDate
											}
											invalidFeedback={
												editCertificateTrackerForm.errors.returnDate
											}
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='actualReturnDate' label='Actual Return Date'>
										<Input
											type='date'
											placeholder='Enter Date'
											onChange={editCertificateTrackerForm.handleChange}
											value={
												editCertificateTrackerForm.values.actualReturnDate
											}
											onBlur={editCertificateTrackerForm.handleBlur}
											isValid={editCertificateTrackerForm.isValid}
											isTouched={
												editCertificateTrackerForm.touched.actualReturnDate
											}
											invalidFeedback={
												editCertificateTrackerForm.errors.actualReturnDate
											}
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='status' label='Status'>
										<Select
											ariaLabel='Select Status'
											placeholder='Select Status'
											onChange={editCertificateTrackerForm.handleChange}
											value={editCertificateTrackerForm.values.status}
											onBlur={editCertificateTrackerForm.handleBlur}
											isValid={editCertificateTrackerForm.isValid}
											isTouched={editCertificateTrackerForm.touched.status}
											invalidFeedback={
												editCertificateTrackerForm.errors.status
											}>
											<option value='IN'>IN</option>
											<option value='DUE'>DUE</option>
										</Select>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='remarks' label='Remarks'>
										<Textarea
											placeholder='Enter Remarks'
											onChange={editCertificateTrackerForm.handleChange}
											value={editCertificateTrackerForm.values.remarks}
										/>
									</FormGroup>
								</div>
							</div>
						</OffCanvasBody>
						<div className='row m-0'>
							<div className='col-12 p-3'>
								<Button
									color='info'
									icon='Save'
									className='w-100'
									type='submit'
									isDisable={
										!editCertificateTrackerForm.isValid &&
										!!editCertificateTrackerForm.submitCount
									}>
									Save
								</Button>
							</div>
						</div>
					</OffCanvas>
				</Page>
				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	);
};

export default CertificateTrackingReport;
