import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getProfileForStaff = (staffDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getProfileForStaff/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getProfileForStaff - " + error.message)
            console.log('error caught in service : getProfileForStaff')
        },
    );

export const getStaffListByUserTypeId = (staffDetailsId: any, userTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getStaffListByUserTypeId/${getLicenseKey}/${staffDetailsId}/${userTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListByUserTypeId - " + error.message)
            console.log('error caught in service : getStaffListByUserTypeId')
        },
    );

export const getStaffDesignation = (userTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getStaffDesignation/${getLicenseKey}/${userTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDesignation - " + error.message)
            console.log('error caught in service : getStaffDesignation')
        },
    );

export const addStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffDesignation')
        }
    );

export const updateStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffDesignation')
        }
    );

export const deleteStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStaffDesignation')
        }
    );

export const addStaffDetails = (addStaffPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addStaffDetails`, addStaffPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffDetails')
        }
    );

export const getStaffDegree = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getStaffDegree/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDegree - " + error.message)
            console.log('error caught in service : getStaffDegree')
        },
    );

export const updateStaffDetails = (updateStaffDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateStaffDetails`, updateStaffDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffDetails')
        }
    );

export const getStaffDetails = (staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getStaffDetails/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDetails - " + error.message)
            console.log('error caught in service : getStaffDetails')
        },
    );

export const deleteStaffDetails = (staffDeletePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteStaffDetails`, staffDeletePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStaffDetails')
        }
    );

export const updateStaffResigningDetails = (exitOrResignPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateStaffResigningDetails`, exitOrResignPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffResigningDetails')
        }
    );

export const updateStaffRole = (addStaffRolePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateStaffRole`, addStaffRolePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffRole')
        }
    );

export const getDeletedStaffList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getDeletedStaffList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDeletedStaffList - " + error.message)
            console.log('error caught in service : getDeletedStaffList')
        },
    );

export const rejoinStaffDetails = (staffRejoinPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `rejoinStaffDetails`, staffRejoinPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : rejoinStaffDetails')
        }
    );

export const getStaffListByDesignationId = (staffDetailsId: any, staffDesignationId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getStaffListByDesignationId/${getLicenseKey}/${staffDetailsId}/${staffDesignationId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListByDesignationId - " + error.message)
            console.log('error caught in service : getStaffListByDesignationId')
        },
    );

export const getActivityLog = (staffDetailsId: any, fromDateInMilliSec: any, toDateInMilliSec: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void) =>
    api.get(getAPIURL() + `getActivityLog/${getLicenseKey}/${staffDetailsId}/${fromDateInMilliSec}/${toDateInMilliSec}`,
        (response) => {
            success(response);
        },
        (error) => {
            failure('getActivityLog - ' + error.message);
            console.log('error caught in service : getActivityLog');
        },
    );

export const getStaffLeaveDetails = (userTypeId: any, staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getStaffLeaveDetails/${getLicenseKey}/${userTypeId}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffLeaveDetails - " + error.message)
            console.log('error caught in service : getStaffLeaveDetails')
        },
    );

export const addStaffLeave = (leavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addStaffLeave`, leavePostData,
        response => {
            success(response)
        },
        error => {
            failure("addStaffLeave- " + error.message)
            console.log('error caught in service : addStaffLeave')
        },
    );

export const updateStaffLeave = (leavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateStaffLeave`, leavePostData,
        response => {
            success(response)
        },
        error => {
            failure("updateStaffLeave - " + error.message)
            console.log('error caught in service : updateStaffLeave')
        },
    );

export const deleteStaffLeave = (leavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteStaffLeave`, leavePostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteStaffLeave - " + error.message)
            console.log('error caught in service : deleteStaffLeave')
        },
    );

export const getStatus = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getStatus/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStatus - " + error.message)
            console.log('error caught in service : getStatus')
        },
    );

export const getStaffLeaveDetailsRequestHistory = (requestTableId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getStaffRequestHistory/${getLicenseKey}/${requestTableId}/13`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffRequestHistory - " + error.message)
            console.log('error caught in service : getStaffRequestHistory')
        },
    );

export const getLeaveType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getLeaveType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getLeaveType - " + error.message)
            console.log('error caught in service : getLeaveType')
        },
    );

export const addAssignmentDetails = (addStaffPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addAssignmentDetails`, addStaffPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addAssignmentDetails')
        }
    );

export const addCalenderEventForStaff = (addEventfPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addCalenderEventForStaff`, addEventfPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addCalenderEventForStaff')
        }
    );

export const getCalenderEventForStaff = (staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getCalenderEventForStaff/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getCalenderEventForStaff - " + error.message)
            console.log('error caught in service : getCalenderEventForStaff')
        },
    );

export const updateCalenderEventForStaff = (eventPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateCalenderEventForStaff`, eventPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateCalenderEventForStaff')
        }
    );
export const deleteStudentAssignmentStatus = (addStaffPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteStudentAssignmentStatus`, addStaffPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStudentAssignmentStatus')
        }
    );
export const deleteCalenderEventForStaff = (eventPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteCalenderEventForStaff`, eventPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteCalenderEventForStaff')
        }
    );