import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubheaderSeparator, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import AlertService from '../../../services/AlertService';
import { getLicenseKey } from '../../../services/application.settings';
import { getStudentAttendanceDetails } from '../../../services/attendance.service';
import { pictNotLoading, profilePic } from '../../../services/common.service';
import { getStudentExamMark } from '../../../services/exam.service';
import { getStudentTotalFeeCollection } from '../../../services/fee.service';
import { showLoader } from '../../../services/loader.services';
import { getProfileForStudent, getStudentSkills, deleteStudentSkills } from '../../../services/student.service';
import { toasts } from '../../../services/toast.service';
import Button from '../../bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle, CardActions } from '../../bootstrap/Card';
import Icon from '../../icon/Icon';
import Avatar from '../../Avatar';

const ParentProfile = () => {

    const { darkModeStatus } = useDarkMode();
    const { studentDetailsId } = useParams();
    const { userAccountId, userTypeId } = useContext(AuthContext);


    useEffect(() => {
        getStudentProfile(studentDetailsId);
        getStudentTotalFeeCollectionList(studentDetailsId);
        getStudentAttendance(studentDetailsId)
        getStudentSkillsList(studentDetailsId)
    }, [])

    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([])
    const [studentAttendanceData, setStudentAttendanceData] = useState<any>([])
    const [studentAbsentAttendanceData, setStudentAbsentAttendanceData] = useState<any>([])
    const [studentMarkData, setStudentMarkData] = useState<any>([])
    const [studentSubjectWiseMarkData, setStudentSubjectWiseMarkData] = useState<any>([])

    const [absentDetailsOffCanvas, setAbsentDetailsOffCanvas] = useState(false);
    const [subjectWiseMarkOffCanvas, setSubjectWiseMarkOffCanvas] = useState(false);

    const componentRef = useRef(null);
    const navigate = useNavigate();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function getStudentProfile(studentDetailsId: any) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                        if (data[0].standardDetailsId != undefined && data[0].sectionDetailsId != undefined && studentDetailsId != undefined) {
                            getStudentExamMarkList(data[0].standardDetailsId, data[0].sectionDetailsId, studentDetailsId)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setDataSuccess(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    function getStudentTotalFeeCollectionList(studentDetailsId: any) {
        getStudentTotalFeeCollection(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTotalFeeCollection;
                    if (data != undefined) {
                        setStudentTotalFeeCollectionData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [totalWorkingDays, setTotalWorkingDays] = useState<any>('')

    function getStudentAttendance(studentDetailsId: any) {
        getStudentAttendanceDetails(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentAttendanceDetails;

                    if (data != undefined) {
                        let data1 = data[0].attendanceDetails;
                        setStudentAttendanceData(data);
                        setStudentAbsentAttendanceData(data1);
                        setTotalWorkingDays(data[0].totalWorkingDays);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }
    const [totalMark, setTotalMark] = useState<any>('')
    function getStudentExamMarkList(standardDetailsId: any, sectionDetailsId: any, studentDetailsId: any) {
        getStudentExamMark(standardDetailsId, sectionDetailsId, studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentExamMarkDetails;
                    if (data != undefined) {
                        setStudentMarkData(data[0]);
                        setTotalMark(data[0].totalMark);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [examTypeName, setExamTypeName] = useState<any>('')
    const [result, setResult] = useState<any>('')
    const [resultImage, setResultImage] = useState<any>('')
    const [totalScore, setTotalScore] = useState<any>('')
    const [classMark, setClassMark] = useState<any>('')
    const [percentage, setPercentage] = useState<any>('')

    function onActivate(i: any) {
        setExamTypeName(i.examTypeName)
        setResult(i.result)
        setResultImage(i.resultImage)
        setTotalScore(i.totalScored)
        setClassMark(i.classRank)
        setPercentage(i.percentage)
        setStudentSubjectWiseMarkData(i.markDetails)
        setSubjectWiseMarkOffCanvas(true)

    }

    function closeAndReset() {
        setAbsentDetailsOffCanvas(false)
        setSubjectWiseMarkOffCanvas(false)
    }

    const [studentSkillsData, setStudentSkillsData] = useState<any>([])
    const colors = ['info', 'success', 'warning', 'secondary', 'primary', 'dark'];

    const [skillNoDataMsg, setSkillNoDataMsg] = useState<any>('')

    function getStudentSkillsList(studentDetailsId: any) {
        getStudentSkills(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentSkills[0].skills;
                    if (data != undefined) {
                        for (let i = 0; i < data.length; i++) {
                            for (let i = 0; i < data.length; i++) {
                                data[i].color = colors[Math.floor(Math.random() * colors.length)];
                            }
                        }
                        setStudentSkillsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentSkillsData([])
                    setDataSuccess(false)
                    setSkillNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [studentSkillsId, setStudentSkillsId] = useState<any>('')

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
    const [isOpen, setIsOpen] = useState(false);

    function setDeleteStudentSkills() {
        return {
            studentSkillsId: studentSkillsId,
            licenseKey: getLicenseKey,
            staffDetailsId: userAccountId,
        };
    }

    function deleteStudentSkillsSubmit() {
        showLoader(true);
        if (studentSkillsId > 0) {
            let leavePostData = setDeleteStudentSkills();
            deleteStudentSkills(
                leavePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'success' });
                        setIsOpen(true);
                        setIsDeleteOpen(false)
                        getStudentSkillsList(studentDetailsId);
                        setStudentSkillsId('');
                    } else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'warning' });
                        setIsOpen(true);
                    } else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: 'error' });
                        setIsOpen(true);
                    }
                },
                (error) => {
                    setAlertStatus({ message: error, type: 'error' });
                    setIsOpen(true);
                },
            );
        } else if (studentSkillsId == 0 || studentSkillsId == undefined) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
            setIsOpen(true);
        }
    }

    return (
        <>
            {studentDetailsData.map((student: any) => (
                <PageWrapper title={`${student.studentName}`} ref={componentRef} key={student.studentDetailsId}>
                    <SubHeader>
                        <SubHeaderLeft>
                            <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                                Back to List
                            </Button>
                            <SubheaderSeparator />
                        </SubHeaderLeft>
                    </SubHeader>
                    <Page>
                        <div className='row'>

                            <div className='col-lg-5'>
                                <Card stretch className='shadow-3d-primary rounded-1 border border-light'>
                                    <CardBody>
                                        <div className='row g-5'>
                                            <div className='col-12'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-shrink-0'>
                                                        <Avatar
                                                            srcSet={profilePic(student.genderId)}
                                                            src={profilePic(student.genderId)}
                                                            className='rounded-circle'
                                                        />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='h2 fw-bold'>
                                                            {student.guardian}
                                                        </div>
                                                        <div className='h5 text-muted'>Parent / Guardian </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='row g-3'>
                                                    <div className='col-12'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='Mail' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {student.guardianEmail ? student.guardianEmail : <Icon icon='Remove' className='mb-0 text-dark fw-bold h4' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Email Address
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon
                                                                    icon='PhoneIphone'
                                                                    size='3x'
                                                                    color='info'
                                                                />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {student.smsToBeSentNumber ? student.smsToBeSentNumber : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>Phone</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='Work' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {student.guardianOccupation ? student.guardianOccupation : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Occupation
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-12'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='House' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {student.address ? student.address : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Address
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className='col-lg-7'>

                                <Card stretch className='shadow-3d-primary rounded-1 border border-light'>
                                    <CardHeader>
                                        <CardLabel iconColor={'info'} icon='HolidayVillage'>
                                            <CardTitle>Address</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-6">
                                                <Card
                                                    className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-danger bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-danger-hover transition-base rounded-2 mb-4`}
                                                    shadow='sm'>
                                                    <CardBody>
                                                        <CardTitle tag='div' className='h5'>
                                                            Communication
                                                        </CardTitle>
                                                        <div className='col-12'>
                                                            <div className='d-flex align-items-center mt-3'>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fs-6 mb-0'>
                                                                        {student.address ? student.address : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className='col-6'>
                                                <Card
                                                    className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-success bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-success-hover transition-base rounded-2 mb-4`}
                                                    shadow='sm'>
                                                    <CardBody>
                                                        <CardTitle tag='div' className='h5'>
                                                            Permanent
                                                        </CardTitle>
                                                        <div className='d-flex align-items-center mt-3'>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fs-6 mb-0'>
                                                                    {student.permAddress ? student.permAddress : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Page >

                    <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

                </PageWrapper >))
            }
        </>
    );
};

export default ParentProfile;


