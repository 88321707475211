import { useFormik } from 'formik';
import { useEffect, useState, useRef, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import { toasts } from '../../../services/toast.service';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import {
	getColumnsForDataTable,
	profilePic,
	updateColumnsForDataTable,
	updateFilter,
} from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { getComplaintDetails } from '../../../services/student.service';
import Avatar from '../../Avatar';

function ComplaintList({ type }: any) {
	useEffect(() => {
		getComplaintList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [complaintDetailsData, setComplaintDetailsData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const [studentComplaintsData, setStudentComplaintsData] = useState<any>([]);
	const [staffComplaintsData, setStaffComplaintsData] = useState<any>([]);
	const [parentComplaintsData, setParentComplaintsData] = useState<any>([]);
	const [studentAndstaffComplaintsData, setStudentAndstaffComplaintsData] = useState<any>([]);

	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);
	const [studentNoDataMsg, setStudentNoDataMsg] = useState<any>('');
	const [staffNoDataMsg, setStaffNoDataMsg] = useState<any>('');
	const [parentNoDataMsg, setParentNoDataMsg] = useState<any>('');
	const [staffAndStudentNoDataMsg, setStaffAndStudentNoDataMsg] = useState<any>('');

	// ForModal
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(complaintDetailsData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	type TTabs = 'Comments';
	interface ITabs {
		[key: string]: TTabs;
	}

	const TABS: ITabs = {
		COMMENTS: 'Comments',
	};
	const [activeTab, setActiveTab] = useState(TABS.SUMMARY);

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

	function getComplaintList() {
		setDataStatus(false);
		getComplaintDetails(0, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.complaintDetails;
					if (data != undefined) {
						getColumnsForTable('getComplaintDetails', 'get');
						setComplaintDetailsData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						let studentComplaints = data.filter((item: any) => item.userTypeId == 9);
						setStudentComplaintsData(studentComplaints);

						setStudentNoDataMsg(studentComplaints == '' ? 'No Complaints' : '');

						let staffComplaints = data.filter(
							(item: any) => item.userTypeId != 9 && item.userTypeId != 10,
						);
						setStaffComplaintsData(staffComplaints);

						setStaffNoDataMsg(staffComplaints == '' ? 'No Complaints' : '');

						let parentComplaints = data.filter((item: any) => item.userTypeId == 10);
						setParentComplaintsData(parentComplaints);

						setParentNoDataMsg(parentComplaints == '' ? 'No Complaints' : '');

						setStudentAndstaffComplaintsData(data);

						setDataStatus(true);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					//toasts(response.data.message, "Error")
					getColumnsForTable('getComplaintDetails', 'get');
					setComplaintDetailsData([]);
					setStaffAndStudentNoDataMsg(response.data.message);
					setStudentNoDataMsg(response.data.message);
					setStaffNoDataMsg(response.data.message);
					setParentNoDataMsg(response.data.message)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getComplaintDetails', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	return (
		<>
			{type == 'dashboard' ? (
				<div className='col-lg-12'>
					<Card
						stretch
						className='overflow-hidden border border-light rounded-1'
						shadow='md'
						tag='form'
						noValidate>
						<CardHeader borderSize={1}>
							<CardLabel icon='Chat' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Complaints
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody style={{ height: '55vh' }} isScrollable>
							{studentAndstaffComplaintsData != '' ? (
								<>
									{studentAndstaffComplaintsData.map((i: any, index: any) => (
										<div
											className={index > 0 ? 'row g-4 mt-1' : 'row g-4'}
											key={i.complaintDetailsId}>
											<div className='col-12 d-flex'>
												<div className='flex-shrink-0'>
													{i.profilePath != null ? (
														<Avatar src={i.profilePath} size={64} />
													) : (
														<img
															src={profilePic(i.genderId)}
															width='64'
															height='64'
														/>
													)}
												</div>
												<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
													<figure className='mb-0'>
														<div>
															<strong className='text-dark'>
																{i.studentOrStaffName} -{' '}
															</strong>
															{i.studentRollNoOrEmpNumber}
														</div>
														<div className='text-info fw-bold mt-1 text-uppercase'>
															{i.complaintType}
														</div>
														<blockquote className='blockquote mt-1'>
															<div
																className='fs-6'
																dangerouslySetInnerHTML={{
																	__html: i.natureOfComplaint,
																}}
															/>
														</blockquote>
														<figcaption className='blockquote-footer mb-0'>
															{i.complaintDateForView} ({i.userType})
														</figcaption>
													</figure>
												</div>
											</div>
											<div className='col-12 mt-2 border border-light'></div>
										</div>
									))}{' '}
								</>
							) : (
								<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
									{staffAndStudentNoDataMsg}
								</div>
							)}
						</CardBody>
					</Card>
				</div>
			) : (
				<PageWrapper title='Complaint List'>
					<Page>
						<div className='row h-100'>
							<div className='col-lg-6'>
								<Card
									stretch
									className='overflow-hidden border border-light'
									shadow='md'
									tag='form'
									noValidate>
									<CardHeader borderSize={1}>
										<CardLabel icon='Chat' iconColor='info'>
											<CardTitle tag='div' className='h5'>
												Student Complaints
											</CardTitle>
										</CardLabel>
									</CardHeader>

									<CardBody isScrollable>
										{studentComplaintsData != '' ? (
											<>
												{studentComplaintsData.map((i: any, index: any) => (
													<div
														className={
															index > 0 ? 'row g-4 mt-1' : 'row g-4'
														}
														key={i.complaintDetailsId}>
														<div className='col-12 d-flex'>
															<div className='flex-shrink-0'>
																{i.profilePath != null ? (
																	<Avatar
																		src={i.profilePath}
																		size={64}
																	/>
																) : (
																	<img
																		src={profilePic(i.genderId)}
																		width='64'
																		height='64'
																	/>
																)}
															</div>
															<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
																<figure className='mb-0'>
																	<div>
																		<strong className='text-dark'>
																			{i.studentOrStaffName} -{' '}
																		</strong>
																		{i.studentRollNoOrEmpNumber}
																	</div>
																	<div className='text-info fw-bold mt-1 text-uppercase'>
																		{i.complaintType}
																	</div>
																	<blockquote className='blockquote mt-1'>
																		<div
																			className='fs-6'
																			dangerouslySetInnerHTML={{
																				__html: i.natureOfComplaint,
																			}}
																		/>
																	</blockquote>
																	<figcaption className='blockquote-footer mb-0'>
																		{i.complaintDateForView}
																	</figcaption>
																</figure>
															</div>
														</div>
														<div className='col-12 mt-2 border border-light'></div>
													</div>
												))}
											</>
										) : (
											<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
												{studentNoDataMsg}
											</div>
										)}
									</CardBody>
								</Card>
							</div>
							<div className='col-lg-6'>
								<Card
									stretch
									className='overflow-hidden border border-light'
									shadow='md'
									tag='form'
									noValidate>
									<CardHeader borderSize={1}>
										<CardLabel icon='Chat' iconColor='info'>
											<CardTitle tag='div' className='h5'>
												Staff Complaints
											</CardTitle>
										</CardLabel>
									</CardHeader>

									<CardBody isScrollable>
										{staffComplaintsData != '' ? (
											<>
												{staffComplaintsData.map((i: any, index: any) => (
													<div
														className={
															index > 0 ? 'row g-4 mt-1' : 'row g-4'
														}
														key={i.complaintDetailsId}>
														<div className='col-12 d-flex'>
															<div className='flex-shrink-0'>
																{i.profilePath != null ? (
																	<Avatar
																		src={i.profilePath}
																		size={64}
																	/>
																) : (
																	<img
																		src={profilePic(i.genderId)}
																		width='64'
																		height='64'
																	/>
																)}
															</div>
															<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
																<figure className='mb-0'>
																	<div>
																		<strong className='text-dark'>
																			{i.studentOrStaffName} -{' '}
																		</strong>
																		{i.studentRollNoOrEmpNumber}
																	</div>
																	<div className='text-info fw-bold mt-1 text-uppercase'>
																		{i.complaintType}
																	</div>
																	<blockquote className='blockquote mt-1'>
																		<div
																			className='fs-6'
																			dangerouslySetInnerHTML={{
																				__html: i.natureOfComplaint,
																			}}
																		/>
																	</blockquote>
																	<figcaption className='blockquote-footer mb-0'>
																		{i.complaintDateForView}
																	</figcaption>
																</figure>
															</div>
														</div>
														<div className='col-12 mt-2 border border-light'></div>
													</div>
												))}{' '}
											</>
										) : (
											<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
												{staffNoDataMsg}
											</div>
										)}
									</CardBody>
								</Card>
							</div>
							<div className='col-lg-6'>
								<Card
									stretch
									className='overflow-hidden border border-light'
									shadow='md'
									tag='form'
									noValidate>
									<CardHeader borderSize={1}>
										<CardLabel icon='Chat' iconColor='info'>
											<CardTitle tag='div' className='h5'>
												Parent Complaints
											</CardTitle>
										</CardLabel>
									</CardHeader>

									<CardBody isScrollable>
										{parentComplaintsData != '' ? (
											<>
												{parentComplaintsData.map((i: any, index: any) => (
													<div
														className={
															index > 0 ? 'row g-4 mt-1' : 'row g-4'
														}
														key={i.complaintDetailsId}>
														<div className='col-12 d-flex'>
															<div className='flex-shrink-0'>
																{i.profilePath != null ? (
																	<Avatar
																		src={i.profilePath}
																		size={64}
																	/>
																) : (
																	<img
																		src={profilePic(i.genderId)}
																		width='64'
																		height='64'
																	/>
																)}
															</div>
															<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
																<figure className='mb-0'>
																	<div>
																		<strong className='text-dark'>
																			{i.studentOrStaffName}
																		</strong>
																	</div>
																	<div className='text-info fw-bold mt-1 text-uppercase'>
																		{i.complaintType}
																	</div>
																	<blockquote className='blockquote mt-1'>
																		<div
																			className='fs-6'
																			dangerouslySetInnerHTML={{
																				__html: i.natureOfComplaint,
																			}}
																		/>
																	</blockquote>
																	<figcaption className='blockquote-footer mb-0'>
																		{i.complaintDateForView}
																	</figcaption>
																</figure>
															</div>
														</div>
														<div className='col-12 mt-2 border border-light'></div>
													</div>
												))}{' '}
											</>
										) : (
											<div className='d-flex justify-content-center text-uppercase fw-bold text-danger'>
												{parentNoDataMsg}
											</div>
										)}
									</CardBody>
								</Card>
							</div>
						</div>
					</Page>
				</PageWrapper>
			)}
		</>
	);
}
export default ComplaintList;
