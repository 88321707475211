import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getAnnouncementDetails = (userTypeId: any,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(getAPIURL() + `getAnnouncementDetails/${getLicenseKey}/${userTypeId}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getAnnouncementDetails - ' + error.message);
		console.log('error caught in service : getAnnouncementDetails');
	},
);

export const addAnnouncementDetails = (reminderTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `addAnnouncementDetails`, reminderTypePostData,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : addAnnouncementDetails')
		}
	);

export const updateAnnouncementDetails = (updateReminderPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `updateAnnouncementDetails`, updateReminderPost,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : updateAnnouncementDetails')
		}
	);

export const deleteAnnouncementDetails = (updateReminderPost: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `deleteAnnouncementDetails`, updateReminderPost,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : deleteAnnouncementDetails')
		}
	);