import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import { toasts } from '../../../services/toast.service';
import {
	deleteStudentSkills,
	getProfileForStudent,
	getStudentSkills,
} from '../../../services/student.service';
import AuthContext from '../../../contexts/authContext';
import { useReactToPrint } from 'react-to-print';
import {
	getStudentHobbieDetails,
	pictNotLoading,
	profilePic,
} from '../../../services/common.service';
import { getStudentTotalFeeCollection } from '../../../services/fee.service';
import { getStudentAttendanceDetails } from '../../../services/attendance.service';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../bootstrap/OffCanvas';
import { getStudentExamMark, getStudentExamMarkDetails } from '../../../services/exam.service';
import { BorderOuter } from '../../icon/material-icons';
import { borderBottomLeftRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';
import AttendanceStatus from '../../charts/AttendanceStatus';
import ExamMarkStatus from '../../charts/ExamMarkStatus';
import Alert from '../../bootstrap/Alert';
import Badge from '../../bootstrap/Badge';
import InputGroup from '../../bootstrap/forms/InputGroup';
import Modal, { ModalBody, ModalHeader } from '../../bootstrap/Modal';
import { getLicenseKey } from '../../../services/application.settings';
import { showLoader } from '../../../services/loader.services';
import AlertService from '../../../services/AlertService';

const StudentProfile = () => {
	const { darkModeStatus } = useDarkMode();
	const { studentDetailsId } = useParams();
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getStudentProfile(studentDetailsId);
		getStudentTotalFeeCollectionList(studentDetailsId);
		getStudentAttendance(studentDetailsId);
		getStudentSkillsList(studentDetailsId);
		getHobbieDetails(studentDetailsId);
	}, []);

	const [studentDetailsData, setStudentDetailsData] = useState<any>([]);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([]);
	const [studentAttendanceData, setStudentAttendanceData] = useState<any>([]);
	const [studentAbsentAttendanceData, setStudentAbsentAttendanceData] = useState<any>([]);
	const [studentMarkData, setStudentMarkData] = useState<any>([]);
	const [studentSubjectWiseMarkData, setStudentSubjectWiseMarkData] = useState<any>([]);
	const [hobbieDetailsData, setHobbieDetailsData] = useState<any>([]);

	const [studentHobbieDetailsId, setStudentHobbieDetailsId] = useState<any>('');

	const [absentDetailsOffCanvas, setAbsentDetailsOffCanvas] = useState(false);
	const [subjectWiseMarkOffCanvas, setSubjectWiseMarkOffCanvas] = useState(false);

	const [hobbieNoDataMsg, setHobbieNoDataMsg] = useState<any>('');

	const componentRef = useRef(null);
	const navigate = useNavigate();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function getStudentProfile(studentDetailsId: any) {
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile;
					if (data != undefined) {
						setStudentDetailsData(data);
						if (
							data[0].standardDetailsId != undefined &&
							data[0].sectionDetailsId != undefined &&
							studentDetailsId != undefined
						) {
							getStudentExamMarkList(
								data[0].standardDetailsId,
								data[0].sectionDetailsId,
								studentDetailsId,
							);
						}
					} else {
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setDataSuccess(false);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getStudentTotalFeeCollectionList(studentDetailsId: any) {
		getStudentTotalFeeCollection(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentTotalFeeCollection;
					if (data != undefined) {
						setStudentTotalFeeCollectionData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [totalWorkingDays, setTotalWorkingDays] = useState<any>('');

	function getStudentAttendance(studentDetailsId: any) {
		getStudentAttendanceDetails(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentAttendanceDetails;

					if (data != undefined) {
						let data1 = data[0].attendanceDetails;
						setStudentAttendanceData(data);
						setStudentAbsentAttendanceData(data1);
						setTotalWorkingDays(data[0].totalWorkingDays);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}
	const [totalMark, setTotalMark] = useState<any>('');
	function getStudentExamMarkList(
		standardDetailsId: any,
		sectionDetailsId: any,
		studentDetailsId: any,
	) {
		getStudentExamMark(
			standardDetailsId,
			sectionDetailsId,
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentExamMarkDetails;
					if (data != undefined) {
						setStudentMarkData(data[0]);
						setTotalMark(data[0].totalMark);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [examTypeName, setExamTypeName] = useState<any>('');
	const [result, setResult] = useState<any>('');
	const [resultImage, setResultImage] = useState<any>('');
	const [totalScore, setTotalScore] = useState<any>('');
	const [classMark, setClassMark] = useState<any>('');
	const [percentage, setPercentage] = useState<any>('');

	function onActivate(i: any) {
		setExamTypeName(i.examTypeName);
		setResult(i.result);
		setResultImage(i.resultImage);
		setTotalScore(i.totalScored);
		setClassMark(i.classRank);
		setPercentage(i.percentage);
		setStudentSubjectWiseMarkData(i.markDetails);
		setSubjectWiseMarkOffCanvas(true);
	}

	function closeAndReset() {
		setAbsentDetailsOffCanvas(false);
		setSubjectWiseMarkOffCanvas(false);
	}

	const [studentSkillsData, setStudentSkillsData] = useState<any>([]);
	const colors = ['info', 'success', 'warning', 'secondary', 'primary', 'dark'];

	const [skillNoDataMsg, setSkillNoDataMsg] = useState<any>('');

	function getStudentSkillsList(studentDetailsId: any) {
		getStudentSkills(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentSkills[0].skills;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							for (let i = 0; i < data.length; i++) {
								data[i].color = colors[Math.floor(Math.random() * colors.length)];
							}
						}
						setStudentSkillsData(data);
					} else {
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setStudentSkillsData([]);
					setDataSuccess(false);
					setSkillNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getHobbieDetails(studentDetailsId: any) {
		getStudentHobbieDetails(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentHobbieDetails[0].hobbies;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							data[i].color = colors[Math.floor(Math.random() * colors.length)];
						}
						setHobbieDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					setHobbieDetailsData([]);
					setHobbieNoDataMsg(response.data.message);
					//toasts(response.data.message, "Error")
					setDataSuccess(false);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [studentSkillsId, setStudentSkillsId] = useState<any>('');

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	function setDeleteStudentSkills() {
		return {
			studentSkillsId: studentSkillsId,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
		};
	}

	function deleteStudentSkillsSubmit() {
		showLoader(true);
		if (studentSkillsId > 0) {
			let leavePostData = setDeleteStudentSkills();
			deleteStudentSkills(
				leavePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						setIsDeleteOpen(false);
						getStudentSkillsList(studentDetailsId);
						setStudentSkillsId('');
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (studentSkillsId == 0 || studentSkillsId == undefined) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	return (
		<>
			{studentDetailsData.map((student: any) => (
				<PageWrapper
					title={`${student.studentName}`}
					ref={componentRef}
					key={student.studentDetailsId}>
					{userTypeId != 9 ? (
						<SubHeader>
							<SubHeaderLeft>
								<Button
									color='info'
									isLink
									icon='ArrowBack'
									onClick={() => navigate(-1)}>
									Back to List
								</Button>
								<SubheaderSeparator />
							</SubHeaderLeft>
							{userTypeId == 1 ? (
								<SubHeaderRight>
									<Button
										color='primary'
										isLight
										icon='Edit'
										tag='a'
										to={`../academic/updateStudent/${student.studentDetailsId}`}>
										Edit
									</Button>
								</SubHeaderRight>
							) : (
								<></>
							)}
						</SubHeader>
					) : (
						<SubHeader>
							<SubHeaderLeft>
								<Button
									color='info'
									isLink
									icon='ArrowBack'
									onClick={() => navigate(-1)}>
									Back to List
								</Button>
								<SubheaderSeparator />
							</SubHeaderLeft>
						</SubHeader>
					)}
					<Page>
						<div className='row'>
							<div className='col-lg-5'>
								<Card className='shadow-3d-info'>
									<CardBody>
										<div className='row g-3'>
											<div className='col-12 d-flex justify-content-center'>
												{student.profilePath != null ? (
													<img
														style={{ borderRadius: '50%' }}
														src={student.profilePath}
														width='100'
														height='100'
														onError={(e: any) =>
															pictNotLoading(e, student.genderId)
														}
													/>
												) : (
													<img
														src={profilePic(student.genderId)}
														width='100'
														height='100'
													/>
												)}
											</div>
											<div className='col-12'>
												<div className='row g-2'>
													<span className='fs-4 fw-bold me-2  d-flex justify-content-center text-center text-uppercase'>{`${student.studentName}`}</span>
													<div className='h5 text-muted text-lowercase opacity-50 d-flex justify-content-center text-uppercase'>
														{student.admissionNo}
													</div>
													<div className='col-4'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='HowToReg'
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold mb-0 '>
																	{student.studentRollNo ? (
																		student.studentRollNo
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}{' '}
																</div>
																<div className='text-muted'>
																	Roll No
																</div>
															</div>
														</div>
													</div>
													<div className='col-4'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon={
																		student.genderId == 1
																			? 'Male'
																			: 'Female'
																	}
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold  mb-0'>
																	{student.gender ? (
																		student.gender
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
																<div className='text-muted'>
																	Gender
																</div>
															</div>
														</div>
													</div>
													<div className='col-4'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='PhoneIphone'
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold  mb-0'>
																	{student.smsToBeSentNumber ? (
																		student.smsToBeSentNumber
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
																<div className='text-muted'>
																	Mobile
																</div>
															</div>
														</div>
													</div>
													<div className='col-4 mt-4'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='DateRange'
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold  mb-0'>
																	{student.dateOfBirthForView ? (
																		student.dateOfBirthForView
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
																<div className='text-muted'>
																	Date of Birth
																</div>
															</div>
														</div>
													</div>
													<div className='col-8 mt-4'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Mail'
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold  mb-0'>
																	{student.email ? (
																		student.email
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h4'
																		/>
																	)}
																</div>
																<div className='text-muted'>
																	Email Address
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>

								<AttendanceStatus
									studentDetailsId={studentDetailsId}
									height={197}
								/>

								{studentSkillsData != '' ? (
									<Card className='border border-light'>
										<CardHeader>
											<CardLabel icon='Stream' iconColor='warning'>
												<CardTitle tag='div' className='h5'>
													Skill
												</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody>
											{studentSkillsData != '' ? (
												<div className='row g-2'>
													{studentSkillsData.map((item: any) => (
														<div className='col-auto'>
															{userTypeId == 9 || userTypeId == 10 ? (
																<Badge
																	className={`border border-${item.color} rounded-1 px-3 py-2`}
																	isLight
																	color={item.color}>
																	<Icon
																		icon={'AccessibilityNew'}
																		size='lg'
																		className='me-1'
																	/>
																	{item.skillType}
																</Badge>
															) : (
																<InputGroup>
																	<Badge
																		className={`border border-${item.color} rounded-0 px-3 py-2`}
																		isLight
																		color={item.color}>
																		<Icon
																			icon={
																				'AccessibilityNew'
																			}
																			size='lg'
																			className='me-1'
																		/>
																		{item.skillType}
																	</Badge>
																	<Button
																		onClick={() => {
																			setIsDeleteOpen(true);
																			setStudentSkillsId(
																				item.studentSkillsId,
																			);
																		}}
																		isOutline
																		color={item.color}
																		icon='Delete'></Button>
																</InputGroup>
															)}
														</div>
													))}
												</div>
											) : (
												<div className='row'>
													<div className='col'>
														<Alert
															color='warning'
															isLight
															icon='Report'
															className='mb-0'>
															{skillNoDataMsg}
														</Alert>
													</div>
												</div>
											)}
										</CardBody>
									</Card>
								) : null}

								<Card>
									<CardHeader>
										<CardLabel icon='ShowChart' iconColor='secondary'>
											<CardTitle tag='div' className='h5'>
												Fee Status
											</CardTitle>
										</CardLabel>
										<CardActions>
											<strong>Till Now</strong>.
										</CardActions>
									</CardHeader>
									<CardBody>
										<div className='col-12'>
											{studentTotalFeeCollectionData.map(
												(studentTotalFee: any) => (
													<div
														className='row g-4 align-items-center'
														key={studentTotalFee.studentDetailsId}>
														<div className='col-xl-6'>
															<div
																className={classNames(
																	'd-flex align-items-center rounded-2 p-3',
																	{
																		'bg-l10-info':
																			!darkModeStatus,
																		'bg-lo25-info':
																			darkModeStatus,
																	},
																)}>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='DoneAll'
																		size='3x'
																		color='info'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold fs-4 text-info mb-0'>
																		{studentTotalFee.termFees_grossPayable +
																			studentTotalFee.busFees_grossPayable +
																			studentTotalFee.extraFees_grossPayable}
																	</div>
																	<div className='text-muted'>
																		<b> TOTAL</b>
																	</div>
																</div>
															</div>
														</div>
														<div className='col-xl-6'>
															<div
																className={classNames(
																	'd-flex align-items-center rounded-2 p-3',
																	{
																		'bg-l10-success':
																			!darkModeStatus,
																		'bg-lo25-success':
																			darkModeStatus,
																	},
																)}>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='Savings'
																		size='3x'
																		color='success'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold fs-4 text-success mb-0'>
																		{studentTotalFee.termFees_amountPaid +
																			studentTotalFee.termFees_concession +
																			studentTotalFee.busFees_amountPaid +
																			studentTotalFee.busFees_concession +
																			studentTotalFee.extraFees_amountPaid +
																			studentTotalFee.extraFees_concession}
																	</div>
																	<div className='text-muted'>
																		<b>PAID</b>
																	</div>
																</div>
															</div>
														</div>
														<div className='col-xl-6'>
															<div
																className={classNames(
																	'd-flex align-items-center rounded-2 p-3',
																	{
																		'bg-l10-danger':
																			!darkModeStatus,
																		'bg-lo25-danger':
																			darkModeStatus,
																	},
																)}>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='Celebration'
																		size='3x'
																		color='danger'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold fs-4 text-danger mb-0'>
																		{studentTotalFee.termFees_netPayable +
																			studentTotalFee.busFees_netPayable +
																			studentTotalFee.extraFees_netPayable}
																	</div>
																	<div className='text-muted'>
																		<b>BALANCE</b>
																	</div>
																</div>
															</div>
														</div>
														<div className='col-xl-6'>
															<div
																className={classNames(
																	'd-flex align-items-center rounded-2 p-3',
																	{
																		'bg-l10-secondary':
																			!darkModeStatus,
																		'bg-lo25-secondary':
																			darkModeStatus,
																	},
																)}>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='Timer'
																		size='3x'
																		color='secondary'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold fs-4 text-secondary mb-0'>
																		{
																			studentTotalFee.totalFineAmount
																		}
																	</div>
																	<div className='text-muted'>
																		<b>FINE</b>
																	</div>
																</div>
															</div>
														</div>
													</div>
												),
											)}
										</div>
									</CardBody>
								</Card>
								{/* {studentMarkData != '' ?
                                    <Card>
                                        <CardHeader>
                                            <CardLabel icon='PlaylistAddCheck' iconColor='info'>
                                                <CardTitle tag='div' className='h5 text-uppercase'>
                                                    {studentMarkData.examTypeName}
                                                </CardTitle>
                                            </CardLabel>
                                            <CardActions>
                                                <strong>Total : {totalMark}</strong>.
                                            </CardActions>
                                        </CardHeader>
                                        <CardBody >
                                            <div className='col-12 '>
                                                {[studentMarkData].map((studentMark: any) => (
                                                    <div className='row g-4 pb-3 align-items-center cursor-pointer' key={studentMark.examDetailsId} onClick={() => { onActivate(studentMark) }}>
                                                        <div className='col-xl-6'>
                                                            <div
                                                                className={classNames(
                                                                    'd-flex align-items-center rounded-2 p-3',
                                                                    {
                                                                        'bg-l10-success': !darkModeStatus,
                                                                        'bg-lo25-success': darkModeStatus,
                                                                    },
                                                                )}>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='Score' size='3x' color='success' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-4 text-success mb-0'>
                                                                        {studentMark.totalScored}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        <b> Scored</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-xl-6 '>
                                                            <div
                                                                className={classNames(
                                                                    'd-flex align-items-center rounded-2 p-3',
                                                                    {
                                                                        'bg-l10-danger': !darkModeStatus,
                                                                        'bg-lo25-danger': darkModeStatus,
                                                                    },
                                                                )}>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='Leaderboard' size='3x' color='danger' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-4 text-danger mb-0'>
                                                                        {studentMark.classRank}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        <b>Rank</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </CardBody>
                                    </Card> : null
                                } */}
							</div>

							<div className='col-lg-7'>
								<Card className='shadow-3d-primary'>
									<CardBody>
										<div className='row g-4'>
											<div className='col-md-6 md-4'>
												<Card
													className={`bg-l${
														darkModeStatus ? 'o25' : '25'
													}-primary bg-l${
														darkModeStatus ? 'o50' : '10'
													}-primary-hover transition-base rounded-2 mb-4`}
													shadow='sm'>
													<CardHeader className='bg-transparent'>
														<CardLabel>
															<CardTitle tag='div' className='h5'>
																Address
															</CardTitle>
														</CardLabel>
													</CardHeader>
													<CardBody>
														<div className='d-flex align-items-center pb-3'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='House'
																	size='4x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold mb-0'>
																	{student.address ? (
																		student.address
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
												<Card
													className={`bg-l${
														darkModeStatus ? 'o25' : '25'
													}-danger bg-l${
														darkModeStatus ? 'o50' : '10'
													}-danger-hover transition-base rounded-2 mb-0`}
													shadow='sm'>
													<CardHeader className='bg-transparent'>
														<CardLabel>
															<CardTitle tag='div' className='h5'>
																Other Details
															</CardTitle>
														</CardLabel>
													</CardHeader>
													<CardBody>
														<div className='col-12'>
															<div className='row g-2'>
																<div className='col-6'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='Flag'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold mb-0 '>
																				{student.nationality ? (
																					student.nationality
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}{' '}
																			</div>
																			<div className='text-muted'>
																				Nationality
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-6'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='Article'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold mb-0'>
																				{student.religion ? (
																					student.religion
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Religion
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-6'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='BookmarkAdded'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold mb-0'>
																				{student.casteName ? (
																					student.casteName
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}{' '}
																			</div>
																			<div className='text-muted'>
																				Caste Name
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-6'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='Cached'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold  mb-0'>
																				{student.category ? (
																					student.category
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}{' '}
																			</div>
																			<div className='text-muted'>
																				Category
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
											</div>
											<div className='col-md-6'>
												<Card
													className={`bg-l${
														darkModeStatus ? 'o25' : '25'
													}-success bg-l${
														darkModeStatus ? 'o50' : '10'
													}-success-hover transition-base rounded-2 mb-0`}
													stretch
													shadow='sm'>
													<CardHeader className='bg-transparent'>
														<CardLabel>
															<CardTitle tag='div' className='h5'>
																Class Info
															</CardTitle>
														</CardLabel>
													</CardHeader>
													<CardBody className='pt-0'>
														<div className='row g-2 d-flex align-items-center'>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='school'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold mb-0'>
																			{student.standardName ? (
																				student.standardName
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Standard
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='Subject'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold  mb-0'>
																			{student.sectionName ? (
																				student.sectionName
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Section
																		</div>
																	</div>
																</div>
															</div>
															{student.standardDetailsId == 11 ||
															student.standardDetailsId == 12 ? (
																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='SpeakerGroup'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold  mb-0'>
																				{student.courseName ? (
																					student.courseName
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Course
																			</div>
																		</div>
																	</div>
																</div>
															) : null}
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='CalendarToday'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold  mb-0'>
																			{student.batchName ? (
																				student.batchName
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Batch
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='HomeWork'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold mb-0'>
																			{student.medium ? (
																				student.medium
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}{' '}
																		</div>
																		<div className='text-muted'>
																			Medium
																		</div>
																	</div>
																</div>
															</div>
															{/* <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='AccessTime' size='2x' color='primary' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold  mb-0'>
                                                                            {student.shiftName ? student.shiftName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            shift
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='EmojiPeople'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold  mb-0'>
																			{student.referredBy ? (
																				student.referredBy
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Referred By
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='DirectionsBus'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold  mb-0'>
																			{student.areaName ? (
																				student.areaName
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Transport Area Name
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
											</div>
										</div>
									</CardBody>
								</Card>

								{hobbieDetailsData != '' ? (
									<Card className='border border-light'>
										<CardHeader>
											<CardLabel icon='AddReaction' iconColor='warning'>
												<CardTitle tag='div' className='h5'>
													Hobbie
												</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody>
											{hobbieDetailsData != '' ? (
												<div className='row g-2'>
													{hobbieDetailsData.map((item: any) => (
														<div className='col-auto'>
															{userTypeId == 9 || userTypeId == 10 ? (
																<Badge
																	className={`border border-${item.color} rounded-1 px-3 py-2`}
																	isLight
																	color={item.color}>
																	<Icon
																		icon={'AccessibilityNew'}
																		size='lg'
																		className='me-1'
																	/>
																	{item.hobbieType}
																</Badge>
															) : (
																<InputGroup>
																	<Badge
																		className={`border border-${item.color} rounded-0 px-3 py-2`}
																		isLight
																		color={item.color}>
																		<Icon
																			icon={
																				'AccessibilityNew'
																			}
																			size='lg'
																			className='me-1'
																		/>
																		{item.hobbieType}
																	</Badge>
																	<Button
																		onClick={() => {
																			setIsDeleteOpen(true);
																			setStudentHobbieDetailsId(
																				item.studentHobbieDetailsId,
																			);
																		}}
																		isOutline
																		color={item.color}
																		icon='Delete'></Button>
																</InputGroup>
															)}
														</div>
													))}
												</div>
											) : (
												<div className='row'>
													<div className='col'>
														<Alert
															color='warning'
															isLight
															icon='Report'
															className='mb-0'>
															{hobbieNoDataMsg}
														</Alert>
													</div>
												</div>
											)}
										</CardBody>
									</Card>
								) : null}
								<Card>
									<CardHeader>
										<CardLabel icon='Task' iconColor='primary'>
											<CardTitle tag='div' className='h5'>
												Fee Details
											</CardTitle>
										</CardLabel>
										<CardActions>
											{userTypeId != 9 &&
											userTypeId != 10 &&
											userTypeId != 2 &&
											userTypeId != 3 &&
											userTypeId != 4 &&
											userTypeId != 5 ? (
												<Button
													color='storybook'
													isLight
													icon='ArrowForward'
													onClick={() =>
														navigate(
															`../general/termFeesCollection/${studentDetailsId}`,
														)
													}>
													Fee Details
												</Button>
											) : (
												<></>
											)}
										</CardActions>
									</CardHeader>
									<CardBody>
										{studentTotalFeeCollectionData ? (
											<div className='table-responsive'>
												<table className='table table-modern table-hover'>
													<thead className='text-center text-uppercase align-middle'>
														<tr>
															<th className='table-info'></th>
															<th className='table-info'>
																Term <br />
																Fees
															</th>
															<th className='table-info'>
																Fine <br />
																Fees
															</th>
															<th className='table-info'>
																Bus <br />
																Fees
															</th>
															<th className='table-info'>
																Extra <br />
																Fees
															</th>
														</tr>
													</thead>

													{studentTotalFeeCollectionData.map(
														(studentTotalFeeCollectionData: any) => (
															<tbody
																className='text-center align-middle'
																key={
																	studentTotalFeeCollectionData.studentDetailsId
																}>
																<tr>
																	<th>GROSS PAYABLE</th>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.termFees_grossPayable
																			}
																		</strong>
																	</td>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.totalFineAmount
																			}
																		</strong>
																	</td>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.busFees_grossPayable
																			}
																		</strong>
																	</td>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.extraFees_grossPayable
																			}
																		</strong>
																	</td>
																</tr>
																<tr>
																	<th>AMOUNT PAID</th>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.termFees_amountPaid
																			}
																		</strong>
																	</td>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.totalFineAmount
																			}
																		</strong>
																	</td>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.busFees_amountPaid
																			}
																		</strong>
																	</td>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.extraFees_amountPaid
																			}
																		</strong>
																	</td>
																</tr>
																<tr>
																	<th>CONCESSION</th>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.termFees_concession
																			}
																		</strong>
																	</td>
																	<td>
																		<strong>0</strong>
																	</td>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.busFees_concession
																			}
																		</strong>
																	</td>
																	<td>
																		<strong>
																			{
																				studentTotalFeeCollectionData.extraFees_concession
																			}
																		</strong>
																	</td>
																</tr>
																<tr>
																	<th>NET PAYABLE</th>
																	<td>
																		{studentTotalFeeCollectionData.termFees_netPayable ===
																		0 ? (
																			<strong className='text-success'>
																				{
																					studentTotalFeeCollectionData.termFees_netPayable
																				}
																			</strong>
																		) : (
																			<strong className='text-danger'>
																				{
																					studentTotalFeeCollectionData.termFees_netPayable
																				}
																			</strong>
																		)}
																	</td>

																	<td>
																		<strong>0</strong>
																	</td>

																	<td>
																		{studentTotalFeeCollectionData.busFees_netPayable ===
																		0 ? (
																			<strong className='text-success'>
																				{
																					studentTotalFeeCollectionData.busFees_netPayable
																				}
																			</strong>
																		) : (
																			<strong className='text-danger'>
																				{
																					studentTotalFeeCollectionData.busFees_netPayable
																				}
																			</strong>
																		)}
																	</td>
																	<td>
																		{studentTotalFeeCollectionData.extraFees_netPayable ===
																		0 ? (
																			<strong className='text-success'>
																				{
																					studentTotalFeeCollectionData.extraFees_netPayable
																				}
																			</strong>
																		) : (
																			<strong className='text-danger'>
																				{
																					studentTotalFeeCollectionData.extraFees_netPayable
																				}
																			</strong>
																		)}
																	</td>
																</tr>
															</tbody>
														),
													)}
												</table>
												<br />

												{studentTotalFeeCollectionData.map(
													(studentTotalFeeCollectionData: any) => (
														<div
															className='col-12 d-flex justify-content-end'
															key={
																studentTotalFeeCollectionData.studentDetailsId
															}>
															<p className='fw-bold fs-5 mb-0'>
																{' '}
																Balance :{' '}
															</p>
															<strong
																className={
																	studentTotalFeeCollectionData.total_netPayable ==
																	0
																		? 'text-success'
																		: 'text-danger'
																}>
																<p className='fw-bold fs-5 mb-0 ms-2'>
																	{
																		studentTotalFeeCollectionData.total_netPayable
																	}
																</p>
															</strong>
														</div>
													),
												)}
											</div>
										) : (
											<div className='text-danger col-12 d-flex justify-content-center mt-5'>
												<p className='fw-bold fs-5 mb-0 mt-5'>
													Fees Not Collected Yet .{' '}
												</p>
											</div>
										)}
									</CardBody>
								</Card>
							</div>
						</div>
					</Page>
					<OffCanvas
						setOpen={setAbsentDetailsOffCanvas}
						isOpen={absentDetailsOffCanvas}
						titleId='absentDetailsOffCanvas'
						isBodyScroll
						placement='end'>
						<OffCanvasHeader
							setOpen={setAbsentDetailsOffCanvas}
							onClick={closeAndReset}>
							<OffCanvasTitle id='absentDetailsOffCanvas'>
								Absent Details
							</OffCanvasTitle>
						</OffCanvasHeader>
						<OffCanvasBody>
							{studentAbsentAttendanceData.length != '' ? (
								studentAbsentAttendanceData.map((student: any) => (
									<Card key={student.studentAttendanceId} shadow='lg'>
										<CardBody>
											<div className='col-12'>
												<div className='row'>
													<div className='col d-flex align-items-center'>
														<div className='flex-shrink-0'>
															<div
																className='ratio ratio-1x1 me-3'
																style={{ width: 48 }}>
																<div
																	className={classNames(
																		'rounded-2',
																		'd-flex align-items-center justify-content-center',
																		{
																			'bg-l10-dark':
																				!darkModeStatus,
																			'bg-l90-dark':
																				darkModeStatus,
																		},
																	)}>
																	<span className='fw-bold'>
																		{
																			student.dateOfAttendanceForView.split(
																				' ',
																			)[0]
																		}
																	</span>
																</div>
															</div>
														</div>
														<div className='flex-grow-1'>
															<div className='fs-6'>
																{student.dateOfAttendanceForView}
															</div>
															<div className='text-muted'>
																<small>
																	{student.remarks != ''
																		? student.remarks
																		: '-'}
																</small>
															</div>
														</div>
													</div>
													<div className='col-auto text-end'>
														<div className='flex-shrink-0'>
															<div
																style={{ width: 100 }}
																className={classNames(
																	`bg-l${
																		darkModeStatus
																			? 'o25'
																			: '10'
																	}-danger text-danger fw-bold py-2 rounded-pill me-3 text-center`,
																)}>
																{student.absentOrPermission}
															</div>
														</div>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								))
							) : (
								<div className='d-flex justify-content-evenly'>
									<strong>FULL PRESENT</strong>
								</div>
							)}
						</OffCanvasBody>
					</OffCanvas>
					<OffCanvas
						setOpen={setSubjectWiseMarkOffCanvas}
						isOpen={subjectWiseMarkOffCanvas}
						titleId='subjectWiseMarkOffCanvas'
						isBodyScroll
						placement='end'>
						<OffCanvasHeader
							setOpen={setSubjectWiseMarkOffCanvas}
							onClick={closeAndReset}>
							<OffCanvasTitle id='subjectWiseMarkOffCanvas'>
								Mark Details
							</OffCanvasTitle>
						</OffCanvasHeader>
						<OffCanvasBody>
							{/* <div className='row ' style={{ backgroundColor: 'red', borderRadius: '0% 50% 50% 50%;' }}>
                                <div className='col-12 d-flex justify-content-center text-uppercase mt-3'>{student.studentName}</div>
                                <div className='col-12 d-flex justify-content-center'>
                                    {student.admissionNo}
                                </div>
                                <div className='col-12 d-flex justify-content-center'>
                                    {examTypeName}
                                </div>
                                <div className='col-12 d-flex justify-content-center'>
                                    <span style={{ marginRight: '10px' }}>{result}</span> {resultImage != null ?
                                        <img style={{ borderRadius: '50%' }} src={resultImage}
                                            width="20" height="20" />
                                        : <img src={profilePic(student.genderId)}
                                            width="20" height="20" />}
                                </div>
                            </div>
                            <div className='row d-flex justify-content-evenly' style={{ zIndex: 9999, background: 'linear-gradient(to bottom, red 50%, white 50%)' }}>
                                <div className='col-lg-3  align-self-center mt-3' style={{ backgroundColor: 'white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                    <div className='fw-bold d-flex justify-content-center text-black mb-0' >
                                        {totalScore}
                                    </div>
                                    <div className='text-muted d-flex justify-content-center'>
                                        <b>Total Mark</b>
                                    </div>
                                </div>
                                <div className='col-lg-3  align-self-center mt-3' style={{ backgroundColor: 'white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                    <div className='fw-bold d-flex justify-content-center  text-black mb-0'>
                                        {classMark}
                                    </div>
                                    <div className='text-muted d-flex justify-content-center'>
                                        <b>Rank</b>
                                    </div>
                                </div>
                                <div className='col-lg-3 align-self-center mt-3' style={{ backgroundColor: 'white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                    <div className='fw-bold d-flex justify-content-center text-black mb-0'>
                                        {percentage}
                                    </div>
                                    <div className='text-muted d-flex justify-content-center'>
                                        <b>Percentage</b>
                                    </div>
                                </div>
                            </div> */}
							<div className='row g-4'>
								<div className='col-md-4'>
									<Card
										className={`bg-l${
											darkModeStatus ? 'o25' : '25'
										}-success bg-l${
											darkModeStatus ? 'o50' : '10'
										}-success-hover transition-base rounded-2 mb-0`}
										shadow='sm'>
										<CardBody>
											<div className='d-flex align-items-center pb-1'>
												<div className='flex-grow-1 '>
													<div className='fw-bold fs-3 text-success mb-0  d-flex justify-content-center '>
														{totalScore}
													</div>
													<div className='text-muted  d-flex justify-content-center'>
														<b>Total Mark</b>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
								<div className='col-md-4'>
									<Card
										className={`bg-l${
											darkModeStatus ? 'o25' : '25'
										}-danger bg-l${
											darkModeStatus ? 'o50' : '10'
										}-danger-hover transition-base rounded-2 mb-0 `}
										shadow='sm'>
										<CardBody>
											<div className='d-flex align-items-center pb-1'>
												<div className='flex-grow-1 '>
													<div className='fw-bold fs-3 text-danger mb-0  d-flex justify-content-center '>
														{classMark}
													</div>
													<div className='text-muted  d-flex justify-content-center '>
														<b> Rank</b>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
								<div className='col-md-4'>
									<Card
										className={`bg-l${
											darkModeStatus ? 'o25' : '25'
										}-warning bg-l${
											darkModeStatus ? 'o50' : '10'
										}-warning-hover transition-base rounded-2 mb-0`}
										shadow='sm'>
										<CardBody>
											<div className='d-flex align-items-center pb-1'>
												<div className='flex-grow-1 '>
													<div className='fw-bold fs-3 text-warning mb-0  d-flex justify-content-center'>
														{percentage}
													</div>
													<div className='text-muted  d-flex justify-content-center '>
														<b>Percentage</b>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							{studentSubjectWiseMarkData != '' ? (
								<table className='table table-modern table-hover text-center text-nowrap text-md-nowrap mg-b-0 mt-4'>
									<thead>
										<tr className='table-success text-decoration-underline'>
											<th>Subject</th>
											<th>Total</th>
											<th>Mark</th>
										</tr>
									</thead>
									<tbody>
										{studentSubjectWiseMarkData.map((rowData: any) => (
											<tr key={rowData.examDetailsId}>
												<td>{rowData.paperName}</td>
												<td>{rowData.totalMark}</td>
												<td>{rowData.totalScoredMark}</td>
											</tr>
										))}
										<tr className='table-danger'>
											<td>Total</td>
											<td>
												{studentSubjectWiseMarkData
													.map((dataMark: any) =>
														dataMark.totalMark > 0
															? dataMark.totalMark
															: 0,
													)
													.reduce((a: any, b: any) => a + b, 0)}
											</td>
											<td>
												{studentSubjectWiseMarkData
													.map((dataScore: any) =>
														dataScore.totalScoredMark > 0
															? dataScore.totalScoredMark
															: 0,
													)
													.reduce((a: any, b: any) => a + b, 0)}
											</td>
										</tr>
									</tbody>
								</table>
							) : null}
						</OffCanvasBody>
					</OffCanvas>

					<Modal
						setIsOpen={setIsDeleteOpen}
						isOpen={isDeleteOpen}
						titleId='deleteBatchMaster'
						isStaticBackdrop
						size='sm'>
						<ModalHeader>
							<></>
						</ModalHeader>
						<ModalBody>
							<div className='row g-2'>
								<div className='col-12 d-flex justify-content-center fs-4'>
									<h5>
										<strong>Are you sure want to delete ?</strong>
									</h5>
								</div>
								<div className='col-10'>
									<div className='d-flex gap-2 justify-content-end'>
										<Button
											color='danger'
											size={'sm'}
											onClick={deleteStudentSkillsSubmit}>
											Yes
										</Button>
										<Button
											color='info'
											size={'sm'}
											onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
											No
										</Button>
									</div>
								</div>
								<div className='col-10'></div>
							</div>
						</ModalBody>
					</Modal>

					<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
				</PageWrapper>
			))}
		</>
	);
};

export default StudentProfile;
