import classNames from 'classnames';
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import useDarkMode from '../../../hooks/useDarkMode';
import useSortableData from '../../../hooks/useSortableData';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../services/AlertService';
import { getLicenseKey } from '../../../services/application.settings';
import { convertDateToEpoch, getColumnsForDataTable, getCurrentDateAndTime, getUserType, updateColumnsForDataTable, updateFilter } from '../../../services/common.service';
import { toasts } from '../../../services/toast.service';
import Input from '../../bootstrap/forms/Input';
import Icon from '../../icon/Icon';
import SearchableSelect from '../../../common/components/SearchableSelect';
import NoDataMsg from '../../../common/components/NoDataMsg';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import AuthContext from '../../../contexts/authContext';
import { showLoader, TableLoader } from '../../../services/loader.services';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import { useReactToPrint } from 'react-to-print';
import { addStaffAttendance, getStaffAttendanceReportList, getStaffAttendanceSummaryReport } from '../../../services/attendance.service';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import { getFirstLetter } from '../../../helpers/helpers';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';

const StaffAttendanceList = () => {

	useEffect(() => {
		getUserTypeList();
		let dateInMilliSeconds = convertDateToEpoch(getCurrentDateAndTime('date'));
		getStaffAttendanceReport(dateInMilliSeconds, 0, 0);
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);

	const [userTypeData, setUserTypeData] = useState<any>([]);
	const [staffTypeId, setStaffTypeId] = useState<any>({ value: 0, label: 'Select All' });
	const [staffDetailsData, setStaffDetailsData] = useState<any>([]);
	const [staffDetailsArray, setStaffDetailsArray] = useState<any>([]);
	const [staffDetailsReportData, setStaffDetailsReportData] = useState<any>([]);

	// For Table
	const [dataSuccess, setDataSuccess] = useState(false);
	const [isReport, setIsReport] = useState('');
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(1000);
	const [isLoader, setIsLoader] = useState(false);
	const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([]);
	const [attendanceConfirmationArray, setAttendanceConfirmationArray] = useState<any>([]);
	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [isWorkingDay, setIsWorkingDay] = useState(false);

	const { items: items1, requestSort: requestSort1, getClassNamesFor: getClassNamesFor1 } = useSortableData(staffDetailsReportData);
	const { items: items, requestSort: requestSort, getClassNamesFor: getClassNamesFor } = useSortableData(staffDetailsData);

	const currentPageData1 = dataPagination(items1, currentPage, perPage);
	const currentPageData = dataPagination(items, currentPage, perPage);

	const studentAttendanceForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			dateOfAttendance: getCurrentDateAndTime('date'),
		},
		validate: (values) => {
			const errors: {
				dateOfAttendance?: string;
			} = {};

			if (!values.dateOfAttendance) {
				errors.dateOfAttendance = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => { },
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData: any = currentPageData.filter(
		(i) =>
			(i.userType !== null && i.userType.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase())) ||
			(i.staffName !== null && i.staffName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase())),
	);

	// Filter
	const filteredData1 = updateFilter(currentPageData1, columnVisibilityData, columnVisibilityForm);


	function selectAttendanceDate(isReport: any) {
		setIsLoader(false);
		setDataSuccess(false);
		setIsOpenListCard(true);
		// check is working day
		let dateInMilliSeconds = convertDateToEpoch(studentAttendanceForm.values.dateOfAttendance);
		let currentDateMilliSeconds = convertDateToEpoch(getCurrentDateAndTime('date'));

		if (dateInMilliSeconds == currentDateMilliSeconds) {
			viewStaffAttendanceReport(isReport);
		} else {
			setAlertStatus({ message: 'Please select today date !', type: 'error' });
			setIsOpen(true);
		}
	}

	function viewStaffAttendanceReport(isReport: any) {
		setIsWorkingDay(false);
		setStaffDetailsArray([]);
		setDataSuccess(false);
		setIsLoader(true);
		let dateInMilliSeconds = convertDateToEpoch(studentAttendanceForm.values.dateOfAttendance);
		getStaffAttendanceReport(dateInMilliSeconds, 0, isReport);
	}

	function getStaffAttendanceReport(dateOfAttendance: any, userTypeId: any, isReport: any) {
		showLoader(true)
		getStaffAttendanceReportList(dateOfAttendance, userTypeId, 1,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffAttendanceReportList;
					if (data != undefined) {
						showLoader(false)
						getStaffAttendanceSummaryReportList(dateOfAttendance)
						getColumnsForTable('getStaffAttendanceReportList', 'get');
						setIsReport('Report');
						setStaffDetailsReportData(data);
						setDataSuccess(true);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setDataSuccess(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					if (studentAttendanceForm.values.dateOfAttendance == getCurrentDateAndTime('date') && isReport == 0) {
						getStaffAttendanceSummaryReportList(dateOfAttendance)
					} else {
						setIsReport('Report');
						getColumnsForTable('getStaffAttendanceReportList', 'get');
						setDataSuccess(true);
						setStaffDetailsReportData([]);
						setNoDataMsg(response.data.message);
					}

				} else {
					setDataSuccess(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [staffAttendanceSummaryData, setStaffAttendanceSummaryData] = useState<any>([])

	function getStaffAttendanceSummaryReportList(dateOfAttendance: number) {
		getStaffAttendanceSummaryReport(dateOfAttendance, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffAttendanceSummaryReport[0];
					if (data != undefined) {
						setIsLoader(false);
						setStaffAttendanceSummaryData(data);
						if (data.totalAbsent == 0) {
							showLoader(false)
							setIsReport('allPresent');
						}
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success == false) {
					setIsLoader(false);
					setIsReport('');
					viewStaffAttendance()
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						const userTypeData = [{ userTypeId: 0, userType: 'Select All' }];
						data.forEach((obj: { userTypeId: number; userType: string }) => {
							let userType = userTypeData.push(obj);
						});
						setUserTypeData(userTypeData);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheckedSelectAll, setIsCheckedSelectAll] = useState(false)
	const [isPresentAll, setIsPresentAll] = useState(false)
	const [checkLength, setCheckLength] = useState(0);

	function viewStaffAttendance() {
		setDataSuccess(false);
		setIsLoader(true);
		let dateInMilliSeconds = convertDateToEpoch(studentAttendanceForm.values.dateOfAttendance);
		getStaffListByUserType(dateInMilliSeconds, 0, 0);
		setIsWorkingDay(false);
	}

	function getStaffListByUserType(dateOfAttendance: any, staffTypeId: any, isReport: any) {
		getStaffAttendanceReportList(dateOfAttendance, staffTypeId, isReport,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffAttendanceReportList;
					if (data != undefined) {
						let pendingData = data.filter((item: any) => item.attendanceStatusId == 3)
						let pendingData1 = data.filter((item: any) => item.attendanceStatusId == 1)
						setCheckLength(pendingData.length);
						setIsPresentAll(pendingData1 != '' ? true : false)
						setIsCheckedSelectAll(pendingData == '' ? true : false)
						setStaffDetailsData(data);
						setFilteredDataToRefresh(data);
						setDataSuccess(true);
						setIsReport('');
						showLoader(false)
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
							data[i].isEditable = data[i].isEditable = true;
							data[i].isSelect = data[i].isSelect = false;
						}
					} else {
						setDataSuccess(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataSuccess(true);
					setIsReport('');
					setStaffDetailsData([]);
					setNoDataMsg(response.data.message);
					//toasts(response.data.message, "Error")
				} else {
					setDataSuccess(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const updateStaffDetailsIdValue = (e: any, cell: number, staffName: any, status: any) => {

		const newData: any = [];

		if (e == true) {
			filteredDataToRefresh.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == cell) {
					item['isSelect'] = true;
					item['isEditable'] = false;
					item['status'] = status;
				}
				newData.push(item);
			});
			setFilteredDataToRefresh(newData);
		}
		else if (e == false) {
			setIsCheckAll(false)
			filteredDataToRefresh.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == cell) {
					item['isSelect'] = false;
					item['isEditable'] = true;
					item['status'] = status;
					item['remarks'] = '';
				}
				newData.push(item);
			});
			setFilteredDataToRefresh(newData);
		}

		if (attendanceConfirmationArray.length == 0) {
			const obj = {
				staffDetailsId: cell,
				staffName: staffName,
			};
			attendanceConfirmationArray.push(obj);
		} else {
			let selectedItem1: any;
			attendanceConfirmationArray.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == cell) {
					selectedItem1 = item;
					selectedItem1['staffDetailsId'] = cell;
				}
			});
			if (selectedItem1 != undefined && e == false) {
				attendanceConfirmationArray.splice(
					attendanceConfirmationArray.indexOf(selectedItem1),
					1,
				);
				selectedItem1['staffDetailsId'] = cell;
			}
			else if (selectedItem1 != undefined && e == true) {
				selectedItem1['staffDetailsId'] = cell,
					selectedItem1['staffName'] = staffName
			}
			else {
				var obj;
				obj = {
					staffDetailsId: cell,
					staffName: staffName,
				};

				attendanceConfirmationArray.push(obj);
			}
		}

		if (staffDetailsArray.length == 0) {
			const obj = {
				staffDetailsId: cell,
				absentOrPermission: status,
				remarks: '',
			};
			staffDetailsArray.push(obj);
		} else {
			let selectedItem: any;
			staffDetailsArray.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == cell) {
					selectedItem = item;
					selectedItem['staffDetailsId'] = cell;
				}
			});
			if (selectedItem != undefined && e == false) {
				staffDetailsArray.splice(staffDetailsArray.indexOf(selectedItem), 1);
				selectedItem['staffDetailsId'] = cell;
			}
			else if (selectedItem != undefined && e == true) {
				selectedItem['staffDetailsId'] = cell,
					selectedItem['absentOrPermission'] = status,
					selectedItem['remarks'] = ''
			}
			else {
				var obj;
				obj = {
					staffDetailsId: cell,
					absentOrPermission: status,
					remarks: '',
				};

				staffDetailsArray.push(obj);
				if (staffDetailsArray.length != 0) {
					if (staffDetailsArray.length == checkLength) {
						setIsCheckAll(true)
					}
				}
			}
		}
	};

	const updateRemarksValue = (remarks: any, cell: number) => {
		const newData: any = [];

		filteredData.forEach((item: any, index: any) => {
			if (item['staffDetailsId'] == cell) {
				item['remarks'] = remarks;
			}
			newData.push(item);
		});

		setFilteredDataToRefresh(newData);

		if (staffDetailsArray.length == 0) {
			const obj = {
				staffDetailsId: cell,
				absentOrPermission: '',
				remarks: remarks,
			};
			staffDetailsArray.push(obj);
		} else {
			let selectedItem: any;
			staffDetailsArray.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == cell) {
					selectedItem = item;
					selectedItem['remarks'] = remarks;
				}
			});
			if (selectedItem != undefined) {
				selectedItem['remarks'] = remarks;
			} else {
				var obj;
				obj = {
					staffDetailsId: cell,
					absentOrPermission: '',
					remarks: remarks,
				};

				staffDetailsArray.push(obj);
			}
		}
	};


	const selectAllStudents = (e: any) => {
		const newData: any = [];
		const newData1: any = [];
		setIsCheckAll(e)
		if (e == true) {

			staffDetailsArray.splice(0, staffDetailsArray.length)

			filteredDataToRefresh.forEach((item: any) => {

				filteredDataToRefresh.forEach((item: any, index: any) => {
					if (item['staffDetailsId'] == item.staffDetailsId) {
						item['status'] = 'Absent'
					}
					newData1.push(item);
				});
				setFilteredDataToRefresh(newData1);

				if (item.attendanceStatusId == 3) {
					const objForConfirmation = {
						staffDetailsId: item.staffDetailsId,
						staffName: item.staffName,
					};
					attendanceConfirmationArray.push(objForConfirmation);


					item.isSelect = true
					item.isEditable = false
					newData.push(item.studentDetailsId)
					var obj
					obj = {
						staffDetailsId: item.staffDetailsId,
						absentOrPermission: 'Absent',
						remarks: '',
					};
					staffDetailsArray.push(obj);
				}
			});
		} else {
			if (e == false) {
				filteredData.forEach((item: any) => {
					item.isSelect = false
					item.isEditable = true
					setStaffDetailsArray([])
					setAttendanceConfirmationArray([])
				});
			}
		}
	}

	function setStudentAttendance() {

		const absentStaffCount = staffDetailsArray.filter((item: any) => item.absentOrPermission == 'Absent')

		return {
			dateOfAttendance: studentAttendanceForm.values.dateOfAttendance,
			//userTypeId: staffTypeId?.value,
			staffDetails: staffDetailsArray,
			submittedBy: userAccountId,
			licenseKey: getLicenseKey,
			absentStaffCount: absentStaffCount?.length
		};
	}

	function studentAttendanceSubmit() {
		showLoader(true);
		if (studentAttendanceForm.isValid) {
			let attendancePostData = setStudentAttendance();
			addStaffAttendance(
				attendancePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						setStaffDetailsArray([]);
						setDataSuccess(false);
						setIsWorkingDay(false);
						setIsOpenListCard(true);
						let dateInMilliSeconds = convertDateToEpoch(getCurrentDateAndTime('date'));
						getStaffAttendanceReport(dateInMilliSeconds, 0, 0);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (studentAttendanceForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'warning');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStaffAttendanceReportList', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'warning');
		}
	}

	function closeAndReset() {
		setConfirmationOpen(false);
		setStaffTypeId('')
		setAttendanceConfirmationArray([])
		setCheckLength(0);
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			<PageWrapper title='Attendance'>
				<Page container='fluid'>

					{/* <Collapse isOpen={isOpenListCard}>
						<Card
							stretch
							data-tour='list'
							tag='form'
							noValidate
							onSubmit={studentAttendanceForm.handleSubmit}>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Staff Attendance
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row g-4'>
									<div className='col-3'>
										<FormGroup id='dateOfAttendance' label='Date' isFloating>
											<Input
												onChange={studentAttendanceForm.handleChange}
												value={studentAttendanceForm.values.dateOfAttendance}
												onBlur={studentAttendanceForm.handleBlur}
												isValid={studentAttendanceForm.isValid}
												isTouched={studentAttendanceForm.touched.dateOfAttendance}
												invalidFeedback={studentAttendanceForm.errors.dateOfAttendance}
												type='date'
											/>
										</FormGroup>
									</div>
									<div className='col-3'>
										<Button className='mt-2' icon='ArrowDownward' color='primary' onClick={() => selectAttendanceDate(0)}>
											View
										</Button>										
									</div>
								</div>
							</CardBody>
						</Card>
					</Collapse>

					<OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} /> */}

					{dataSuccess ? (
						<>
							{isReport == '' ? (
								<>
									<Card stretch data-tour='list'>
										<CardHeader borderSize={1}>
											<div className='d-flex col-5 justify-content-start'>
												<CardLabel icon='List' iconColor='info'>
													<CardTitle tag='div' className='h5'>
														Staff Attendance List
													</CardTitle>
												</CardLabel>
											</div>
											<div className='d-flex col-7 justify-content-center g-4'>
												<div className='col-2 justify-content-center'></div>
												<div
													className='d-flex col-5 justify-content-start'
													data-tour='search'>
													<label
														className='hide-in-pdf border-0 bg-transparent cursor-pointer mt-2'
														htmlFor='searchInput'>
														<Icon icon='Search' size='2x' color='primary' />
													</label>
													<Input
														id='searchInput'
														type='search'
														placeholder='Search...'
														onChange={columnVisibilityForm.handleChange}
														value={columnVisibilityForm.values.searchInput}
													/>
												</div>
												&nbsp;&nbsp;
												<div className='col-2 justify-content-center'>
													<Button
														icon='Save'
														color='primary'
														isDisable={staffDetailsArray.length > 0 ? false : true}
														onClick={() => setConfirmationOpen(true)}>
														Submit
													</Button>
												</div>
												<div className='col-3 justify-content-end'>
													<Button
														icon='PresentToAll'
														color='success'
														isDisable={staffDetailsArray.length > 0 || isPresentAll == true ? true : false}
														onClick={studentAttendanceSubmit}>
														Full Present
													</Button>
												</div>
											</div>
										</CardHeader>
										<CardBody className='table-responsive'>
											<table className='table table-modern table-hover text-nowrap'>
												<thead>
													<tr>
														<th scope='col'>
															<Checks
																disabled={isCheckedSelectAll}
																onChange={(e: any) => { selectAllStudents(e.target.checked) }}
																checked={isCheckAll}
															/>
														</th>
														<th
															scope='col'
															onClick={() => requestSort('sno')}
															className='cursor-pointer text-decoration-underline text-nowrap'>
															S.No{' '}
															<Icon
																size='lg'
																className={getClassNamesFor('sno')}
																icon='FilterList'
															/>
														</th>
														<th
															scope='col'
															onClick={() => requestSort('staffName')}
															className='cursor-pointer text-decoration-underline text-nowrap'>
															Staff Name{' '}
															<Icon size='lg' className={getClassNamesFor('staffName')} icon='FilterList' />
														</th>
														<th
															scope='col'
															onClick={() => requestSort('userType')}
															className='cursor-pointer text-decoration-underline text-nowrap'>
															User Type{' '}
															<Icon size='lg' className={getClassNamesFor('userType')} icon='FilterList' />
														</th>
														<th>Absent / Permission</th>
														<th>Remarks</th>
													</tr>
												</thead>
												<tbody>
													{filteredData != '' ? (
														<>
															{filteredData.map((rowData: any) => (
																<tr key={rowData.staffDetailsId}>
																	<td>
																		{rowData.attendanceStatusId == 3 ?
																			<Checks
																				value={rowData.staffDetailsId}
																				checked={rowData.isSelect}
																				onChange={(e: any) =>
																					updateStaffDetailsIdValue(
																						e.target.checked,
																						rowData.staffDetailsId,
																						rowData.staffName,
																						'Absent'
																					)
																				}
																			/> : <Icon
																				size='lg'
																				color='success'
																				icon='CheckBox'
																			/>}
																	</td>
																	<td>{rowData.sno}</td>
																	<td>{rowData.staffName}</td>
																	<td>{rowData.userType}</td>
																	{rowData.attendanceStatusId == 3 ?
																		<>
																			{rowData.isSelect == true ? (
																				// <td scope='col'>
																				// 	<Button
																				// 		isLink
																				// 		color='danger'
																				// 		icon='Circle'
																				// 		className='text-nowrap'>
																				// 		Absent
																				// 	</Button>
																				// </td>
																				<Dropdown>
																					<DropdownToggle>
																						<Button
																							color={rowData.status == 'Absent' ? 'danger' : 'warning'}
																							isLight
																							icon={'Circle'}
																						>
																							{rowData.status}
																						</Button>
																					</DropdownToggle>
																					<DropdownMenu isAlignmentEnd>
																						<DropdownItem>
																							<Button
																								isLink
																								color='danger'
																								icon='Circle'
																								className='text-nowrap'
																								onClick={(e: any) =>
																									updateStaffDetailsIdValue(
																										rowData.isSelect,
																										rowData.staffDetailsId,
																										rowData.staffName,
																										'Absent'
																									)
																								}>
																								Absent
																							</Button>
																						</DropdownItem>
																						<DropdownItem>
																							<Button
																								isLink
																								color='warning'
																								icon='Circle'
																								className='text-nowrap'
																								onClick={(e: any) =>
																									updateStaffDetailsIdValue(
																										rowData.isSelect,
																										rowData.staffDetailsId,
																										rowData.staffName,
																										'OD'
																									)
																								}>
																								OD
																							</Button>
																						</DropdownItem>
																					</DropdownMenu>
																				</Dropdown>
																			) : (
																				<td scope='col'>
																					<Button
																						isLink
																						color='success'
																						icon='Circle'
																						className='text-nowrap'>
																						Present
																					</Button>
																				</td>
																			)}
																		</> : <td scope='col'>
																			<Button
																				isLink
																				color='danger'
																				icon='Circle'
																				className='text-nowrap'>
																				Absent
																			</Button>
																		</td>}
																	{rowData.attendanceStatusId == 3 ?
																		<td scope='col'>
																			<FormGroup id='remarks'>
																				<Input
																					className='w-80'
																					placeholder=''
																					value={
																						rowData.remarks
																					}
																					disabled={
																						rowData.isEditable
																					}
																					onInput={(e: any) =>
																						updateRemarksValue(
																							e.target
																								.value,
																							rowData.staffDetailsId,
																						)
																					}
																				/>
																			</FormGroup>
																		</td> : <td scope='col'>
																			<FormGroup id='remarks'>
																				<Input
																					className='w-80'
																					placeholder=''
																					value={
																						rowData.remarks
																					}
																					disabled
																				/>
																			</FormGroup>
																		</td>}
																</tr>
															))}
														</>
													) : (
														<NoDataMsg
															columnsCount={6}
															msg={noDataMsg}
														/>
													)}
												</tbody>
											</table>
										</CardBody>
									</Card>
								</>
							) : (
								<>
									{staffDetailsReportData != '' ?
										<Card stretch data-tour='list' ref={componentRef}>
											<CardHeader borderSize={1}>
												<CardLabel
													icon='List'
													iconColor='info'
													className='col-lg-6'>
													<CardTitle tag='div' className='h5'>
														Staff Attendance List
													</CardTitle>
												</CardLabel>
												&nbsp;&nbsp;
												<ButtonGroup
													className='col-lg-3 d-print-none'
													color='primary'>
													<Icon
														className='mt-1'
														icon='Search'
														size='2x'
														color='primary'
													/>
													<Input
														id='searchInput'
														type='search'
														placeholder='Search...'
														onChange={columnVisibilityForm.handleChange}
														value={columnVisibilityForm.values.searchInput}
													/>
												</ButtonGroup>
												<CardActions className='d-print-none'>
													<Dropdown
														isOpen={columnVisibilityMenu}
														setIsOpen={setColumnVisibilityMenu}
														isButtonGroup>
														<DropdownToggle>
															<Button
																icon='FilterAlt'
																color='primary'
																isLight>
																Filter
															</Button>
														</DropdownToggle>
														<DropdownMenu
															isAlignmentEnd
															size='lg'
															isCloseAfterLeave={false}>
															<DropdownItem>
																<div className='container py-2'>
																	<form className='row g-3'>
																		<div className='col-12'>
																			<FormGroup>
																				<h6>Select All</h6>
																				<Checks
																					id='available'
																					type='switch'
																					label='Select All Columns'
																					onChange={() =>
																						getColumnsForTable(
																							'getStaffAttendanceReportList',
																							'post',
																						)
																					}
																					checked={isChecked}
																					ariaLabel='Available status'
																				/>
																			</FormGroup>
																		</div>
																		<div
																			className='col-12'
																			style={{
																				maxHeight: '200px',
																				overflowY: 'scroll',
																			}}>
																			<FormGroup>
																				<h6>Columns</h6>
																				<ChecksGroup>
																					{allColumnsData.map(
																						(i: any) => (
																							<Checks
																								key={
																									i.columnVisibilityId
																								}
																								label={
																									i.columnName
																								}
																								onChange={() =>
																									updateColumnsSubmit(
																										i.columnVisibilityId,
																										i.isDisplay,
																										'',
																									)
																								}
																								checked={
																									i.isDisplay
																								}
																								disabled={
																									i.isDefault ==
																									true
																								}
																							/>
																						),
																					)}
																				</ChecksGroup>
																			</FormGroup>
																		</div>
																	</form>
																</div>
															</DropdownItem>
														</DropdownMenu>
													</Dropdown>
													<Dropdown isButtonGroup>
														<DropdownToggle>
															<Button
																color='primary'
																isLight
																icon='CloudDownload'>
																{' '}
																Export{' '}
															</Button>
														</DropdownToggle>
														<DropdownMenu isAlignmentEnd>
															<DropdownItem>
																<Button
																	color='primary'
																	isLight
																	icon='CloudDownload'
																	onClick={() =>
																		downloadExcel(
																			'StaffAttendanceList',
																			staffDetailsReportData,
																			columnVisibilityData,
																		)
																	}>
																	{' '}
																	Excel{' '}
																</Button>
															</DropdownItem>
															<DropdownItem>
																<Button
																	color='primary'
																	isLight
																	icon='PictureAsPdf'
																	onClick={() =>
																		convertJsonToPdf(
																			staffDetailsReportData,
																			columnVisibilityData,
																			'StaffAttendanceList',
																		)
																	}>
																	{' '}
																	PDF
																</Button>
															</DropdownItem>
															<DropdownItem>
																<Button
																	color='primary'
																	isLight
																	icon='CloudDownload'
																	onClick={() =>
																		downloadFile(
																			'StaffAttendanceList',
																			staffDetailsReportData,
																			columnVisibilityData,
																		)
																	}>
																	{' '}
																	CSV{' '}
																</Button>
															</DropdownItem>
															<DropdownItem>
																<Button
																	color='primary'
																	isLight
																	icon='Print'
																	onClick={handlePrint}>
																	{' '}
																	Print{' '}
																</Button>
															</DropdownItem>
														</DropdownMenu>
													</Dropdown>
												</CardActions>
											</CardHeader>

											<CardBody className='table-responsive' isScrollable>

												<div className='row ms-5 g-4 align-items-center'>
													<div className='col-xl-1'></div>
													<div className='col-xl-3'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-primary':
																		!darkModeStatus,
																	'bg-lo25-primary':
																		darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='PeopleOutline'
																	size='3x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-3 mb-0'>
																	{
																		staffAttendanceSummaryData.totalStaff
																	}
																</div>
																<div className='text-muted mt-n2'>
																	Total Staff
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-3'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-success':
																		!darkModeStatus,
																	'bg-lo25-success':
																		darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='PeopleOutline'
																	size='3x'
																	color='success'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-3 mb-0'>
																	{
																		staffAttendanceSummaryData.totalPresent
																	}
																</div>
																<div className='text-muted mt-n2'>
																	Total Present
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-3'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-danger':
																		!darkModeStatus,
																	'bg-lo25-danger':
																		darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='PeopleOutline'
																	size='3x'
																	color='danger'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-3 mb-0'>
																	{
																		staffAttendanceSummaryData.totalAbsent
																	}
																</div>
																<div className='text-muted mt-n2'>
																	Total Absent
																</div>
															</div>
														</div>
													</div>
												</div>

												<table className='table table-modern table-hover text-nowrap mt-3'>
													<thead>
														<tr>
															<th
																scope='col'
																onClick={() => requestSort('sno')}
																className='cursor-pointer text-decoration-underline'>
																S.No{''}
																<Icon
																	size='lg'
																	className={getClassNamesFor('sno')}
																	icon='FilterList'
																/>
															</th>
															{columnVisibilityData.map((column: any) => (
																<th
																	key={column.keyName}
																	scope='col'
																	onClick={() =>
																		requestSort(column.keyName)
																	}
																	className='cursor-pointer text-decoration-underline'>
																	{column.columnName}
																	<Icon
																		size='lg'
																		className={getClassNamesFor(
																			column.keyName,
																		)}
																		icon='FilterList'
																	/>
																</th>
															))}
														</tr>
													</thead>
													<tbody>
														{filteredData1 != '' ? (
															<>
																{filteredData1.map((rowData: any) => (
																	<tr key={rowData.staffDetailsId}>
																		<td>{rowData.sno}</td>
																		{columnVisibilityData.map(
																			(column: any) =>
																				column.isDisplay &&
																					rowData[
																					column.keyName
																					] ? (
																					<td
																						className='text-nowrap'
																						key={`${rowData.staffDetailsId}-${column.keyName}`}>
																						{
																							rowData[
																							column
																								.keyName
																							]
																						}
																					</td>
																				) : (
																					<td
																						key={`empty-${column.keyName}`}
																					/>
																				),
																		)}
																	</tr>
																))}
															</>
														) : (
															<NoDataMsg
																columnsCount={
																	columnVisibilityData.length + 1
																}
																msg={noDataMsg}
															/>
														)}
													</tbody>
												</table>
											</CardBody>

											<PaginationButtons
												className='d-print-none'
												data={items}
												label='items'
												setCurrentPage={setCurrentPage}
												currentPage={currentPage}
												perPage={perPage}
												setPerPage={setPerPage}
											/>
										</Card> : null
									}
								</>
							)}
						</>
					) : null}


					{isReport == 'allPresent' ?
						<Card stretch data-tour='list' ref={componentRef}>
							<CardHeader borderSize={1}>
								<CardLabel
									icon='List'
									iconColor='info'
									className='col-lg-6'>
									<CardTitle tag='div' className='h5'>
										Staff Attendance List
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody className='table-responsive' isScrollable>
								<div className='row ms-5 g-4 align-items-center'>
									<div className='col-xl-1'></div>
									<div className='col-xl-3'>
										<div
											className={classNames(
												'd-flex align-items-center rounded-2 p-3',
												{
													'bg-l10-primary':
														!darkModeStatus,
													'bg-lo25-primary':
														darkModeStatus,
												},
											)}>
											<div className='flex-shrink-0'>
												<Icon
													icon='PeopleOutline'
													size='3x'
													color='primary'
												/>
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-3 mb-0'>
													{
														staffAttendanceSummaryData.totalStaff
													}
												</div>
												<div className='text-muted mt-n2'>
													Total Staff
												</div>
											</div>
										</div>
									</div>
									<div className='col-xl-3'>
										<div
											className={classNames(
												'd-flex align-items-center rounded-2 p-3',
												{
													'bg-l10-success':
														!darkModeStatus,
													'bg-lo25-success':
														darkModeStatus,
												},
											)}>
											<div className='flex-shrink-0'>
												<Icon
													icon='PeopleOutline'
													size='3x'
													color='success'
												/>
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-3 mb-0'>
													{
														staffAttendanceSummaryData.totalPresent
													}
												</div>
												<div className='text-muted mt-n2'>
													Total Present
												</div>
											</div>
										</div>
									</div>
									<div className='col-xl-3'>
										<div
											className={classNames(
												'd-flex align-items-center rounded-2 p-3',
												{
													'bg-l10-danger':
														!darkModeStatus,
													'bg-lo25-danger':
														darkModeStatus,
												},
											)}>
											<div className='flex-shrink-0'>
												<Icon
													icon='PeopleOutline'
													size='3x'
													color='danger'
												/>
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-3 mb-0'>
													{
														staffAttendanceSummaryData.totalAbsent
													}
												</div>
												<div className='text-muted mt-n2'>
													Total Absent
												</div>
											</div>
										</div>
									</div>

									<div className='col-1 mt-5'></div>
									<div className="col-11 mt-5 d-flex justify-content-center">
										<div
											className={classNames('d-flex align-items-center rounded-2 p-3')}>
											<div className='flex-shrink-0'>
												<Icon
													icon='Check'
													size='3x'
													color='success'
												/>
											</div>
											<div className='flex-grow-1 ms-3'>
												<b className='fs-4'>FULL PRESENT</b>
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card> : null
					}
				</Page>

				<Modal
					setIsOpen={setConfirmationOpen}
					isOpen={confirmationOpen}
					titleId='absentsStudent'
					isStaticBackdrop
					size='sm'
					isScrollable>
					<ModalHeader setIsOpen={setConfirmationOpen}>
						<ModalTitle id='absentsStudent'>Absent Staff</ModalTitle>
					</ModalHeader>
					<ModalBody>
						{attendanceConfirmationArray.map((attendanceConfirmationArray: any) => (
							<div
								className='col d-flex align-items-center'
								key={attendanceConfirmationArray.staffDetailsId}>
								<div className='flex-shrink-0 mt-2'>
									<div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
										<div
											className={classNames(
												'rounded-2',
												'd-flex align-items-center justify-content-center',
												{
													'bg-l10-dark': !darkModeStatus,
													'bg-l90-dark': darkModeStatus,
												},
											)}>
											<span className='fw-bold'>
												{getFirstLetter(
													attendanceConfirmationArray.staffName,
												)}
											</span>
										</div>
									</div>
								</div>
								<div className='flex-grow-1'>
									<div className='fs-6'>
										{attendanceConfirmationArray.staffName}
									</div>
									{/* <div className='text-muted'>
                                        {attendanceConfirmationArray.uniRegNo != null ? <small>{attendanceConfirmationArray.admissionNo} / {attendanceConfirmationArray.uniRegNo}</small> : <small>{attendanceConfirmationArray.admissionNo}</small>}
                                    </div> */}
								</div>
							</div>
						))}
					</ModalBody>
					<ModalFooter>
						<Button color='info' isOutline className='border-0' onClick={closeAndReset}>
							Close
						</Button>
						<Button
							color='info'
							icon='Save'
							type='submit'
							onClick={studentAttendanceSubmit}>
							Submit
						</Button>
					</ModalFooter>
				</Modal>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	);
};
export default StaffAttendanceList;
