import { useFormik } from "formik";
import { useEffect, useContext, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import NoDataMsg from "../../../common/components/NoDataMsg";
import AuthContext from "../../../contexts/authContext";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getLicenseKey } from "../../../services/application.settings";
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable, getStandardDetails } from "../../../services/common.service";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../services/ExportService";
import { TableLoader } from "../../../services/loader.services";
import { getAreaWiseStudentCount, getStudentListByTransportAreaMasterId } from "../../../services/report.service";
import { toasts } from "../../../services/toast.service";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Modal, { ModalHeader, ModalTitle, ModalBody } from "../../bootstrap/Modal";
import Icon from "../../icon/Icon";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Input from "../../bootstrap/forms/Input";

function AreaWiseStudentReport() {

  useEffect(() => {
    getStandardList()
  }, [])

  const { userAccountId, userTypeId } = useContext(AuthContext);
  const { themeStatus, darkModeStatus } = useDarkMode();
  const [report, setReport] = useState<any>([])
  const [areaWiseStudentReportData, setAreaWiseStudentReportData] = useState([])
  const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
  const [allColumnsData, setAllColumnsData] = useState([]);
  const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
  const [columnDataById, setColumnDataById] = useState([])
  const componentRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false)
  const [areaWiseStudentReportModal, setAreaWiseStudentReportModal] = useState(false)
  const [studentDetailsData, setStudentDetailsData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { items, requestSort, getClassNamesFor } = useSortableData(areaWiseStudentReportData);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const [areaName, setAreaName] = useState('')
  const [dataStatus, setDataStatus] = useState(false)

  const [noDataMsg, setNoDataMsg] = useState('')

  const columnVisibilityForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchInput: '',
      available: false,
    },
    validate: (values) => { },

    //validateOnChange: false,
    onSubmit: () => { },
  });

  const [totalStudentPerColumn, setTotalStudentPerColumn] = useState<any>([])
  const [total, setTotal] = useState<any>('')

  function getAreaWiseStudentReport(standardData: any) {
    setDataStatus(false)
    getAreaWiseStudentCount(
      (response) => {
        if (response.data.success) {
          let data = response.data.data.areaWiseStudentCount;
          if (data != undefined) {
            setDataStatus(true)

            let studentDetailsArray = data.map((area: any) => {
              const filledDetails = standardData.map((std: any) => {
                const existingDetail = area.studentDetails.find(
                  (detail: any) => detail.standardDetailsId === std.standardDetailsId
                );
                return existingDetail || { ...std, studentCount: 0 };
              });

              return { ...area, studentDetails: filledDetails };
            });

            const columnTotals: any = [];
            studentDetailsArray.forEach((area: any) => {
              area.studentDetails.forEach((student: any) => {
                if (!columnTotals[student.standardDetailsId]) {
                  columnTotals[student.standardDetailsId] = 0;
                }
                columnTotals[student.standardDetailsId] += student.studentCount;
              });
            });

            for (let i = 0; i < studentDetailsArray.length; i++) {

              studentDetailsArray[i].sno = i + 1

              let studentDetails = studentDetailsArray[i].studentDetails

              for (let j = 0; j < studentDetails.length; j++) {

                studentDetails[j].transportAreaMasterId = studentDetailsArray[i].transportAreaMasterId;
                studentDetails[j].areaName = studentDetailsArray[i].areaName;
                studentDetailsArray[i][studentDetails[j].standardName] = studentDetails[j].studentCount;
              }
            }

            const total: any = Object.values(columnTotals).reduce((a: any, b: any) => a + b, 0);
            setTotal(total)

            let col = columnTotals.filter((item: any, index: any) => index > 0)
            setTotalStudentPerColumn(col);

            setAreaWiseStudentReportData(studentDetailsArray)
            setReport(studentDetailsArray)

            let data1: any = []

            standardData.forEach((dateOfAdmissionData: any, index: any) => {
              data1.push({ columnVisibilityId: index + 1, columnName: dateOfAdmissionData.standardName, keyName: dateOfAdmissionData.standardName, isActive: true, isDisplay: true, isDefault: true });
            });

            let finalData = data1.filter((item: any) => item.columnName != null)

            const fin = [{ columnVisibilityId: 1, columnName: 'Area Name', keyName: 'areaName', isActive: true, isDisplay: true, isDefault: true }]

            const last = [{ columnVisibilityId: 13, columnName: 'Total', keyName: 'totalStudentCount', isActive: true, isDisplay: true, isDefault: true }]

            setColumnVisibilityData([...fin, ...finalData, ...last])


          } else {
            setDataStatus(true)
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          setDataStatus(true)
          //toasts(response.data.message, "Error")         
          setAreaWiseStudentReportData([]);
          setNoDataMsg(response.data.message)
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          setDataStatus(true)
          toasts(errorDescription, "Error")
        }
      }, error => {
        setDataStatus(true)
        toasts(error, "Error")
      }
    )
  }

  const [standardDetailsData, setStandardDetailsData] = useState<any>([])

  function getStandardList() {
    setDataStatus(false)
    getStandardDetails(
      (response) => {
        if (response.data.success) {
          let data = response.data.data.standardDetails;
          if (data != undefined) {
            setDataStatus(true)
            setStandardDetailsData(data)
            getAreaWiseStudentReport(data)
          } else {
            setDataStatus(true)
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          setDataStatus(true)
          //toasts(response.data.message, "Error")
          setAreaWiseStudentReportData([]);
          setNoDataMsg(response.data.message)
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          setDataStatus(true)
          toasts(errorDescription, "Error")
        }
      }, error => {
        setDataStatus(true)
        toasts(error, "Error")
      }
    )
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <PageWrapper title="Area Wise Student Report">
      <Page container='fluid'>
        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
          <CardHeader borderSize={1}>
            <CardLabel icon='List' iconColor='info' className="col-lg-7">
              <CardTitle tag='div' className='h5'>Area Wise Student Count</CardTitle>
            </CardLabel>&nbsp;&nbsp;

            <ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
              <Icon className="mt-1" icon='Search' size='2x' color='primary' />
              <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
            </ButtonGroup>

            <CardActions className="d-print-none">
              <Dropdown isButtonGroup>
                <DropdownToggle>
                  <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd>
                  <DropdownItem>
                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Area Wise Student Report", areaWiseStudentReportData, columnVisibilityData)}> Excel </Button>
                  </DropdownItem>
                  <DropdownItem>
                    <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(areaWiseStudentReportData, columnVisibilityData, "Area Wise Student Report")}> PDF
                    </Button>
                  </DropdownItem>
                  <DropdownItem>
                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Area Wise Student Report", areaWiseStudentReportData, columnVisibilityData)}> CSV </Button>
                  </DropdownItem>
                  <DropdownItem>
                    <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

            </CardActions>
          </CardHeader>
          <CardBody className='table-responsive' isScrollable>
            {!dataStatus ?
              <TableLoader /> :
              <table className='table table-modern table-hover text-nowrap'>
                <thead>
                  <tr className='table-success text-decoration-underline mt-4'>
                    <th>S.No</th>
                    <th>Area Name</th>
                    {standardDetailsData.map((rowData2: any) => (
                      <th key={rowData2.standardDetailsId}>{rowData2.standardName}</th>
                    ))}
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {report != "" ?
                    <>
                      {report.map((rowData: any, index: number) => (
                        <tr key={rowData.transportAreaMasterId}>
                          <td>{rowData.sno}</td>
                          <td>{rowData.areaName}</td>
                          {rowData.studentDetails.map((item: any) => (
                            <td>{item.studentCount}</td>
                          ))}
                          <td className={rowData.totalStudentCount > 0 ? 'text-success fw-bold' : 'text-danger fw-bold'}>{rowData.totalStudentCount}</td>
                        </tr>
                      ))}
                      <tr className='table-danger fw-bold'>
                        <td></td>
                        <td>Total</td>
                        {totalStudentPerColumn.map((item: any) => (
                          <td>{item}</td>
                        ))}
                        <td className="fw-bold text-primary">{total}</td>
                      </tr>
                    </> :
                    <NoDataMsg columnsCount={columnVisibilityData.length + 15} msg={noDataMsg} />}
                </tbody>
              </table>
            }
          </CardBody>
          <PaginationButtons
            className="d-print-none"
            data={items}
            label='items'
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </Card>
      </Page>

      <Modal
        titleId='areaWiseStudentReportModal'
        isOpen={areaWiseStudentReportModal}
        setIsOpen={setAreaWiseStudentReportModal}
        isStaticBackdrop={true}
        isCentered
        isScrollable
        size='lg' >
        <ModalHeader setIsOpen={setAreaWiseStudentReportModal} >
          <ModalTitle id="areaWiseStudentReportModal">Student List</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="row g-4">
            <div className='col-6 '>
              <div className='d-flex align-items-center'>
                <div className='flex-shrink-0'>
                  <Icon icon='ListAlt' size='3x' color='info' />
                </div>
                <div className='flex-grow-1 ms-3'>
                  <div className='fw-bold fs-5 mb-0'>
                    {areaName}
                  </div>
                  <div className='text-muted'>
                    Area Name
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 " >
              <table className='table table-modern table-hover '>
                <thead className='table-info'>
                  <tr>
                    <th>S.NO</th>
                    <th>ADMISSION NO</th>
                    <th>STUDENT</th>
                    <th>STANDARD</th>
                    <th>SECTION</th>
                    <th>WAYS</th>
                  </tr>
                </thead>
                <tbody>
                  {studentDetailsData.map((i: any) => (
                    <tr key={i.studentTransportDetailsId}>
                      <td className="align-self-center">{i.sno}</td>
                      <td className="align-self-center">{i.admissionNo} </td>
                      <td className="align-self-center">{i.studentName} </td>
                      <td className="align-self-center">{i.standardName} </td>
                      <td className="align-self-center">{i.sectionName} </td>
                      <td className="align-self-center">{i.ways} </td>
                    </tr>))}
                </tbody>
              </table>
            </div>
          </div>

        </ModalBody>
      </Modal>
    </PageWrapper>
  )
}
export default AreaWiseStudentReport;