import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Page from '../../../../layout/Page/Page';
import classNames from 'classnames';
import Icon from '../../../../components/icon/Icon';
import dayjs from 'dayjs';
import useDarkMode from '../../../../hooks/useDarkMode';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { getDashboardDataByUserTypeId } from '../../../../services/dashboard.service';
import { TableLoader } from '../../../../services/loader.services';
import { priceFormat } from '../../../../helpers/helpers';

const TodayAbsentCount = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		if (userTypeId != '' && userTypeId != '') {
			getDashboardDataByUserTypeIdList(userTypeId, userAccountId);
		}
	}, []);


	const { themeStatus, darkModeStatus } = useDarkMode();
	const [dataStatus, setDataStatus] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [dashboardDataByUserTypeData, setDashboardDataByUserTypeData] = useState<any>([])


	function getDashboardDataByUserTypeIdList(userTypeId: any, userAccountId: any) {
		setDataStatus(false);
		getDashboardDataByUserTypeId(userTypeId, userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.dashboardDataByUserTypeId[0];
					if (data != undefined) {
						setDataStatus(true);
						setDashboardDataByUserTypeData(data);
					}
					else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}
	return (
		<>
			{userTypeId != 12 ? <>
				{!dataStatus ?
					<TableLoader /> :
					<div className='row g-4 align-items-center'>
						<div className='col-xl-6'>
							<Card stretch className='border border-light rounded-1'>
								<CardBody>
									<div className="row">
										<div className='col-9'>
											<CardLabel>
												<CardTitle tag='div' className='h5 text-info'>{dashboardDataByUserTypeData.getTodayStudentAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStudentAbsentCount : 0}</CardTitle>
											</CardLabel>
											<div className='fw-bold fs-6 mb-0 text-muted'>Absent Student <span className="fs-6 text-muted">({dashboardDataByUserTypeData.getTotalStudentCount != undefined ? dashboardDataByUserTypeData.getTotalStudentCount : 0})</span></div>
										</div>
										<div className='col-2'>
											<Icon icon='PersonOff' size='3x' color='info' />
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='col-xl-6'>
							<Card stretch className='border border-light rounded-1'>
								<CardBody>
									<div className="row">
										<div className='col-9'>
											<CardLabel>
												<CardTitle tag='div' className='h5' style={{ color: '#cc3300' }}>{dashboardDataByUserTypeData.getTodayStaffAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStaffAbsentCount : 0}</CardTitle>
											</CardLabel>
											<div className='fw-bold fs-6 mb-0 text-muted'>Absent Staff <span className="fs-6 text-muted">({dashboardDataByUserTypeData.getTotalStaffCount != undefined ? dashboardDataByUserTypeData.getTotalStaffCount : 0})</span></div>
										</div>

										<div className='col-2'>
											<Icon icon='PersonOff' size='3x' style={{ color: '#cc3300' }} />
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				} </> : null
			}

			{userTypeId == 12 ?

				<div className='row g-4 align-items-center'>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-info'>{dashboardDataByUserTypeData.getTotalStudentCount != undefined ? dashboardDataByUserTypeData.getTotalStudentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Total Student <span className="fs-6 text-muted"></span></div>
									</div>
									<div className='col-2'>
										<Icon icon='Group' size='3x' color='info' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-danger'>{dashboardDataByUserTypeData.getTodayStudentAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStudentAbsentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Absent Student <span className="fs-6 text-muted"></span></div>
									</div>
									<div className='col-2'>
										<Icon icon='PersonOff' size='3x' color='danger' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-info'>{dashboardDataByUserTypeData.getTotalStaffCount != undefined ? dashboardDataByUserTypeData.getTotalStaffCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Total Staff <span className="fs-6 text-muted"></span></div>
									</div>
									<div className='col-2'>
										<Icon icon='Group' size='3x' color='info' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-3'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-danger'>{dashboardDataByUserTypeData.getTodayStaffAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStaffAbsentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Absent Staff <span className="fs-6 text-muted"></span></div>
									</div>

									<div className='col-2'>
										<Icon icon='PersonOff' size='3x' color='danger' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>

				// <Card stretch className='border border-light rounded-1'>
				// 	<CardHeader>
				// 		<CardLabel icon='Checklist' iconColor='secondary'>
				// 			<CardTitle tag='div' className='h5'>
				// 				Absent Count
				// 			</CardTitle>
				// 		</CardLabel>
				// 	</CardHeader>
				// 	<CardBody>
				// 		{!dataStatus ?
				// 			<TableLoader /> :
				// 			<div className='row g-4 align-items-center'>
				// 				<div className='col-xl-6'>
				// 					<div
				// 						className={classNames(
				// 							'd-flex align-items-center rounded-2 p-3',
				// 							{
				// 								'bg-l10-success': !darkModeStatus,
				// 								'bg-lo25-success': darkModeStatus,
				// 							},
				// 						)}>
				// 						<div className='flex-shrink-0'>
				// 							<Icon icon='Group' size='3x' color='success' />
				// 						</div>
				// 						<div className='flex-grow-1 ms-3'>
				// 							<div className='fw-bold fs-5 mb-0'>{dashboardDataByUserTypeData.getTotalStudentCount != undefined ? dashboardDataByUserTypeData.getTotalStudentCount : 0}</div>
				// 							<div className='text-muted mt-n1 truncate-line-1'>
				// 								Total Student
				// 							</div>
				// 						</div>
				// 					</div>
				// 				</div>
				// 				<div className='col-xl-6'>
				// 					<div
				// 						className={classNames(
				// 							'd-flex align-items-center rounded-2 p-3',
				// 							{
				// 								'bg-l10-danger': !darkModeStatus,
				// 								'bg-lo25-danger': darkModeStatus,
				// 							},
				// 						)}>
				// 						<div className='flex-shrink-0'>
				// 							<Icon icon='PersonOff' size='3x' color='danger' />
				// 						</div>
				// 						<div className='flex-grow-1 ms-3'>
				// 							<div className='fw-bold fs-5 mb-0'>{dashboardDataByUserTypeData.getTodayStudentAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStudentAbsentCount : 0}</div>
				// 							<div className='text-muted mt-n1 truncate-line-1'>
				// 								Absent Student
				// 							</div>
				// 						</div>
				// 					</div>
				// 				</div>
				// 				<div className='col-xl-6'>
				// 					<div
				// 						className={classNames(
				// 							'd-flex align-items-center rounded-2 p-3',
				// 							{
				// 								'bg-l10-primary': !darkModeStatus,
				// 								'bg-lo25-primary': darkModeStatus,
				// 							},
				// 						)}>
				// 						<div className='flex-shrink-0'>
				// 							<Icon
				// 								icon='Group'
				// 								size='3x'
				// 								color='primary'
				// 							/>
				// 						</div>
				// 						<div className='flex-grow-1 ms-3'>
				// 							<div className='fw-bold fs-5 mb-0'> {dashboardDataByUserTypeData.getTotalStaffCount != undefined ? dashboardDataByUserTypeData.getTotalStaffCount : 0}</div>
				// 							<div className='text-muted mt-n1 truncate-line-1'>
				// 								Total Staff
				// 							</div>
				// 						</div>
				// 					</div>
				// 				</div>
				// 				<div className='col-xl-6'>
				// 					<div
				// 						className={classNames(
				// 							'd-flex align-items-center rounded-2 p-3',
				// 							{
				// 								'bg-l10-warning': !darkModeStatus,
				// 								'bg-lo25-warning': darkModeStatus,
				// 							},
				// 						)}>
				// 						<div className='flex-shrink-0'>
				// 							<Icon icon='PersonOff' size='3x' color='info' />
				// 						</div>
				// 						<div className='flex-grow-1 ms-3'>
				// 							<div className='fw-bold fs-5 mb-0'>{dashboardDataByUserTypeData.getTodayStaffAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStaffAbsentCount : 0}</div>
				// 							<div className='text-muted mt-n1'>Absent Staff</div>
				// 						</div>
				// 					</div>
				// 				</div>
				// 			</div>
				// 		}
				// 	</CardBody>
				// </Card> 

				: null
			}
		</>
	);
};

export default TodayAbsentCount;
