import React, { useContext, useEffect, useRef, useState } from 'react';
import { toasts } from '../../../../../services/toast.service';
import { useReactToPrint } from 'react-to-print';
import OpenCardComponent from '../../../../../common/components/OpenCardComponent';
import Page from '../../../../../layout/Page/Page';
import PageWrapper from '../../../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../../../services/AlertService';
import Card, { CardHeader, CardActions, CardBody } from '../../../../bootstrap/Card';
import Collapse from '../../../../bootstrap/Collapse';
import FormGroup from '../../../../bootstrap/forms/FormGroup';
import Icon from '../../../../icon/Icon';
import { getAllStudent, getProfileForStudent, getStudentDetailsBySectionDetailsId } from '../../../../../services/student.service';
import { getBatchMasterDetails, getSectionDetails } from '../../../../../services/master.service';
import { getMedium, getStandardDetails } from '../../../../../services/common.service';
import Button from '../../../../bootstrap/Button';
import CollegeLogo from '../../../../../assets/logos/logo.jpg';
import SearchableSelect from '../../../../../common/components/SearchableSelect';
import dayjs from 'dayjs';
import { getSchoolDetails } from '../../../../../services/school.service';
import AuthContext from '../../../../../contexts/authContext';

const ConductCertificate = () => {

	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getAllStudentDetails();
		getSchool();
	}, []);

	const [mediumId, setMediumId] = useState<any>('');
	const [standardDetailsId, setStandardDetailsId] = useState<any>('');
	const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);
	const [mediumData, setMediumData] = useState<any>([]);
	const [standardData, setStandardData] = useState<any>([]);
	const [studentDetailsId, setStudentDetailsId] = useState<any>(Number);
	const componentRef = useRef(null);
	const [sectionDetailsData, setSectionDetailsData] = useState([]);
	const [studentDetailsData, setStudentDetailsData] = useState<any>([]);
	const [allStudentData, setAllStudentData] = useState([]);
	const [schoolDetailsData, setSchoolDetailsData] = useState<any>([]);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [isLoader, setIsLoader] = useState(false);

	const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true);

	const [batchMasterId, setBatchMasterId] = useState<any>('')
	const [batchMasterData, setBatchMasterData] = useState<any>([])

	const selectBatch = (e: any) => {
		setDataSuccess(false)
		setStandardDetailsId('')
		setSectionDetailsId('')
		setMediumId('')
		let batchMasterId = e
		setBatchMasterId(batchMasterId)
		getMediumList();
	}

	const selectMedium = (e: any) => {
		setDataSuccess(false)
		setStandardDetailsId('');
		setSectionDetailsId('');
		let mediumId = e;
		setMediumId(mediumId);
		getStandardList();
	};

	const selectStandard = (e: any) => {
		setDataSuccess(false)
		setSectionDetailsId('');
		let standardDetailsId = e;
		setStandardDetailsId(standardDetailsId);
		if (mediumId?.value != undefined && standardDetailsId?.value != undefined) {
			getSectionList(mediumId?.value, standardDetailsId?.value);
		}
	};

	const selectSection = (e: any) => {
		setDataSuccess(false)
		setStudentDetailsId('');
		let sectionDetailsId = e;
		setSectionDetailsId(sectionDetailsId);
	};

	const selectStudent = (e: any) => {
		setDataSuccess(false)
		let studentDetailsId = e
		setStudentDetailsId(studentDetailsId)
	}

	function viewParticularStudent() {
		setDataSuccess(false)
		setIsLoader(true);
		getStudentProfile(studentDetailsId?.value);
	}

	function viewStudentClassWiseList() {
		setDataSuccess(false);
		setIsLoader(true);
		if (batchMasterId?.value != undefined && sectionDetailsId?.value != undefined) {
			getStudentListBySectionId(batchMasterId?.value, sectionDetailsId?.value);
		}
	}

	function getStudentListBySectionId(batchMasterId: any, sectionDetailsId: any) {
		getStudentDetailsBySectionDetailsId(batchMasterId, sectionDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetailsBySectionDetailsId;
					if (data != undefined) {
						setIsLoader(false);
						setStudentDetailsData(data);
						setDataSuccess(true);
						setIsOpenListCard(false);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					toasts(response.data.message, 'Error');
					setDataSuccess(false);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getStudentProfile(studentDetailsId: any) {
		setIsLoader(true);
		getProfileForStudent(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentProfile;
					if (data != undefined) {
						setIsLoader(false);
						setStudentDetailsData(data);
						setDataSuccess(true);
						setIsOpenListCard(false);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					toasts(response.data.message, 'Error');
					setDataSuccess(false);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getBatchList() {
		getBatchMasterDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.batchMasterDetails;
					if (data != undefined) {
						setBatchMasterData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setBatchMasterData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getMediumList() {
		getMedium(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.medium;
					if (data != undefined) {
						setMediumData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setMediumData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStandardList() {
		getStandardDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.standardDetails;
					if (data != undefined) {
						setStandardData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStandardData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSectionList(mediumId: any, standardDetailsId: any) {
		getSectionDetails(mediumId, standardDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.sectionDetails;
					if (data != undefined) {
						setSectionDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, 'Error');
					setSectionDetailsData([])
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getAllStudentDetails() {
		getAllStudent(userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentDetails;
					if (data != undefined) {
						setAllStudentData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, 'Error');
					setAllStudentData([])
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getSchool() {
		getSchoolDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.schoolDetails;
					if (data != undefined) {
						setSchoolDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}
	// Print
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function selectStudentCard() {
		if (isStudentOrClassWise) {
            getBatchList()           
        }
		setIsStudentOrClassWise(!isStudentOrClassWise);
		setDataSuccess(false);
		setMediumId('')
		setStandardDetailsId('')
		setSectionDetailsId('');
		setStudentDetailsId('');
		setBatchMasterId('')
	}

	return (
		<PageWrapper title='Student List'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card>
						<CardHeader borderSize={1}>
							<CardActions>
								<Button
									icon='PersonSearch'
									color='info'
									isLight={isStudentOrClassWise ? false : true}
									onClick={selectStudentCard}>
									Student
								</Button>

								<Button
									icon='PeopleAlt'
									color='info'
									isLight={isStudentOrClassWise ? true : false}
									onClick={selectStudentCard}>
									Class Wise
								</Button>
							</CardActions>
						</CardHeader>
						{isStudentOrClassWise ? (
							<CardBody>
								<div className='row'>
									<div className='col-4 mt-3'>
										<FormGroup id='studentDetailsId' label='Student' isFloating>
											<SearchableSelect isFloating
												ariaLabel='Student'
												onChange={selectStudent}
												value={studentDetailsId}
												list={allStudentData.map((data: any) => ({
													value: data.studentDetailsId,
													label: data.studentNameAndAdmissionNo,
												}))}
											/>
										</FormGroup>
									</div>
									<div className='col-3 mt-4'>
										<Button
											icon='ArrowDownward'
											color='primary'
											onClick={viewParticularStudent} isDisable={studentDetailsId?.value == undefined ? true : false}>
											View
										</Button>
									</div>
								</div>
							</CardBody>
						) : (
							<CardBody>
								<div className='row'>
									<div className='col-md-2'>
										<FormGroup id='batchMasterId' label='Batch' isFloating>
											<SearchableSelect isFloating ariaLabel='Batch' placeholder="Batch"
												onChange={selectBatch}
												value={batchMasterId}
												list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} required />
										</FormGroup>
									</div>

									{batchMasterId?.value != undefined ?
										<div className='col-2'>
											<FormGroup id='mediumId' label='Medium' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Medium'
													onChange={selectMedium}
													value={mediumId}
													list={mediumData.map((data: any) => ({
														value: data.mediumId,
														label: data.medium,
													}))}
												/>
											</FormGroup>
										</div> : null
									}

									{mediumId?.value != undefined ? (
										<div className='col-2'>
											<FormGroup
												id='standardDetailsId'
												label='Standard'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Standard'
													onChange={selectStandard}
													value={standardDetailsId}
													list={standardData.map((data: any) => ({
														value: data.standardDetailsId,
														label: data.standardName,
													}))}
												/>
											</FormGroup>
										</div>
									) : null}

									{standardDetailsId?.value != undefined ? (
										<div className='col-2'>
											<FormGroup id='sectionDetailsId' label='Section' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Section'
													onChange={selectSection}
													value={sectionDetailsId}
													list={sectionDetailsData.map((data: any) => ({
														value: data.sectionDetailsId,
														label: data.sectionName,
													}))}
												/>
											</FormGroup>
										</div>
									) : null}

									{sectionDetailsId?.value != undefined ? (
										<div className='col-3 mt-2'>
											<Button
												icon='ArrowDownward'
												color='primary'
												onClick={viewStudentClassWiseList} >
												View
											</Button>
										</div>
									) : null}
								</div>
							</CardBody>
						)}
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess ? <>
					<div className='d-flex col-7 justify-content-end' style={{ marginLeft: "420px" }}>
						<Button icon="Print" isLight color="primary" onClick={handlePrint}>Print</Button>
					</div>
					<CardBody ref={componentRef} >
						{studentDetailsData.map((studentData: any) => (
							<div key={studentData.studentDetailsId}>
								<table cellPadding={10} cellSpacing={10} width='100%' style={{ fontFamily: "Times New Roman" }} className='border border-dark'>
									<tbody style={{ borderBottom: 'solid 1px black' }}>
										{schoolDetailsData.map((school: any) => (
											<tr key={school.schoolDetailsId}>
												<td align="center" width="20%">
													<img
														height='100px'
														width='100px'
														src={CollegeLogo}
													/>
												</td>
												<th className='text-center' align="center" style={{
													fontFamily: 'Bookman Old Style',
													// color: 'rgb(61, 57, 57)', 
													width: "60%"
												}}>
													<h1 className="text-uppercase">
														<b style={{ fontSize: '90%' }}> {school.schoolName}</b>
													</h1>

													<p className="fs-6">({school.description})<br />{school.address}<br />
														<Icon icon="Email" /> {school.website} <Icon icon="Phone" /> {school.phone} <Icon icon="PhoneAndroid" /> {school.cellNo}</p>
												</th>
												<td width="20%"></td>
											</tr>
										))}
									</tbody>
									<tbody>
										<tr>
											<th style={{ width: '20%' }}></th>
											<th style={{ width: '60%' }} className="text-center" >
												<h2 className="mt-5" style={{
													fontFamily: 'Bookman Old Style',
													// color: 'rgb(61, 57, 57)', 
												}}>
													<u>CONDUCT CERTIFICATE</u>
												</h2>
											</th>
											<th style={{ width: '20%' }}>
											</th>
										</tr>
										<tr>
											<td width='100%' colSpan={4} className="fs-4"
												style={{
													fontFamily: 'Bookman Old Style',
													// color: 'rgb(61, 57, 57)', 
												}}>
												<br />
												<label style={{ marginLeft: "30px" }}>This is to certify that Selvi / Selvan{' '}</label>
												<label style={{ width: "350px", borderBottom: "solid rgb(61, 57, 57) 1px" }} className="fw-bold text-center">
													<b contentEditable="true" suppressContentEditableWarning={true} >
														{studentData.studentName}
													</b>
												</label>
												<label style={{ marginLeft: "30px" }}>has studied{' '}</label>
												<br />
												<br />
												<br />
												<label style={{ width: "300px", borderBottom: "solid rgb(61, 57, 57) 1px", marginLeft: "30px" }} className="fw-bold text-center">
													<b contentEditable="true" suppressContentEditableWarning={true} >
														{studentData.courseName}
													</b>
												</label>
												<label style={{ marginLeft: "30px" }}>in this school during the academic year</label>
												<label style={{ width: "200px", borderBottom: "solid rgb(61, 57, 57) 1px" }} className="fw-bold text-center">
													<b contentEditable="true" suppressContentEditableWarning={true} >
														{studentData.academicPeriod}
													</b>
												</label>
												<br />
												<br />
												<br />
												<label style={{ marginLeft: "30px" }}>During this period her/his conduct and character was{' '}</label>
												<label style={{ width: "350px", borderBottom: "solid rgb(61, 57, 57) 1px" }} className="fw-bold text-center">
													<b contentEditable="true" suppressContentEditableWarning={true} >
													</b>
												</label>
												<br />
												<br />
												<br />
												{schoolDetailsData.map((school: any) => (
													<tr key={school.schoolDetailsId}>
														<th style={{ width: '30%' }}>
															<label>Place:{school.district}</label><br />
															<label className='mt-3'>Date :{dayjs(new Date()).format('DD/MM/YYYY')}
															</label>
														</th>
														<th style={{ width: '35%' }} className="text-center" >
														</th>
														<th style={{ width: '30%' }}>
															<label style={{ marginLeft: "90px" }}>PRINCIPAL</label>
															<label className='mt-3'>
																{school.schoolName}
															</label>
														</th>
													</tr>
												))}
											</td>
										</tr>
									</tbody>
								</table>
								<br />
								<br />
								<br />
							</div>
						))}
					</CardBody>
				</> : null}
				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</Page>
		</PageWrapper>
	);
};

export default ConductCertificate;
