import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import SubHeader, {
    SubHeaderLeft,
    SubHeaderRight,
    SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../components/bootstrap/Card';
import Avatar from '../../../components/Avatar';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import { toasts } from '../../../services/toast.service';
import UserImage2Webp from '../../../assets/img/wanna/wanna1.webp';
import UserImage2 from '../../../assets/img/wanna/wanna1.png';
import { getProfileForStaff } from '../../../services/staff.service';
import { useReactToPrint } from 'react-to-print';
import AuthContext from '../../../contexts/authContext';
import { pictNotLoading, profilePic } from '../../../services/common.service';
import { getStaffAttendanceDetails } from '../../../services/attendance.service';
import classNames from 'classnames';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas';
import StaffAttendanceStatus from '../../charts/StaffAttendanceStatus';


const StaffProfile = () => {

    const { darkModeStatus } = useDarkMode();
    const { staffDetailsId } = useParams();

    useEffect(() => {
        getStaffProfile(staffDetailsId)
        getStudentAttendance(staffDetailsId)
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [profileForStaffData, setProfileForStaffData] = useState<any>([])
    const [staffAttendanceData, setStaffAttendanceData] = useState<any>([])
    const [staffAbsentAttendanceData, setStaffAbsentAttendanceData] = useState<any>([])
    const [totalWorkingDays, setTotalWorkingDays] = useState<any>('')
    const [absentDetailsOffCanvas, setAbsentDetailsOffCanvas] = useState(false);


    function getStaffProfile(staffDetailsId: any) {
        getProfileForStaff(staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff;
                    if (data != undefined) {
                        setProfileForStaffData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentAttendance(staffDetailsId: any) {
        getStaffAttendanceDetails(staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffAttendanceDetails;

                    if (data != undefined) {
                        let attendanceDetails = data[0].attendanceDetails;
                        setStaffAttendanceData(data);
                        setStaffAbsentAttendanceData(attendanceDetails);
                        setTotalWorkingDays(data[0].totalWorkingDays);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    return (
        <>
            {profileForStaffData.map((staff: any) => (
                <PageWrapper title={`${staff.fullname}`} key={staff.staffUserAccountId}>
                    <SubHeader>
                        <SubHeaderLeft>
                            <Button
                                color='info'
                                isLink
                                icon='ArrowBack'
                                tag='a'
                                to={`../academic/staffList`}>
                                Back to List
                            </Button>
                            <SubheaderSeparator />
                        </SubHeaderLeft>
                        {userTypeId == 1 ?
                            <SubHeaderRight>
                                <Button
                                    color='primary'
                                    isLight
                                    icon='Edit'
                                    tag='a'
                                    to={`../academic/updateStaff/${staff.staffUserAccountId}`}>
                                    Edit
                                </Button>
                            </SubHeaderRight>
                            : <></>}
                    </SubHeader>
                    <Page container='fluid'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <Card stretch className='shadow-3d-info'>
                                    <CardBody>
                                        <div className='row g-3'>
                                            <div className='col-12 d-flex justify-content-center'>
                                                {staff.profilePath != null ?
                                                    <img style={{ borderRadius: '50%' }} src={staff.profilePath}
                                                        width="120" height="120" onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                                    : <img className="rounded-0" src={profilePic(staff.genderId)}
                                                        width="120" height="120" />}
                                            </div>
                                            <div className='col-12'>
                                                <div className='row g-2'>
                                                    <span className='fs-4 fw-bold me-2  d-flex justify-content-center text-center text-uppercase'>{`${staff.fullname}`}</span>
                                                    <div className='h5 text-muted text-lowercase opacity-50 d-flex justify-content-center text-uppercase'>
                                                        {staff.userType != '' && staff.userType != null ? staff.userType : staff.userType}
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='ViewList' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {staff.empNumber ? staff.empNumber : <Icon icon='Splitscreen' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Emp number
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='DateRange' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {staff.dateOfJoiningForView ? staff.dateOfJoiningForView : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Date Of Joining
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon={staff.genderId == 1 ? 'Male' : 'Female'} size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {staff.gender ? staff.gender : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                    </div>
                                                                <div className='text-muted'>
                                                                    Gender
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='PhoneIphone' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {staff.contactNumber ? staff.contactNumber : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                     </div>
                                                                <div className='text-muted'>
                                                                    Mobile Number
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='Mail' size='3x' color='info' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-5 mb-0'>
                                                                    {staff.email ? staff.email : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    Email Address
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col-lg-7'>
                                <Card stretch className='shadow-3d-primary'>
                                    <CardBody>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <Card
                                                    className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-danger bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-danger-hover transition-base rounded-2 mb-0`}
                                                    shadow='sm'>
                                                    <CardHeader className='bg-transparent'>
                                                        <CardLabel>
                                                            <CardTitle tag='div' className='h5'>
                                                                Class Info
                                                            </CardTitle>
                                                        </CardLabel>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className='col-12'>
                                                            <div className='row g-2'>
                                                                <div className='col-6'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='flex-shrink-0'>
                                                                            <Icon icon='Class' size='2x' color='primary' />
                                                                        </div>
                                                                        <div className='flex-grow-1 ms-3'>
                                                                            <div className='fw-bold mb-0 '>
                                                                                {staff.fromStandardName ? staff.fromStandardName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                            </div>
                                                                            <div className='text-muted'>
                                                                                From Standard
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='flex-shrink-0'>
                                                                            <Icon icon='Class' size='2x' color='primary' />
                                                                        </div>
                                                                        <div className='flex-grow-1 ms-3'>
                                                                            <div className='fw-bold mb-0'>
                                                                                {staff.toStandardName ? staff.toStandardName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                            </div>
                                                                            <div className='text-muted'>
                                                                                To Standard
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {staff.classInchargeStandardDetailsId > 0 ?
                                                                    <div className='col-6'>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='flex-shrink-0'>
                                                                                <Icon icon='BookmarkAdded' size='2x' color='primary' />
                                                                            </div>
                                                                            <div className='flex-grow-1 ms-3'>
                                                                                <div className='fw-bold mb-0'>
                                                                                    {staff.classInchargeStandardName ? staff.classIncharge : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                                         </div>
                                                                                <div className='text-muted'>
                                                                                    Class Incharge
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                <Card
                                                    className={`mt-4 bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-primary bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-primary-hover transition-base rounded-2 mb-4`}
                                                    shadow='sm'>
                                                    <CardHeader className='bg-transparent'>
                                                        <CardLabel>
                                                            <CardTitle tag='div' className='h5'>
                                                                Permanent Address
                                                            </CardTitle>
                                                        </CardLabel>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className='d-flex align-items-center pb-3'>
                                                            <div className='flex-shrink-0'>
                                                                <Icon
                                                                    icon='House'
                                                                    size='4x'
                                                                    color='primary'
                                                                />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold mb-0 fs-5'>
                                                                    {staff.address ? staff.address : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className='col-md-5'>
                                                <Card
                                                    className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-success bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-success-hover transition-base rounded-2 mb-0`}
                                                    stretch
                                                    shadow='sm'>
                                                    <CardHeader className='bg-transparent'>
                                                        <CardLabel>
                                                            <CardTitle tag='div' className='h5'>
                                                                Other details
                                                            </CardTitle>
                                                        </CardLabel>
                                                    </CardHeader>
                                                    <CardBody className='pt-0'>
                                                        <div className='row g-2 d-flex align-items-center'>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='School' size='2x' color='info' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold fs-5 mb-0'>
                                                                            {staff.qualification1 ? staff.qualification1 : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                            </div>
                                                                        <div className='text-muted'>
                                                                            Qualification
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='DateRange' size='2x' color='info' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold fs-5 mb-0'>
                                                                            {staff.dateOfBirthForView ? staff.dateOfBirthForView : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            Date of Birth
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <Icon icon='Bloodtype' size='2x' color='info' />
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3'>
                                                                        <div className='fw-bold fs-5 mb-0'>
                                                                            {staff.groupName ? staff.groupName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                          </div>
                                                                        <div className='text-muted'>
                                                                            Blood group
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            {/* <div className='col-lg-4'>
                                <Card>
                                    <CardHeader>
                                        <CardLabel icon='Person' iconColor='secondary'>
                                            <CardTitle tag='div' className='h5'>
                                                Attendance
                                            </CardTitle>
                                        </CardLabel>
                                        <CardActions>
                                            <strong>Total : {totalWorkingDays}</strong>
                                        </CardActions>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='col-12'>
                                            {staffAttendanceData.map((studentAttendance: any) => (
                                                <div className='row g-4 align-items-center' key={studentAttendance.studentDetailsId}>
                                                    <div className='col-xl-6'>
                                                        <div
                                                            className={classNames(
                                                                'd-flex align-items-center rounded-2 p-3',
                                                                {
                                                                    'bg-l10-success': !darkModeStatus,
                                                                    'bg-lo25-success': darkModeStatus,
                                                                },
                                                            )}>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='CheckBox' size='3x' color='success' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-4 text-success mb-0'>
                                                                    {studentAttendance.totalPresentCount}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    <b> PRESENT</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-xl-6'>
                                                        <div onClick={() => { setAbsentDetailsOffCanvas(studentAttendance.totalAbsentCount > 0 ? true : false) }}
                                                            className={classNames(
                                                                'd-flex align-items-center rounded-2 p-3 cursor-pointer',
                                                                {
                                                                    'bg-l10-danger': !darkModeStatus,
                                                                    'bg-lo25-danger': darkModeStatus,
                                                                },
                                                            )}>
                                                            <div className='flex-shrink-0'>
                                                                <Icon icon='PersonOff' size='3x' color='danger' />
                                                            </div>
                                                            <div className='flex-grow-1 ms-3'>
                                                                <div className='fw-bold fs-4 text-danger mb-0'>
                                                                    {studentAttendance.totalAbsentCount}
                                                                </div>
                                                                <div className='text-muted'>
                                                                    <b>ABSENT</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </CardBody>
                                </Card>
                            </div> */}

                            {staffDetailsId != '1' ?
                                <div className='col-lg-5'>
                                    <StaffAttendanceStatus staffDetailsId={staffDetailsId} height={197} />
                                </div> : null
                            }
                        </div>
                    </Page>

                    <OffCanvas setOpen={setAbsentDetailsOffCanvas} isOpen={absentDetailsOffCanvas} titleId='absentDetailsOffCanvas' isBodyScroll placement='end'>
                        <OffCanvasHeader setOpen={setAbsentDetailsOffCanvas}>
                            <OffCanvasTitle id='absentDetailsOffCanvas'>Absent Details</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            {staffAbsentAttendanceData.length != '' ? staffAbsentAttendanceData.map((student: any) => (
                                <Card key={student.studentAttendanceId} shadow='lg'>
                                    <CardBody>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col d-flex align-items-center'>
                                                    <div className='flex-shrink-0'>
                                                        <div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
                                                            <div
                                                                className={classNames(
                                                                    'rounded-2',
                                                                    'd-flex align-items-center justify-content-center',
                                                                    {
                                                                        'bg-l10-dark': !darkModeStatus,
                                                                        'bg-l90-dark': darkModeStatus,
                                                                    },
                                                                )}>
                                                                <span className='fw-bold'>{student.dateOfAttendanceForView.split(' ')[0]}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex-grow-1'>
                                                        <div className='fs-6'>{student.dateOfAttendanceForView}</div>
                                                        <div className='text-muted'>
                                                            <small>{student.remarks != '' ? student.remarks : '-'}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-auto text-end'>
                                                    <div className='flex-shrink-0'>
                                                        <div
                                                            style={{ width: 100 }}
                                                            className={classNames(
                                                                `bg-l${darkModeStatus ? 'o25' : '10'
                                                                }-danger text-danger fw-bold py-2 rounded-pill me-3 text-center`,
                                                            )}>
                                                            {student.absentOrPermission}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            )) : <div className='d-flex justify-content-evenly'>
                                <strong>FULL PRESENT</strong>
                            </div>}
                        </OffCanvasBody>
                    </OffCanvas>

                </PageWrapper>))}
        </>
    );
};

export default StaffProfile;
