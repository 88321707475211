import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import { toasts } from "../../../services/toast.service";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import AlertService from "../../../services/AlertService";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getAcademicPeriod } from "../../../services/master.service";
import AuthContext from "../../../contexts/authContext";
import { getColumnsForDataTable, getFailedStudentListByStandardDetailsId, getStandardDetails, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { getExamDetails, getExamTimeTable } from "../../../services/exam.service";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import Collapse from "../../bootstrap/Collapse";
import classNames from "classnames";
import { getProfileForStudent } from "../../../services/student.service";
import Icon from "../../icon/Icon";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../services/ExportService";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import NoDataMsg from "../../../common/components/NoDataMsg";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import useSortableData from "../../../hooks/useSortableData";
import Input from "../../bootstrap/forms/Input";
import { useFormik } from "formik";
import { getLicenseKey } from "../../../services/application.settings";

function FailedStudentList() {

    useEffect(() => {
        getAcademicPeriodList()
        getStandardDetailsList()
        if (userTypeId == 9) {
            getStudentProfile(userAccountId)
        }
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [examTimeTableData, setExamTimeTableData] = useState<any>([]);
    const componentRef = useRef(null);
    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [academicPeriodData, setAcademicPeriodData] = useState<any>([])
    const [examDetailsId, setExamDetailsId] = useState<any>('');
    const [examTypeData, setExamTypeData] = useState<any>([]);
    const [columnDataById, setColumnDataById] = useState([]);
    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [failedStudentData, setFailedStudentData] = useState([]);
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentAcademic, setStudentAcademic] = useState<any>('')
    const [studentStandard, setStudentStandard] = useState<any>('')
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [isChecked, setIsChecked] = useState(false);
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')
    const [exam, setExam] = useState<any>('')
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [standard, setStandard] = useState<any>('')
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const { items, requestSort, getClassNamesFor } = useSortableData(failedStudentData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(
        onCurrentPageData,
        columnVisibilityData,
        columnVisibilityForm,
    );

    const selectAcademicPeriod = (e: any) => {
        setExamDetailsId('')
        setStandardDetailsId('')
        let academicPeriodId = e
        setAcademicPeriodId(academicPeriodId)
        if (academicPeriodId?.value != undefined && userTypeId != 9) {
            getExam(academicPeriodId?.value)
        }
    }

    const selectExamType = (e: any) => {
        setStandardDetailsId('')
        let examDetailsId = e
        setExamDetailsId(examDetailsId)

    }

    const selectStandard = (e: any) => {
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
    }

    function getStandardDetailsList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        const standardData = [{ standardDetailsId: 0, standardName: 'Select All' }];
                        data.forEach((obj: { standardDetailsId: number; standardName: string }) => {
                            standardData.push(obj);
                        });
                        setStandardData(standardData);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        setAcademicPeriodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setAcademicPeriodData([]);
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getExam(academicPeriodId: any) {
        getExamDetails(academicPeriodId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examDetails;
                    if (data != undefined) {
                        setExamTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setExamTypeData([]);
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentProfile(studentDetailsId: any) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                        setStudentAcademic(data[0].academicPeriodId)
                        setStudentStandard(data[0].standardDetailsId)
                        getExam(data[0].academicPeriodId)
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setDataSuccess(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    function getFailedStudentListByStandardDetails(examDetailsId: string, standardDetailsId: string) {
        setIsLoader(true);
        getFailedStudentListByStandardDetailsId(userAccountId, examDetailsId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.failedStudentListByStandardDetailsId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setFailedStudentData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        getColumnsForTable('getFailedStudentListByStandardDetailsId', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getFailedStudentListByStandardDetailsId', 'get');
                    setFailedStudentData([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(
            userTypeId,
            apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData);
                        let columnVisibilityData = allColumnsData.filter(
                            (item: any) => item.isDisplay == 1,
                        );
                        setColumnVisibilityData(columnVisibilityData);

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        } else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter(
                                (item: any) => item.isDefault == false,
                            );

                            for (let i = 0; i < nonDefaultValue.length; i++) {
                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1,
                                };
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all);
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
        if (columnVisibilityForm.isValid) {
            setColumnDataById(isDisplay);
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type
                    ? type
                    : [
                        {
                            columnVisibilityId: columnVisibilityId,
                            isDisplay: isDisplay ? 0 : 1,
                        },
                    ],
            };

            updateColumnsForDataTable(
                columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getFailedStudentListByStandardDetailsId', 'get');
                    } else if (data.success == false) {
                        // toasts(data.success, "Error")
                    } else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, 'Error');
                    }
                },
                (error) => {
                    toasts(error, 'Error');
                },
            );
        } else if (columnVisibilityForm.isValid == false) {
            toasts('Please fill all the details!', 'Error');
        }
    }

    function viewExamList() {
        setStandard('');
        setExam('');
        setIsLoader(true);
        setDataSuccess(false);
        setExamTimeTableData([]);
        getFailedStudentListByStandardDetails(examDetailsId?.value, standardDetailsId?.value)
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Failed Student List`}>
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Failed Student List
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                {userTypeId == 9 ?
                                    <div className="row">
                                        <div className=" col-md-3">
                                            <FormGroup id='examDetailsId' label='Exam Type' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Exam Type' placeholder="Exam Type"
                                                    value={examDetailsId}
                                                    onChange={selectExamType}
                                                    list={examTypeData.map((data: any) => (
                                                        { value: data.examDetailsId, label: data.examTypeName }
                                                    ))} required />
                                            </FormGroup>
                                        </div>

                                        {examDetailsId ?
                                            <div className='col-md-3 align-self-center'>
                                                <Button icon='ArrowDownward' color='primary' onClick={viewExamList}>View</Button>
                                            </div>
                                            : null}
                                    </div>
                                    :
                                    <div className="row g-4">
                                        <div className='col-md-3'>
                                            <FormGroup id='academicPeriodId' label='Academic Period' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Academic Period' placeholder="Academic Period"
                                                    value={academicPeriodId}
                                                    onChange={selectAcademicPeriod}
                                                    list={academicPeriodData.map((data: any) => (
                                                        { value: data.academicPeriodId, label: data.period }
                                                    ))} required />
                                            </FormGroup>
                                        </div>
                                        {academicPeriodId ?
                                            <div className=" col-md-3">
                                                <FormGroup id='examDetailsId' label='Exam' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Exam' placeholder="Exam"
                                                        value={examDetailsId}
                                                        onChange={selectExamType}
                                                        list={examTypeData.map((data: any) => (
                                                            { value: data.examDetailsId, label: data.examTypeName }
                                                        ))} required />
                                                </FormGroup>
                                            </div> : null}

                                        {examDetailsId ?
                                            <div className='col-md-3'>
                                                <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Standard' placeholder="Standard"
                                                        onChange={selectStandard}
                                                        value={standardDetailsId}
                                                        list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />
                                                </FormGroup>
                                            </div> : null}

                                        {standardDetailsId ?
                                            <div className='col-md-3 align-self-center'>
                                                <Button icon='ArrowDownward' color='primary' onClick={viewExamList}>View</Button>
                                            </div>
                                            : null}
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />
                    {dataSuccess ? (
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                    <CardTitle tag='div' className='h5'>
                                        Failed Student List
                                    </CardTitle>
                                </CardLabel>
                                &nbsp;&nbsp;
                                <ButtonGroup
                                    className='col-lg-3 justify-content-end d-print-none'
                                    color='primary'>
                                    <Icon
                                        className='mt-1'
                                        icon='Search'
                                        size='2x'
                                        color='primary'
                                    />
                                    <Input
                                        id='searchInput'
                                        type='search'
                                        placeholder='Search...'
                                        onChange={columnVisibilityForm.handleChange}
                                        value={columnVisibilityForm.values.searchInput}
                                    />
                                </ButtonGroup>
                                <CardActions className='d-print-none'>
                                    <Dropdown
                                        isOpen={columnVisibilityMenu}
                                        setIsOpen={setColumnVisibilityMenu}
                                        isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>
                                                Filter
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu
                                            isAlignmentEnd
                                            size='lg'
                                            isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks
                                                                    id='available'
                                                                    type='switch'
                                                                    label='Select All Columns'
                                                                    onChange={() =>
                                                                        getColumnsForTable(
                                                                            'getFailedStudentListByStandardDetailsId',
                                                                            'post',
                                                                        )
                                                                    }
                                                                    checked={isChecked}
                                                                    ariaLabel='Available status'
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div
                                                            className='col-12'
                                                            style={{
                                                                maxHeight: '200px',
                                                                overflowY: 'scroll',
                                                            }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map(
                                                                        (i: any) => (
                                                                            <Checks
                                                                                key={
                                                                                    i.columnVisibilityId
                                                                                }
                                                                                id='{i.columnVisibilityId}'
                                                                                label={i.columnName}
                                                                                onChange={() =>
                                                                                    updateColumnsSubmit(
                                                                                        i.columnVisibilityId,
                                                                                        i.isDisplay,
                                                                                        '',
                                                                                    )
                                                                                }
                                                                                checked={
                                                                                    i.isDisplay
                                                                                }
                                                                                disabled={
                                                                                    i.isDefault ==
                                                                                    true
                                                                                }
                                                                            />
                                                                        ),
                                                                    )}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'>
                                                {' '}
                                                Export{' '}
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='CloudDownload'
                                                    onClick={() =>
                                                        downloadExcel(
                                                            'Failed Student List',
                                                            failedStudentData,
                                                            columnVisibilityData,
                                                        )
                                                    }>
                                                    {' '}
                                                    Excel{' '}
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='PictureAsPdf'
                                                    onClick={() =>
                                                        convertJsonToPdf(
                                                            failedStudentData,
                                                            columnVisibilityData,
                                                            'Failed Student List',
                                                        )
                                                    }>
                                                    {' '}
                                                    PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='CloudDownload'
                                                    onClick={() =>
                                                        downloadFile(
                                                            'Failed Student List',
                                                            failedStudentData,
                                                            columnVisibilityData,
                                                        )
                                                    }>
                                                    {' '}
                                                    CSV{' '}
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button
                                                    color='primary'
                                                    isLight
                                                    icon='Print'
                                                    onClick={handlePrint}>
                                                    {' '}
                                                    Print{' '}
                                                </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th
                                                scope='col'
                                                onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>
                                                S.No{' '}
                                                <Icon
                                                    size='lg'
                                                    className={getClassNamesFor('sno')}
                                                    icon='FilterList'
                                                />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th
                                                    key={column.keyName}
                                                    scope='col'
                                                    onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon
                                                        size='lg'
                                                        className={getClassNamesFor(column.keyName)}
                                                        icon='FilterList'
                                                    />
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != '' ? (
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.referredId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) =>
                                                            column.isDisplay &&
                                                                rowData[column.keyName] ? (
                                                                <td
                                                                    key={`${rowData.referredId}-${column.keyName}`}>
                                                                    {rowData[column.keyName]}
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`empty-${column.keyName}`}
                                                                />
                                                            ),
                                                        )}

                                                    </tr>
                                                ))}{' '}
                                            </>
                                        ) : (
                                            <NoDataMsg
                                                columnsCount={columnVisibilityData.length + 2}
                                                msg={noDataMsg}
                                            />
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className='d-print-none'
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>
                    ) : null}
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
}
export default FailedStudentList;