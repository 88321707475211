import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Icon from '../../icon/Icon';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardBody, CardLabel, CardTitle } from '../../bootstrap/Card';
import Button from '../../bootstrap/Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import useTourStep from '../../../hooks/useTourStep';
import { getStaffListByUserTypeId } from '../../../services/staff.service';
import AuthContext from '../../../contexts/authContext';
import { toasts } from '../../../services/toast.service';
import { getUserType, pictNotLoading, profilePic } from '../../../services/common.service';
import { TableLoader } from '../../../services/loader.services';
import Badge from '../../bootstrap/Badge';

const StaffListByUserType = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getUserTypeList();
		getStaffListByUserType(0);
	}, []);

	useTourStep(18);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [staffDetailsData, setStaffDetailsData] = useState<any>([]);
	const [userDetailsId, setUserDetailsId] = useState<any>(0);
	const [userTypeData, setUserTypeData] = useState<any>([]);

	const [userType, setUserType] = useState<any>('');
	const [dataSuccess, setDataSuccess] = useState<any>('');

	const selectStaff = (i: any) => {
		// setDataSuccess(false);
		setUserDetailsId(i.userDetailsId);
		setUserType(i.userType);
		getStaffListByUserType(i.userTypeId);
	};

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						let userType = data.filter((item: any) => item.userTypeId != 9 && item.userTypeId != 10)
						const userTypeData = [{ userTypeId: 0, userType: 'All Staff' }];
						userType.forEach((obj: { userTypeId: number; userType: string }) => {
							userTypeData.push(obj);
						});
						setUserTypeData(userTypeData);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffListByUserType(staffTypeId: any) {
		getStaffListByUserTypeId(userAccountId, staffTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffListByUserTypeId;
					if (data != undefined) {
						setStaffDetailsData(data);
						setDataSuccess(true);
					} else {
						toasts('Undefined Data', 'Error');
						setDataSuccess(true);
					}
				} else if (response.data.success === false) {
					setDataSuccess(true);
					setStaffDetailsData([]);
					setNoDataMsg(response.data.message);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataSuccess(true);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataSuccess(true);
			},
		);
	}

	return (
		<PageWrapper title='Staff List By UserType'>
			<SubHeader>
				<SubHeaderLeft>
					<CardLabel icon='list' iconColor='primary'>
						<CardTitle tag='div' className='h5'>
							Staff Profile
						</CardTitle>
					</CardLabel>
				</SubHeaderLeft>
				<SubHeaderRight>
					<SubheaderSeparator />
					<Dropdown isButtonGroup className='mb-0'>
						<Button color='success' isLight icon='AccountBox'>
							{userDetailsId == 0 ? 'All Staff' : userType}
						</Button>
						<DropdownToggle>
							<Button color='success' isLight isVisuallyHidden />
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd>
							{userTypeData.map((i: any) => (
								<DropdownItem>
									<Button onClick={() => selectStaff(i)}>{i.userType}</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
				{!dataSuccess ? (
					<TableLoader />
				) : (
					staffDetailsData != '' ?
						<div className='row row-cols-xxl-3 row-cols-lg-3 row-cols-md-2'>
							{staffDetailsData.map((i: any) => (
								<div key={i.staffDetailsId} className='col'>
									<Card stretch className='border border-light rounded-1'>
										<CardBody>
											<div className='row g-3'>
												<div className='col d-flex'>
													<div className='flex-shrink-0'>
														<div className='position-relative'>
															<div
																className='ratio ratio-1x1'
																style={{ width: 100 }}>
																<div
																	className={classNames(
																		`bg-l25-${i.color}`,
																		'rounded-2',
																		'd-flex align-items-center justify-content-center',
																	)}>
																	{i.profilePath != null &&
																		i.profilePath != '' ? (
																		<img
																			style={{
																				borderRadius:
																					'50%',
																			}}
																			src={i.profilePath}
																			width='90'
																			height='90'
																			onError={(e: any) =>
																				pictNotLoading(
																					e,
																					i.genderId,
																				)
																			}
																		/>
																	) : (
																		<img
																			// className='rounded-0'
																			src={profilePic(
																				i.genderId,
																			)}
																			width='90'
																			height='90'
																		/>
																	)}
																</div>
															</div>
														</div>
													</div>
													<div className='flex-grow-1 ms-3 d-flex justify-content-between'>
														<div className='w-100'>
															<div className='row'>
																<div className='col'>
																	<div className='d-flex align-items-center'>
																		<div className='fw-bold me-2 text-uppercase' style={{ fontSize: '14px' }}>
																			{i.staffName}
																		</div>
																	</div>
																	<div className='text-muted fs-6'>
																		{i.empNumber}
																	</div>
																	<div className='mt-2'>
																		<Badge
																			className={`border border-success rounded-0 px-3 py-2`}
																			isLight
																			color={'success'}
																		>
																			{i.userType}
																		</Badge>
																		<Badge
																			className={`border border-info rounded-0 px-3 py-2 ${i.qualification1 != '' ? 'ms-2' : ''}`}
																			isLight
																			color={'info'}
																		>
																			{i.qualification1}
																		</Badge>
																	</div>
																	<div className='text-muted mt-2'>
																		<Badge
																			className={`border border-secondary rounded-0 px-3 py-2`}
																			isLight
																			color={'secondary'}
																		>
																			{i.email}
																		</Badge>
																	</div>
																	<div className='text-muted mt-2'>
																		<Badge
																			className={`border border-primary rounded-0 px-3 py-2`}
																			isLight
																			color={'primary'}
																		>
																			{i.contactNumber}
																		</Badge>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							))}
						</div> :
						<div className='row'>
							<div className='col-12' style={{ height: '20vh' }}></div>
							<div className='col-12 d-flex justify-content-center'>
								<Icon icon='DoNotDisturb' size={'10x'} />
							</div>
							<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
								{noDataMsg}
							</div>
						</div>
				)}
			</Page>
		</PageWrapper>
	);
};

export default StaffListByUserType;
