
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import Collapse from "../../../bootstrap/Collapse";
import OpenCardComponent from "../../../../common/components/OpenCardComponent";
import { useContext, useEffect, useRef, useState } from "react";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import { useFormik } from "formik";
import classNames from "classnames";
import { useReactToPrint } from "react-to-print";
import Icon from "../../../icon/Icon";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import Input from "../../../bootstrap/forms/Input";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../services/ExportService";
import { toasts } from "../../../../services/toast.service";
import { getColumnsForDataTable, getConcessionType, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import AuthContext from "../../../../contexts/authContext";
import { getLicenseKey } from "../../../../services/application.settings";
import { showLoader } from "../../../../services/loader.services";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import SearchableSelect from "../../../../common/components/SearchableSelect";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import { addStudentAdvanceFee, getNotAssignedTermFees, getStudentFeeDetails } from "../../../../services/fee.service";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../bootstrap/OffCanvas";
import Label from "../../../bootstrap/forms/Label";
import { approveStudentFeeConcession, getStudentConcessionList, rejectStudentFeeConcession } from "../../../../services/concession.service";
import Textarea from "../../../bootstrap/forms/Textarea";
import AlertService from "../../../../services/AlertService";

function ConcessionList() {

	useEffect(() => {
		getConcessionTypeList()
	}, [])

	const componentRef = useRef(null);
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const { themeStatus, darkModeStatus } = useDarkMode();

	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([])
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [isChecked, setIsChecked] = useState(false)

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
	const [isOpen, setIsOpen] = useState(false);

	// For Table
	const [studentConcessionData, setStudentConcessionData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(studentConcessionData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const [isOpenListCard, setIsOpenListCard] = useState(true)
	const [dataSuccess, setDataSuccess] = useState(false)
	const [isLoader, setIsLoader] = useState(false)
	const [isNavigate, setIsNavigate] = useState<any>(null);

	const [concessionTypeId, setConcessionTypeId] = useState<any>('')
	const [noDataMsg, setNoDataMsg] = useState('')
	const [studentConcessionAmount, setStudentConcessionAmount] = useState(0)
	const [studentConcessionDetailsId, setStudentConcessionDetailsId] = useState<any>('')

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [isOpenRejectModal, setIsOpenRejectModal] = useState(false)
	const [studentFeeDetails, setStudentFeeDetails] = useState<any>([])
	const [hideLoader, setHideLoader] = useState(false);
	const [noDataMsgForStudentFeeDetails, setNoDataMsgForStudentFeeDetails] = useState<any>('')
	const [advanceTutionFeesNoData, setAdvanceTutionFeesNoData] = useState("")
	const [studentDetailsId, setStudentDetailsId] = useState<any>('')

	const [addAdvanceFeeOffCanvas, setAddAdvanceFeeOffCanvas] = useState(false)
	const [termFeesForTutionFeesData, setTermFeesForTutionFeesData] = useState<any>([])
	const [termFeesForTransportFeesData, setTermFeesForTransportFeesData] = useState<any>([])
	const [concessionData, setConcessionData] = useState([])
	const [concessionDetails, setConcessionDetails] = useState<any>([])
	const [advanceTransportFeesNoData, setAdvanceTransportFeesNoData] = useState("")

	const [studentAdvanceFee, setStudentAdvanceFee] = useState<any>([])
	const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([])
	const [remarks, setRemarks] = useState('')
	const [totalFeesAmount, setTotalFeesAmount] = useState<any>(0)

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		validateOnChange: false,
		onSubmit: () => { },
	});

	const selectConcessionType = (e: any) => {
		setDataSuccess(false)
		setIsLoader(false)
		let concessionTypeId = e
		setConcessionTypeId(concessionTypeId)
		setIsOpenListCard(true)
	}

	function onApprove(i: any) {
		setIsOpenModal(true)
		getStudentFeeList(userTypeId, i.studentDetailsId)
		setStudentDetailsId(i.studentDetailsId)
		setStudentConcessionAmount(i.amount)
		setStudentConcessionDetailsId(i.studentConcessionDetailsId)
	}

	function onReject(i: any) {
		setIsOpenRejectModal(true)
		setStudentDetailsId(i.studentDetailsId)
		setStudentConcessionDetailsId(i.studentConcessionDetailsId)
	}

	function generalConcession() {
		setIsLoader(true);
		getStudentConcession(concessionTypeId?.value)
	}

	function selectTermFee() {
		getTermFeesForTutionFees(studentDetailsId, 1);
		getTermFeesForTransportFees(studentDetailsId, 4);
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

	const updateFeesReceivedValue = (cell: number, amount: any) => {

		studentFeeDetails.forEach((feeType: any) => {

			let feesData = feeType.studentFees
			const newData: any = [];

			feesData.forEach((item: any) => {

				if (item.studentFeeDetailsId == cell) {
					item.amount = amount
				}
				newData.push(item)
				setFilteredDataToRefresh(newData)
			})
		});

		if (concessionDetails.length == 0) {
			const obj = {
				studentFeeDetailsId: cell,
				concessionAmount: amount,
				concessionRemarks: null
			};
			concessionDetails.push(obj);
		} else {
			let selectedItem: any;
			concessionDetails.forEach((item: any, index: any) => {
				if (item['studentFeeDetailsId'] == cell) {
					selectedItem = item
					selectedItem['concessionAmount'] = amount
				}
			});
			if (selectedItem != undefined) {
				selectedItem['concessionAmount'] = amount
			} else {
				var obj
				obj = {
					studentFeeDetailsId: cell,
					concessionAmount: amount,
					concessionRemarks: null
				};
				concessionDetails.push(obj);
			}
		}
		totalPayFees()
	}

	function totalPayFees() {
		let totalFeesAmount1 = 0;
		for (let data of concessionDetails) {
			let feeAmount = Number(data.concessionAmount)
			totalFeesAmount1 += feeAmount;
		}
		setTotalFeesAmount(totalFeesAmount1);
	}

	function setFeeConcessionPostDetails() {
		return ({
			concessionTotalAmount: totalFeesAmount,
			studentDetailsId: studentDetailsId,
			studentConcessionDetailsId: studentConcessionDetailsId,
			concessionDetails: concessionDetails,
			remarks: remarks,
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId
		})
	}

	function setCollectAdvanceFeeDetails() {
		return ({
			studentAdvanceFee: studentAdvanceFee,
			studentDetailsId: studentDetailsId,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId
		})
	}

	function approveStudentFeeConcessionSumit() {
		showLoader(true)
		if (concessionDetails.length > 0) {
			let feeConcessionPostData = setFeeConcessionPostDetails();
			approveStudentFeeConcession(feeConcessionPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						getStudentFeeList(userTypeId, studentDetailsId);
						getStudentConcession(concessionTypeId?.value)
						closeAndReset()
					}
					else if (data.success == false) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
					else {
						showLoader(false)
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					showLoader(false)
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (studentAdvanceFee.length == 0) {
			showLoader(false)
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function rejectStudentFeeConcessionSubmit() {
		showLoader(true)
		if (studentConcessionDetailsId > 0) {
			let feeConcessionPostData = setFeeConcessionPostDetails();
			rejectStudentFeeConcession(feeConcessionPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						getStudentFeeList(userTypeId, studentDetailsId);
						getStudentConcession(concessionTypeId?.value)
						closeAndReset()
					}
					else if (data.success == false) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
					else {
						showLoader(false)
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					showLoader(false)
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (studentAdvanceFee.length == 0) {
			showLoader(false)
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function payAdvanceFeesSubmit() {
		showLoader(true)
		if (studentAdvanceFee.length > 0) {
			let payAdvanceFeesPostData = setCollectAdvanceFeeDetails();
			addStudentAdvanceFee(payAdvanceFeesPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						getStudentFeeList(userTypeId, studentDetailsId);
						selectTermFee()
						setStudentAdvanceFee([])
						setAddAdvanceFeeOffCanvas(false)
						setIsOpenModal(true)
					}
					else if (data.success == false) {
						showLoader(false)
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
					else {
						showLoader(false)
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					showLoader(false)
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (studentAdvanceFee.length == 0) {
			showLoader(false)
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function getConcessionTypeList() {
		getConcessionType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.concessionType;
					if (data != undefined) {
						setConcessionData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setConcessionData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getStudentConcession(concessionTypeId: any) {
		setIsLoader(true);
		getStudentConcessionList(concessionTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentFeeConcession;
					if (data != undefined) {
						setIsLoader(false);
						setStudentConcessionData(data);
						setIsOpenListCard(false);
						setDataSuccess(true);
						getColumnsForTable('getStudentFeeConcession', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						toasts('Undefined Data', 'Error');
						setIsLoader(false);
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
					getColumnsForTable('getStudentFeeConcession', 'get');
					setStudentConcessionData([]);
					setNoDataMsg(response.data.message);
					setIsLoader(false);
					setIsOpenListCard(false);
					setDataSuccess(true);
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function getTermFeesForTutionFees(studentDetailsId: number, feeTypeId: number) {
		getNotAssignedTermFees(studentDetailsId, feeTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.notAssignedTermFees;
					if (data != undefined) {
						setTermFeesForTutionFeesData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setTermFeesForTutionFeesData([]);
					setAdvanceTutionFeesNoData(response.data.message)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getTermFeesForTransportFees(studentDetailsId: number, feeTypeId: number) {
		getNotAssignedTermFees(studentDetailsId, feeTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.notAssignedTermFees;
					if (data != undefined) {
						setTermFeesForTransportFeesData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setTermFeesForTransportFeesData([]);
					setAdvanceTransportFeesNoData(response.data.message)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getStudentFeeList(userTypeId: number, studentDetailsId: number) {
		setHideLoader(true)
		getStudentFeeDetails(userTypeId, studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentFeeDetails[0]['feeType'];
					if (data != undefined) {
						setStudentFeeDetails(data);
						setHideLoader(false)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setNoDataMsgForStudentFeeDetails(response.data.message)
					setHideLoader(false)
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
					setHideLoader(false)
				}
			}, error => {
				toasts(error, "Error")
				setHideLoader(false)
			}
		)
	}

	function addStudentAdvanceFeeForTutionValue(cell: any, feeMasterId: number, feeTypeId: number, standardDetailsId: any, feeAmount: number) {

		const newData: any = [];

		termFeesForTutionFeesData.forEach((item: any) => {

			if (item.termDetailsId == cell) {
				item.isChecked = true
			}
			newData.push(item)
			setFilteredDataToRefresh(newData)
		});

		if (studentAdvanceFee.length == 0) {

			var obj
			obj = {
				termDetailsId: cell,
				standardDetailsId: standardDetailsId,
				fmOrTrasportDetailsId: feeMasterId,
				feeTypeId: feeTypeId,
				amount: feeAmount,
			};
			studentAdvanceFee.push(obj);
		}
		else {
			let selectedItem: any
			studentAdvanceFee.forEach((item: any, index: number) => {
				if (item['termDetailsId'] == cell) {
					selectedItem = item
				}
			});
			if (selectedItem != undefined) {
				const newData: any = [];

				termFeesForTutionFeesData.forEach((item: any) => {

					if (item.termDetailsId == cell) {
						item.isChecked = false
					}
					newData.push(item)
					setFilteredDataToRefresh(newData)
				});

				studentAdvanceFee.splice(studentAdvanceFee.indexOf(selectedItem), 1);
				selectedItem['termDetailsId'] = cell
			}
			else {
				var obj
				obj = {
					termDetailsId: cell,
					standardDetailsId: standardDetailsId,
					fmOrTrasportDetailsId: feeMasterId,
					feeTypeId: feeTypeId,
					amount: feeAmount,
				};
				studentAdvanceFee.push(obj);
			}
		}
	}

	function addStudentAdvanceFeeForTrasportValue(cell: number, fmOrTrasportDetailsId: any, feeTypeId: number, standardDetailsId: any, feeAmount: number) {

		const newData: any = [];

		termFeesForTransportFeesData.forEach((item: any) => {

			if (item.termDetailsId == cell) {
				item.fmOrTrasportDetailsId = fmOrTrasportDetailsId
				item.isChecked = true
			}
			newData.push(item)
			setFilteredDataToRefresh(newData)
		});

		if (studentAdvanceFee.length == 0) {

			var obj
			obj = {
				termDetailsId: cell,
				fmOrTrasportDetailsId: fmOrTrasportDetailsId,
				standardDetailsId: standardDetailsId,
				feeTypeId: feeTypeId,
				amount: feeAmount,
			};
			studentAdvanceFee.push(obj);
		}
		else {
			let selectedItem: any
			studentAdvanceFee.forEach((item: any, index: number) => {
				if (item['termDetailsId'] == cell) {
					selectedItem = item
				}
			});
			if (selectedItem != undefined) {
				studentAdvanceFee.splice(studentAdvanceFee.indexOf(selectedItem), 1);

				const newData: any = [];

				termFeesForTransportFeesData.forEach((item: any) => {

					if (item.termDetailsId == cell) {
						item.fmOrTrasportDetailsId = fmOrTrasportDetailsId
						item.isChecked = false
					}
					newData.push(item)
					setFilteredDataToRefresh(newData)
				});

				selectedItem['termDetailsId'] = cell
			}
			else {
				var obj
				obj = {
					termDetailsId: cell,
					fmOrTrasportDetailsId: fmOrTrasportDetailsId,
					standardDetailsId: standardDetailsId,
					feeTypeId: feeTypeId,
					amount: feeAmount,
				};
				studentAdvanceFee.push(obj);
			}
		}
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)

	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

		if (columnVisibilityForm.isValid) {

			setColumnDataById(isDisplay)
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStudentFeeConcession', 'get');
					}
					else if (data.success == false) {
						// toasts(data.success, "Error")
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibilityForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	function closeAndReset() {
		setStudentConcessionAmount(0)
		setTotalFeesAmount(0)
		setRemarks('')
		selectTermFee()
		setStudentAdvanceFee([])
		setAddAdvanceFeeOffCanvas(false)
		setIsOpenModal(false)
		setConcessionDetails([])
		setStudentDetailsId('')
		setStudentConcessionDetailsId('')
		setIsOpenRejectModal(false)
	}

	return (
		<>
			<PageWrapper title="Concession Report">
				<Page container='fluid'>
					<Collapse isOpen={isOpenListCard}>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info' className="col-lg-6">
									<CardTitle tag='div' className='h5'>Student Concession</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className="row">
									<div className="col-lg-3">
										<FormGroup id="concessionTypeId" label="Concession Type" isFloating>
											<SearchableSelect placeholder="Select Concession Type" isFloating
												ariaLabel='Concession Type'
												onChange={selectConcessionType}
												value={concessionTypeId}
												list={concessionData.map((data: any) => (
													{ value: data.concessionTypeId, label: data.concessionType }
												))}
											/>
										</FormGroup>
									</div>
									<div className="col-lg-3 align-self-center">
										<FormGroup >
											<Button icon="South" color="primary"
												onClick={generalConcession}>View</Button>
										</FormGroup>
									</div>
								</div>
							</CardBody>
						</Card>
					</Collapse>

					<OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

					{dataSuccess ?
						<Card stretch data-tour='list' ref={componentRef} id='pdf'>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info' className="col-lg-6">
									<CardTitle tag='div' className='h5'>Student Concession List</CardTitle>
								</CardLabel>&nbsp;&nbsp;

								<ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
									<Icon className="mt-1" icon='Search' size='2x' color='primary' />
									<Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
								</ButtonGroup>

								<CardActions className="d-print-none">
									<Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
										<DropdownToggle>
											<Button icon='FilterAlt' color='primary' isLight>Filter</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
											<DropdownItem>
												<div className='container py-2'>
													<form className='row g-3'>
														<div className='col-12'>
															<FormGroup>
																<h6>Select All</h6>
																<Checks
																	id='available'
																	type='switch'
																	label='Select All Columns'
																	onChange={() => getColumnsForTable('getStudentFeeConcession', 'post')}
																	checked={isChecked}
																	ariaLabel='Available status'
																/>
															</FormGroup>
														</div>
														<div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
															<FormGroup>
																<h6>Columns</h6>
																<ChecksGroup>
																	{allColumnsData.map((i: any) => (
																		<Checks
																			key={i.columnVisibilityId}
																			id='{i.columnVisibilityId}'
																			label={i.columnName}
																			onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')}
																			checked={i.isDisplay}
																			disabled={i.isDefault == true}
																		/>
																	))}
																</ChecksGroup>
															</FormGroup>
														</div>
													</form>
												</div>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									<Dropdown isButtonGroup>
										<DropdownToggle>
											<Button color='primary' isLight icon='CloudDownload'> Export </Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											<DropdownItem>
												<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("StudentConcessionData", studentConcessionData, columnVisibilityData)}> Excel </Button>
											</DropdownItem>
											<DropdownItem>
												<Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(studentConcessionData, columnVisibilityData, "StudentConcessionData")}> PDF
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("StudentConcessionData", studentConcessionData, columnVisibilityData)}> CSV </Button>
											</DropdownItem>
											<DropdownItem>
												<Button color='primary' isLight icon='Print' onClick={handlePrint} > Print </Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>

								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive' isScrollable>
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th scope='col' onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>S.No{' '}
												<Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
											</th>
											{columnVisibilityData.map((column: any) => (
												<th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
												</th>
											))}
											<th><div style={{ marginLeft: '17px' }}>Action</div></th>
											<td />
										</tr>
									</thead>
									<tbody>
										{filteredData != '' ?
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.studentDetailsId}>
														<td>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) => (
															(column.isDisplay && rowData[column.keyName]) ?
																<td key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
														))}
														<td className='d-print-none'>
															<div>
																<Button
																	onClick={() => onApprove(rowData)}
																	isOutline={!darkModeStatus}
																	color='success'
																	isLight={darkModeStatus}
																	className={classNames('text-nowrap', {
																		'border-light': !darkModeStatus,
																	}, 'mg-lg-t-2')}> A
																</Button> &nbsp;
																<Button
																	onClick={() => onReject(rowData)}
																	isOutline={!darkModeStatus}
																	color='danger'
																	isLight={darkModeStatus}
																	className={classNames('text-nowrap', {
																		'border-light': !darkModeStatus,
																	}, 'mg-lg-t-2')}> R
																</Button>
															</div>
														</td>
													</tr>
												))}
											</> :
											<NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								data={items}
								label='items'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</Card> : null
					}
				</Page>

				<Modal
					setIsOpen={setIsOpenModal}
					isOpen={isOpenModal}
					titleId='addConcession'
					isStaticBackdrop
					size='xl'>
					<ModalHeader className="'modal-header'"
						setIsOpen={() => { setIsOpenModal(false) }} >
						<ModalTitle id='addConcession'>Add Concession</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className="row">
							<div className='col-lg-6'>
								<span className='fs-4 fw-bold d-flex justify-content-start text-center text-uppercase text-primary'><p className="mt-1">Offered Concession Amount  : </p>
									&nbsp;&nbsp;<FormGroup id='concessionTotalAmount'>
										<Input
											value={studentConcessionAmount}
											onChange={(e: any) => setStudentConcessionAmount(e.target.value)}
											onKeyDown={(e: any) => onlyAllowNumber(e)}
										/>
									</FormGroup>
								</span>
							</div>
							<div className="col-6">
								<FormGroup className="d-flex justify-content-end">
									<Button color='storybook' icon='Add' isLight onClick={() => { closeAndReset(); setAddAdvanceFeeOffCanvas(true) }}>
										Add Advance Fee
									</Button>
								</FormGroup>
							</div>
						</div>

						{studentFeeDetails != '' ?
							<>
								{studentFeeDetails.map((feeType: any) => (
									<div key={feeType.feeTypeId}>
										<table className='table table-modern table-hover text-center' >
											<thead>
												<tr className='table-primary'>

													<th scope='col' style={{ width: '20%' }}>{feeType.feeType}</th>
													<th scope='col' style={{ width: '15%' }}>TOTAL FEE</th>
													<th scope='col' style={{ width: '15%' }}>TOTAL RECEIVED</th>
													<th scope='col' style={{ width: '10%' }}>CONCESSION</th>
													<th scope='col' style={{ width: '15%' }}>BALANCE</th>
													<th scope='col' style={{ width: '15%' }}>AMOUNT</th>

												</tr>
											</thead>
											<tbody className='align-middle'>
												{feeType.studentFees.map((feesData: any) => (
													<tr key={feesData.studentFeeDetailsId}>
														<td className='fs-6 text-center'>
															{feesData.termName}
															{feeType.feeTypeId === 4 ? <p className="text-info">
																({feesData.areaName})</p> : feeType.feeTypeId === 14 ? <p className="text-info">({feesData.extraFeeType})</p> : ''}
														</td>
														<td className='fs-6'>{feesData.totalFees}</td>
														<td className='fs-6 text-success'>{feesData.totalFeesReceived}</td>
														<td className='fs-6 text-info'>{feesData.concession}</td>
														<td className='fs-6 text-danger'>{feesData.balanceToBePaid}</td>
														{feesData.balanceToBePaid == 0 ? <td className='fs-6 fw-bold text-success'>PAID</td> :
															<td>
																<FormGroup id='amount'>
																	<Input className="text-center w-100" placeholder='0' value={feesData.amount} onInput={(e: any) => updateFeesReceivedValue(feesData.studentFeeDetailsId, e.target.value)}
																		onKeyDown={(e: any) => onlyAllowNumber(e)} />
																</FormGroup>

															</td>}
													</tr>))}
											</tbody>
										</table>
										<br />
									</div>
								))} </>
							: <p className='text-center'>{noDataMsgForStudentFeeDetails}</p>
						}
						<div className='row h-100 pb-3'>
							<div className="col-lg-5">
								<FormGroup id="narration" label="Remarks" isFloating>
									<Textarea placeholder='Enter Remarks' value={remarks} onChange={(e: any) => setRemarks(e.target.value)} />
								</FormGroup>
							</div>

							<div className="col-lg-4"></div>

							<div className="col-lg-3 mt-2 d-flex justify-content-end">
								<Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button> &nbsp;
								<Button color='info' icon='Save' type="submit" onClick={approveStudentFeeConcessionSumit} isDisable={totalFeesAmount == studentConcessionAmount ? false : true}>Save</Button>
							</div>

						</div>
					</ModalBody>
				</Modal>

				<OffCanvas
					setOpen={setAddAdvanceFeeOffCanvas}
					isOpen={addAdvanceFeeOffCanvas}
					titleId='addAdvanceFeeOffCanvas'
					placement='end'
				>
					<OffCanvasHeader setOpen={setAddAdvanceFeeOffCanvas} onClick={() => setIsOpenModal(true)}>
						<OffCanvasTitle id="addAdvanceFeeOffCanvas">Add Advance Fee</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row'>
							<div className='col-12'>
								<Card className={`bg-l${darkModeStatus ? 'o25' : '25'
									}-success bg-l${darkModeStatus ? 'o50' : '10'
									}-success-hover transition-base rounded-2 mb-4`}
									shadow='sm'>
									<CardBody>
										<div className='d-flex align-items-center pb-3'>
											<div className='flex-shrink-0 ms-2'>
												<Icon icon='PushPin' size='3x' color='danger' />
											</div>
											<div className='flex-grow-1 ms-3'>
												<span className='text-muted'>
													If advance fees not listed. <br /> Please add {''}<Icon icon='East' color='danger' className='mx-1' size='lg' /><Button color='link' tag='a'
														to={`../general/feeMaster`} >Click Here
													</Button>
												</span>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>

							{termFeesForTutionFeesData != '' ?
								<div className='col-md-12 mt-2'>
									<Card shadow='none' borderSize={0} stretch>
										<Label className="col-md-12 p-2 h5">
											<strong className='text-dark'><Icon icon='pending_actions' color='success' className='mx-1' size='2x' />{' '}Tution Fees</strong>
										</Label>

										<div className="table-responsive">
											<table className='table table-modern table-hover text-center'>
												<thead>
													<tr className='table-primary text-center'>
														<th></th>
														<th>TERM </th>
														<th>AMOUNT </th>
													</tr>
												</thead>
												<tbody>
													{termFeesForTutionFeesData.map((termFeesForTution: any) => (
														<tr className='text-center' key={termFeesForTution.termDetailsId}>
															<td width={"20px"}>
																<ChecksGroup>
																	<Checks className='border border-info' checked={termFeesForTution.isChecked} onChange={(e) => addStudentAdvanceFeeForTutionValue(termFeesForTution.termDetailsId, termFeesForTution.feeMasterId, termFeesForTution.feeTypeId, termFeesForTution.standardDetailsId, termFeesForTution.feeAmount)}
																	/>
																</ChecksGroup>
															</td>
															<td>{termFeesForTution.termName}</td>
															<td>{termFeesForTution.feeAmount}</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>

									</Card>
								</div> :
								<div className='text-danger col-12 d-flex justify-content-center'>
									<p className='fw-bold fs-5 mb-0'>{advanceTutionFeesNoData}</p>
								</div>
							}

							{termFeesForTransportFeesData != '' ?
								<div className='col-md-12 mt-2'>
									<Card shadow='none' borderSize={0} stretch>
										<Label className="col-md-12 p-2 h5">
											<strong className='text-dark'><Icon icon='BusAlert' color='success' className='mx-1' size='2x' />{' '}Transport Fees</strong>
										</Label>

										<div className="table-responsive">
											<table className='table table-modern table-hover text-center'>
												<thead>
													<tr className='table-primary text-center'>
														<th></th>
														<th>TERM </th>
														<th>AMOUNT </th>
													</tr>
												</thead>
												<tbody>
													{termFeesForTransportFeesData.map((termFeesForTransport: any) => (
														<tr className='text-center' key={termFeesForTransport.termDetailsId}>
															<td width={"20px"}>
																<ChecksGroup>
																	<Checks className='border border-info' checked={termFeesForTransport.isChecked} onChange={(e) => addStudentAdvanceFeeForTrasportValue(termFeesForTransport.termDetailsId, termFeesForTransport.studentTrasportDetailsId, termFeesForTransport.feeTypeId, termFeesForTransport.standardDetailsId, termFeesForTransport.feeAmount)}
																	/>
																</ChecksGroup>
															</td>
															<td>{termFeesForTransport.termName}</td>
															<td>{termFeesForTransport.feeAmount}</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</Card>
								</div>
								:
								<div className='text-danger col-12 d-flex justify-content-center'>
									<p className='fw-bold fs-5 mb-0'>{advanceTransportFeesNoData}</p>
								</div>
							}
						</div>

					</OffCanvasBody>
					{termFeesForTutionFeesData != '' || termFeesForTransportFeesData != '' ?
						<div className='row m-0'>
							<div className='col-12 p-3'>
								<Button color='info' className='w-100' type="submit" icon="Save" onClick={payAdvanceFeesSubmit}>
									Save
								</Button>
							</div>
						</div> : null}
				</OffCanvas>

				<Modal
					setIsOpen={setIsOpenRejectModal}
					isOpen={isOpenRejectModal}
					titleId='reject'
					isStaticBackdrop
					size='sm'>
					<ModalHeader><></></ModalHeader>
					<ModalBody>
						<div className="row g-3">
							<div className="col-12 d-flex justify-content-center">
								<h5><strong>Are you sure want to reject ?</strong></h5>
							</div>
							<div className='col-10'>
								<div className='d-flex gap-2 justify-content-end'>
									<Button color='danger' size={'sm'} onClick={rejectStudentFeeConcessionSubmit}>
										Yes
									</Button>

									<Button color='info' size={'sm'} onClick={() => setIsOpenRejectModal(!isOpenRejectModal)}>
										No
									</Button>
								</div>
							</div>
							<div className='col-10'></div>
						</div>
					</ModalBody>
				</Modal>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

			</PageWrapper >
		</>
	)
}
export default ConcessionList;