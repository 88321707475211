import { useFormik } from 'formik';
import { useContext, useRef, useState } from 'react';
import NoDataMsg from '../../../common/components/NoDataMsg';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import AuthContext from '../../../contexts/authContext';
import useSortableData from '../../../hooks/useSortableData';
import AlertService from '../../../services/AlertService';
import { getLicenseKey } from '../../../services/application.settings';
import {
	convertDateToEpoch,
	updateFilter,
	getColumnsForDataTable,
	updateColumnsForDataTable,
	getUserType,
} from '../../../services/common.service';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';
import Collapse from '../../bootstrap/Collapse';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Icon from '../../icon/Icon';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import { useReactToPrint } from 'react-to-print';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { toasts } from '../../../services/toast.service';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, {
	CardHeader,
	CardLabel,
	CardTitle,
	CardBody,
	CardActions,
} from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import {
	getStaffAttendanceReportByDate,
	getStaffAttendanceReportByStaffDetailsId,
} from '../../../services/report.service';
import Input from '../../bootstrap/forms/Input';
import { getAcademicPeriod } from '../../../services/master.service';
import { getStaffListByUserTypeId } from '../../../services/staff.service';
import { useParams } from 'react-router-dom';
import SearchableSelect from '../../../common/components/SearchableSelect';

const StaffAttendanceReport = () => {

	const { userType, staffDetails } = useParams();
	const [staffTypeId, setStaffTypeId] = useState<any>('');
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [staffAttendanceData, setStaffAttendanceData] = useState([]);
	const [userTypeData, setUserTypeData] = useState<any>([])
	const [staffDetailsData, setStaffDetailsData] = useState<any>([]);
	const [academicPeriodId, setAcademicPeriodId] = useState<any>('');
	const [staffDetailsId, setStaffDetailsId] = useState<any>('');
	const [dataSuccess, setDataSuccess] = useState(false);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(staffAttendanceData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [isLoader, setIsLoader] = useState(false);

	const [isOpen, setIsOpen] = useState(false);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });

	const [noDataMsg, setNoDataMsg] = useState('');

	const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true);

	const StaffAttendanceForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			fromDate: '',
			toDate: '',
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	const selectAcademicPeriod = (e: any) => {
		setStaffDetailsId('')
		setStaffTypeId('')
		let academicPeriodId = e;
		setAcademicPeriodId(academicPeriodId);
		getUserTypeList();
	};

	const selectUserType = (e: any) => {
		setStaffDetailsId('')
		setDataSuccess(false)
		setIsOpenListCard(true)
		let userTypeId = e;
		setStaffTypeId(userTypeId);
		if (userTypeId?.value != undefined) {
			getStaffListByUserType(userTypeId?.value);
		}
	};

	const selectStaffDetailsId = (e: any) => {
		let staffDetailsId = e;
		setStaffDetailsId(staffDetailsId);
	};

	function ViewStaffAttendanceList() {
		setDataSuccess(false);
		setIsLoader(true);
		let fromDate = StaffAttendanceForm.values.fromDate;
		let toDate = StaffAttendanceForm.values.toDate;

		let fromDateInMilliSec = convertDateToEpoch(fromDate);
		let toDateInMilliSec = convertDateToEpoch(toDate);
		getStaffAttendanceReport(fromDateInMilliSec, toDateInMilliSec);
	}

	function viewStudentClassWiseList() {
		setDataSuccess(false);
		setIsLoader(true);
		if (academicPeriodId?.value != undefined && staffDetailsId?.value != undefined) {
			getStaffAttendanceListByStaffDetailsId(academicPeriodId?.value, staffDetailsId?.value);
		}
	}

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function getStaffAttendanceReport(fromDate: number, toDate: number) {
		setIsLoader(true);
		getStaffAttendanceReportByDate(
			fromDate,
			toDate,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffAttendanceReportByDate;
					if (data != undefined) {
						setIsLoader(false);
						setStaffAttendanceData(data);
						setDataSuccess(true);
						setIsOpenListCard(false);
						getColumnsForTable('getStaffAttendanceReportByDate', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					//toasts(response.data.message, "Error")
					setDataSuccess(true);
					getColumnsForTable('getStaffAttendanceReportByDate', 'get');
					setStaffAttendanceData([]);
					setNoDataMsg(response.data.message);
				} else {
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}
	const [academicPeriodData, setAcademicPeriodData] = useState<any>([]);

	function getAcademicPeriodList() {
		getAcademicPeriod(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.academicPeriod;
					if (data != undefined) {
						setAcademicPeriodData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setAcademicPeriodData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						setUserTypeData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getStaffListByUserType(userTypeId: any) {
		getStaffListByUserTypeId(userAccountId,
			userTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffListByUserTypeId;
					if (data != undefined) {
						setStaffDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setStaffDetailsData([]);
					setStaffDetailsId([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffAttendanceListByStaffDetailsId(academicPeriodId: any, staffDetailsId: any) {
		getStaffAttendanceReportByStaffDetailsId(
			academicPeriodId,
			staffDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffAttendanceReportByStaffDetailsId;
					if (data != undefined) {
						setIsLoader(false);
						setStaffAttendanceData(data);
						setDataSuccess(true);
						setIsOpenListCard(false);
						getColumnsForTable('getStaffAttendanceReportByStaffDetailsId', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					toasts(response.data.message, 'Error');
					setDataSuccess(false);
					getColumnsForTable('getStaffAttendanceReportByStaffDetailsId', 'get');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					toasts(errorDescription, 'Error');
					setDataSuccess(false);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
				setDataSuccess(false);
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						if (isStudentOrClassWise) {
							getColumnsForTable('getStaffAttendanceReportByDate', 'get');
						}
						else {
							getColumnsForTable('getStaffAttendanceReportByStaffDetailsId', 'get');
						}

					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	function selectStudentCard() {
		if (isStudentOrClassWise) {
			getAcademicPeriodList();
		}
		setIsStudentOrClassWise(!isStudentOrClassWise);
		setDataSuccess(false);
		setAcademicPeriodId('');
		setStaffDetailsId('');
		setStaffTypeId('')
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			<PageWrapper title='Staff Attendance Report'>
				<Page container='fluid'>
					<Collapse isOpen={isOpenListCard}>
						<Card>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info' className='col-lg-6'>

									<CardTitle tag='div' className='h5'>

										Staff Attendance Report
									</CardTitle>
								</CardLabel>
								<CardActions className='content-justify-end'>
									<Button
										icon='CalendarToday'
										color='info'
										isLight={isStudentOrClassWise ? false : true}
										onClick={selectStudentCard}>
										Date Wise
									</Button>

									<Button
										icon='PeopleAlt'
										color='info'
										isLight={isStudentOrClassWise ? true : false}
										onClick={selectStudentCard}>
										Staff Wise
									</Button>
								</CardActions>
							</CardHeader>
							<CardBody>
								{isStudentOrClassWise ? (
									<div className='row'>
										<div className='col-3'>
											<FormGroup id='fromDate' label='From Date' isFloating>
												<Input
													type='date'
													onChange={StaffAttendanceForm.handleChange}
													value={StaffAttendanceForm.values.fromDate}
												/>
											</FormGroup>
										</div>
										<div className='col-3'>
											<FormGroup id='toDate' label='To Date' isFloating>
												<Input
													type='date'
													onChange={StaffAttendanceForm.handleChange}
													value={StaffAttendanceForm.values.toDate}
												/>
											</FormGroup>
										</div>
										<div className='col-2 mt-2'>
											<Button
												icon='ArrowDownward'
												color='primary'
												isDisable={
													StaffAttendanceForm.values.fromDate == '' ||
													StaffAttendanceForm.values.toDate == ''
												}
												onClick={ViewStaffAttendanceList}>
												View
											</Button>
										</div>
									</div>
								) : (
									<div className='row'>
										<div className='col-md-3'>
											<FormGroup id='academicPeriodId' label='Academic Period' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Academic Period'
													placeholder='Academic Period'
													value={academicPeriodId}
													onChange={selectAcademicPeriod}
													list={academicPeriodData.map((data: any) => ({
														value: data.academicPeriodId, label: data.period
													}))}
													required
												/>
											</FormGroup>
										</div>
										{academicPeriodId?.value != undefined ? (
											<div className='col-3'>
												<FormGroup
													id='userTypeId'
													label='Staff Type'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='Staff Type'
														placeholder='Select Staff Type'
														onChange={selectUserType}
														value={staffTypeId}
														list={userTypeData.map((data: any) => ({
															value: data.userTypeId,
															label: data.userType,
														}))}
													/>
												</FormGroup>
											</div>) : null}

										{staffTypeId?.value != undefined ? (
											<div className='col-4'>
												<FormGroup
													id='staffDetailsId'
													label='Staff'
													isFloating>
													<SearchableSelect
														isFloating
														ariaLabel='Staff'
														placeholder='Select Staff'
														onChange={(e: any) =>
															selectStaffDetailsId(e)
														}
														value={staffDetailsId}
														list={staffDetailsData.map((data: any) => ({
															value: data.staffDetailsId,
															label: data.empAndStaffName,
														}))}
													/>
												</FormGroup>
											</div>
										) : null}

										{staffDetailsId?.value != undefined ? (
											<div className='col-2'>
												<Button
													className='mt-2'
													icon='ArrowDownward'
													color='primary'
													onClick={viewStudentClassWiseList}>
													View
												</Button>
											</div>
										) : null}
									</div>
								)}
							</CardBody>
						</Card>
					</Collapse>
					<OpenCardComponent
						isOpenListCard={isOpenListCard}
						setIsOpenListCard={setIsOpenListCard}
						isLoader={isLoader}
					/>
					{dataSuccess ? (
						<Card stretch data-tour='list' ref={componentRef} id='pdf'>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info' className='col-lg-5'>
									<CardTitle tag='div' className='h5'>
										Staff Attendance Report
									</CardTitle>
								</CardLabel>
								&nbsp;&nbsp;
								<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
									<Icon
										className='mt-1'
										icon='Search'
										size='2x'
										color='primary'
									/>
									<Input
										id='searchInput'
										type='search'
										placeholder='Search...'
										onChange={columnVisibilityForm.handleChange}
										value={columnVisibilityForm.values.searchInput}
									/>
								</ButtonGroup>
								<CardActions className='d-print-none'>
									<Dropdown
										isOpen={columnVisibilityMenu}
										setIsOpen={setColumnVisibilityMenu}
										isButtonGroup>
										<DropdownToggle>
											<Button icon='FilterAlt' color='primary' isLight>
												Filter
											</Button>
										</DropdownToggle>
										<DropdownMenu
											isAlignmentEnd
											size='lg'
											isCloseAfterLeave={false}>
											<DropdownItem>
												<div className='container py-2'>
													<form className='row g-3'>
														<div className='col-12'>
															<FormGroup>
																<h6>Select All</h6>
																<Checks
																	id='available'
																	type='switch'
																	label='Select All Columns'
																	onChange={() =>
																		getColumnsForTable(
																			isStudentOrClassWise ?
																				'getStaffAttendanceReportByDate' : 'getStaffAttendanceReportByStaffDetailsId',
																			'post',
																		)
																	}
																	checked={isChecked}
																	ariaLabel='Available status'
																/>
															</FormGroup>
														</div>
														<div
															className='col-12'
															style={{
																maxHeight: '200px',
																overflowY: 'scroll',
															}}>
															<FormGroup>
																<h6>Columns</h6>
																<ChecksGroup>
																	{allColumnsData.map(
																		(i: any) => (
																			<Checks
																				key={
																					i.columnVisibilityId
																				}
																				label={i.columnName}
																				onChange={() =>
																					updateColumnsSubmit(
																						i.columnVisibilityId,
																						i.isDisplay,
																						'',
																					)
																				}
																				checked={
																					i.isDisplay
																				}
																				disabled={
																					i.isDefault ==
																					true
																				}
																			/>
																		),
																	)}
																</ChecksGroup>
															</FormGroup>
														</div>
													</form>
												</div>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									<Dropdown isButtonGroup>
										<DropdownToggle>
											<Button color='primary' isLight icon='CloudDownload'>
												{' '}
												Export{' '}
											</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadExcel(
															'StaffAttendanceReport',
															staffAttendanceData,
															columnVisibilityData,
														)
													}>
													{' '}
													Excel{' '}
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='PictureAsPdf'
													onClick={() =>
														convertJsonToPdf(
															staffAttendanceData,
															columnVisibilityData,
															'StaffAttendanceReport',
														)
													}>
													{' '}
													PDF
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadFile(
															'StaffAttendanceReport',
															staffAttendanceData,
															columnVisibilityData,
														)
													}>
													{' '}
													CSV{' '}
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='Print'
													onClick={handlePrint}>
													{' '}
													Print{' '}
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive' isScrollable>
								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th
												scope='col'
												onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>
												S.No{' '}
												<Icon
													size='lg'
													className={getClassNamesFor('sno')}
													icon='FilterList'
												/>
											</th>
											{columnVisibilityData.map((column: any) => (
												<th
													key={column.keyName}
													scope='col'
													onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon
														size='lg'
														className={getClassNamesFor(column.keyName)}
														icon='FilterList'
													/>
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{filteredData != '' ? (
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.staffAttendanceId}>
														<td>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) =>
															column.isDisplay &&
																rowData[column.keyName] ? (
																<td
																	key={`${rowData.staffAttendanceId}-${column.keyName}`}>
																	{rowData[column.keyName]}
																</td>
															) : (
																<td
																	key={`empty-${column.keyName}`}
																/>
															),
														)}
													</tr>
												))}{' '}
											</>
										) : (
											<NoDataMsg
												columnsCount={columnVisibilityData.length + 2}
												msg={noDataMsg}
											/>
										)}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								className='d-print-none'
								data={items}
								label='items'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</Card>
					) : null}
				</Page>
			</PageWrapper>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</>
	);
};
export default StaffAttendanceReport;
