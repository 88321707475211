import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Calendar, dayjsLocalizer, View as TView, Views } from 'react-big-calendar';
import { useFormik } from 'formik';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import eventList, { IEvents } from '../../../common/data/events';
import USERS, { getUserDataWithUsername, IUserProps } from '../../../common/data/userDummyData';
import useMinimizeAside from '../../../hooks/useMinimizeAside';
import {
	CalendarTodayButton,
	CalendarViewModeButtons,
	getLabel,
	getUnitType,
	getViews,
} from '../../extras/calendarHelper';
import { demoPagesMenu } from '../../../menu';
import { getServiceDataWithServiceName } from '../../../common/data/serviceDummyData';
import useDarkMode from '../../../hooks/useDarkMode';
import { TColor } from '../../../type/color-type';
import Button from '../../bootstrap/Button';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import SearchableSelect from '../../../common/components/SearchableSelect';
import FormGroup from '../../bootstrap/forms/FormGroup';
import OffCanvas, {
	OffCanvasHeader,
	OffCanvasTitle,
	OffCanvasBody,
} from '../../bootstrap/OffCanvas';
import Input from '../../bootstrap/forms/Input';
import { getCalenderEventType, getCurrentDateAndTime } from '../../../services/common.service';
import { toasts } from '../../../services/toast.service';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import { showLoader } from '../../../services/loader.services';
import {
	addCalenderEventForStaff,
	deleteCalenderEventForStaff,
	getCalenderEventForStaff,
	updateCalenderEventForStaff,
} from '../../../services/staff.service';
import AlertService from '../../../services/AlertService';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../bootstrap/Dropdown';
import Avatar, { AvatarGroup } from '../../Avatar';
import Icon from '../../icon/Icon';
import DeleteComponents from '../../../common/components/DeleteComponents';

const localizer = dayjsLocalizer(dayjs);
const now = new Date();

interface IEvent extends IEvents {
	user?: IUserProps;
	users?: IUserProps[];
	color?: TColor;
}

const MyEvent = (data: { event: IEvent }) => {
	const { darkModeStatus } = useDarkMode();
	const { event } = data;

	return (
		<div className='row g-2'>
			<div className='col-auto'>
				<div className='row g-1 align-items-baseline'>
					<small
						className={classNames('col-auto text-truncate', {
							'text-primary': !darkModeStatus,
							'text-white': darkModeStatus,
						})}>
						{event?.name}
						<br />
						<span className='text-dark'>{event?.particulars}</span>
					</small>
				</div>
			</div>
		</div>
	);
};

const MyWeekEvent = (data: { event: IEvent }) => {
	const { darkModeStatus } = useDarkMode();

	const { event } = data;
	return (
		<div className='row g-2'>
			<div className='col-12 text-truncate'>{event?.name}</div>
			<div className='col-12'>
				<div className='row g-1 align-items-baseline'>
					<div className='col-auto text-dark'>{event?.particulars}</div>
				</div>
			</div>
		</div>
	);
};

const CalenderViewForStaff = () => {
	const [addTaskOffCanvas, setAddTaskOffCanvas] = useState(false);
	const [calenderEventTypeId, setCalenderEventTypeId] = useState<any>('');
	const [calenderEventTypeData, setCalenderEventTypeData] = useState<any>([]);
	const { userAccountId, userTypeId } = useContext(AuthContext);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);

	const { darkModeStatus, themeStatus } = useDarkMode();
	// useMinimizeAside();

	const [toggleRightPanel, setToggleRightPanel] = useState(true);

	const [employeeList, setEmployeeList] = useState({
		[USERS.JOHN.username]: true,
		[USERS.ELLA.username]: true,
		[USERS.RYAN.username]: true,
		[USERS.GRACE.username]: true,
	});

	const [events, setEvents] = useState(eventList);

	useEffect(() => {
		getCalenderEventTypeList();
		setEvents(eventList);
		getCalenderEventList();
		return () => {};
	}, []);

	const initialEventItem: IEvent = {
		start: undefined,
		end: undefined,
		name: undefined,
		id: undefined,
		user: undefined,
	};
	// Selected Event
	const [eventItem, setEventItem] = useState<IEvent>(initialEventItem);
	// Calendar View Mode
	const [viewMode, setViewMode] = useState<TView>(Views.MONTH);
	// Calendar Date
	const [date, setDate] = useState(new Date());
	// Item edit panel status
	const [toggleInfoEventCanvas, setToggleInfoEventCanvas] = useState<boolean>(false);
	const setInfoEvent = () => setToggleInfoEventCanvas(!toggleInfoEventCanvas);
	const [eventAdding, setEventAdding] = useState<boolean>(false);
	const [calenderEventStaffId, setCalenderEventStaffId] = useState<any>('');

	// Calendar Unit Type
	const unitType = getUnitType(viewMode);
	// Calendar Date Label
	const calendarDateLabel = getLabel(date, viewMode);

	// Change view mode
	const handleViewMode = (e: dayjs.ConfigType) => {
		setDate(dayjs(e).toDate());
		setViewMode(Views.DAY);
	};
	// View modes; Month, Week, Work Week, Day and Agenda
	const views = getViews();

	// New Event
	const handleSelect = ({ start, end }: { start: any; end: any }) => {
		setEventAdding(true);
		setEventItem({ ...initialEventItem, start, end });
	};

	useEffect(() => {
		if (eventAdding) {
			setInfoEvent();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventAdding]);

	/**
	 * Calendar Item Container Style
	 * @param event
	 * @param start
	 * @param end
	 * @param isSelected
	 * @returns {{className: string}}
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const eventStyleGetter = (
		event: { color?: TColor },
		start: any,
		end: any,
		isSelected: boolean,
	) => {
		const isActiveEvent = start <= now && end >= now;
		const isPastEvent = end < now;
		const color = isActiveEvent ? 'success' : event.color;

		return {
			className: classNames({
				[`bg-l${darkModeStatus ? 'o25' : '10'}-${color} text-${color}`]: color,
				'border border-success': isActiveEvent,
				'opacity-50': isPastEvent,
			}),
		};
	};

	const formik = useFormik({
		initialValues: {
			eventStart: '',
			eventEnd: '',
			calenderEventTypeId: '',
			particulars: '',
		},
		validate: (values) => {
			const errors: {
				calenderEventTypeId?: string;
				particulars?: string;
			} = {};
			if (!calenderEventTypeId?.value) {
				errors.calenderEventTypeId = 'Required';
			}
			if (!values.particulars) {
				errors.particulars = 'Required';
			}
			return errors;
		},
		onSubmit: (values) => {
			eventAdding ? addCalenderEventForStaffSubmit() : updateCalenderEventForStaffSubmit();
		},
	});



	useEffect(() => {
		if (eventItem)
			if (eventItem?.calenderEventTypeId != undefined) {
				setCalenderEventStaffId(eventItem?.calenderEventStaffId);
				setCalenderEventTypeId({
					value: eventItem?.calenderEventTypeId,
					label: eventItem?.eventType,
				});
			}

		formik.setValues({
			...formik.values,
			// @ts-ignore
			eventId: eventItem.id || null,
			eventName: eventItem.name || '',
			eventStart: dayjs(eventItem.start).format(),
			eventEnd: dayjs(eventItem.end).format(),
			eventEmployee: eventItem?.user?.username || '',
			calenderEventTypeId:
				eventItem?.calenderEventTypeId != undefined ? eventItem?.calenderEventTypeId : '',
			particulars: eventItem?.particulars != undefined ? eventItem?.particulars : '',
		});
		return () => {};
	}, [eventItem]);

	const assignmentTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			calenderEventTypeId: '',
			particulars: '',
			eventStart: getCurrentDateAndTime('date'),
			eventEnd: getCurrentDateAndTime('date'),
		},
		validate: (values) => {
			const errors: {
				calenderEventTypeId?: string;
				particulars?: string;
				eventStart?: string;
				eventEnd?: string;
			} = {};
			if (!calenderEventTypeId?.value) {
				errors.calenderEventTypeId = 'Required';
			}
			if (!values.particulars) {
				errors.particulars = 'Required';
			}
			if (!values.eventStart) {
				errors.eventStart = 'Required';
			}
			if (!values.eventEnd) {
				errors.eventEnd = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			addCalenderEventForStaffSubmit();
		},
	});

  const editCalenderForm = useFormik({
		initialValues: {
			eventStart: '',
			eventEnd: '',
			calenderEventTypeId: '',
			particulars: '',
		},
		validate: (values) => {
			const errors: {
				calenderEventTypeId?: string;
				particulars?: string;
			} = {};
			if (!calenderEventTypeId?.value) {
				errors.calenderEventTypeId = 'Required';
			}
			if (!values.particulars) {
				errors.particulars = 'Required';
			}
			return errors;
		},
		onSubmit: () => {
			updateCalenderEventForStaffSubmit();
		},
	});

	function selectEventType(e: any) {
		let calenderEventTypeId = e;
		setCalenderEventTypeId(calenderEventTypeId);
	}

	function getCalenderEventTypeList() {
		getCalenderEventType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.calenderEventType;
					if (data != undefined) {
						setCalenderEventTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setCalenderEventTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function setEventDetails() {
		return {
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
			userTypeId: userTypeId,
			calenderEventTypeId: calenderEventTypeId?.value,
			particulars:
				formik.values.particulars != ''
					? formik.values.particulars
					: assignmentTypeForm.values.particulars,
			eventStart: dayjs(formik.values.eventStart).format('YYYY-MM-DDTHH:mm'),
			eventEnd: dayjs(formik.values.eventEnd).format('YYYY-MM-DDTHH:mm'),
		};
	}

	function setEditEventDetails() {
		return {
			licenseKey: getLicenseKey,
			staffDetailsId: userAccountId,
			calenderEventStaffId: calenderEventStaffId,
			calenderEventTypeId: calenderEventTypeId?.value,
			particulars:
				formik.values.particulars != ''
					? formik.values.particulars
					: assignmentTypeForm.values.particulars,
			eventStart: dayjs(formik.values.eventStart).format('YYYY-MM-DDTHH:mm'),
			eventEnd: dayjs(formik.values.eventEnd).format('YYYY-MM-DDTHH:mm'),
		};
	}

	function addCalenderEventForStaffSubmit() {
		showLoader(true);
		if (assignmentTypeForm.isValid) {
			let addEventPostData = setEventDetails();
			addCalenderEventForStaff(
				addEventPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getCalenderEventList();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (assignmentTypeForm.isValid) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function updateCalenderEventForStaffSubmit() {
		showLoader(true);
		if (assignmentTypeForm.isValid) {
			let eventPostData = setEditEventDetails();
			updateCalenderEventForStaff(
				eventPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getCalenderEventList();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (assignmentTypeForm.isValid) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function deleteStaffCalenderSubmit() {
		showLoader(true);
		let eventPostData = setEditEventDetails();
		deleteCalenderEventForStaff(
			eventPostData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'success' });
					setIsOpen(true);
					getCalenderEventList();
					closeAndReset();
				} else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'error' });
					setIsOpen(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					showLoader(false);
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				showLoader(false);
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
		// } else if (editStaffLoginForm.isValid == false) {
		//     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
		//     setIsOpen(true);
		// }
	}

	const [calenderDetailsData, setCalenderDetailsData] = useState<any>([]);

	function getCalenderEventList() {
		getCalenderEventForStaff(
			userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.calenderEventForStaff[0].eventDetails;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							const eventStart = new Date(data[i].eventStart);
							const eventEnd = new Date(data[i].eventEnd);
							data[i].color = 'info';
							data[i].end = eventEnd;
							data[i].icon = 'List';
							data[i].id = data[i].calenderEventStudentId;
							data[i].name = data[i].eventType;
							data[i].start = eventStart;
						}

						setCalenderDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setCalenderDetailsData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function closeAndReset() {
		setCalenderEventTypeId('');
		setAddTaskOffCanvas(false);
    setIsDeleteOpen(false)
		assignmentTypeForm.resetForm();
    editCalenderForm.resetForm();
		formik.resetForm();
	}

	return (
		<PageWrapper title={'Calender'}>
			<SubHeader>
				<SubHeaderLeft>
					<CardLabel icon='TaskAlt' iconColor='info'>
						<CardTitle tag='div' className='h5'>
							Calender
						</CardTitle>
					</CardLabel>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button
						type='submit'
						color='info'
						isLight
						icon='AddTask'
						onClick={() => {
							closeAndReset();
							setAddTaskOffCanvas(true);
						}}>
						Add Task
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
				<div className='row h-100'>
					<div
						className={classNames({
							'col-xxl-8': !toggleRightPanel,
							'col-12': toggleRightPanel,
						})}>
						<Card stretch style={{ minHeight: 680 }}>
							<CardHeader>
								<CardActions>
									<CalendarTodayButton
										unitType={unitType}
										date={date}
										setDate={setDate}
										viewMode={viewMode}
									/>
								</CardActions>
								<CardActions>
									<CalendarViewModeButtons
										setViewMode={setViewMode}
										viewMode={viewMode}
									/>
								</CardActions>
							</CardHeader>
							<CardBody isScrollable>
								<Calendar
									selectable
									toolbar={false}
									localizer={localizer}
									events={calenderDetailsData}
									defaultView={Views.WEEK}
									views={views}
									view={viewMode}
									date={date}
									onNavigate={(_date) => setDate(_date)}
									scrollToTime={new Date(1970, 1, 1, 6)}
									defaultDate={new Date()}
									onSelectEvent={(event) => {
										setInfoEvent();
										setEventItem(event);
									}}
									onSelectSlot={handleSelect}
									onView={handleViewMode}
									onDrillDown={handleViewMode}
									components={{
										event: MyEvent,
										week: {
											event: MyWeekEvent,
										},
										work_week: {
											event: MyWeekEvent,
										},
									}}
									eventPropGetter={eventStyleGetter}
								/>
							</CardBody>
						</Card>
					</div>
				</div>

				<OffCanvas
					setOpen={setAddTaskOffCanvas}
					isOpen={addTaskOffCanvas}
					titleId='addTaskOffCanvas'
					isBodyScroll
					tag='form'
					noValidate
					onSubmit={assignmentTypeForm.handleSubmit}
					isNotClose>
					<OffCanvasHeader
						setOpen={setAddTaskOffCanvas}
						onClick={() => {
							setAddTaskOffCanvas(false);
							closeAndReset();
						}}>
						<OffCanvasTitle id='addTaskOffCanvas'>Add Task </OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-3'>
							<div className='col-12'>
								<FormGroup id='calenderEventTypeId' label='Event Type'>
									<SearchableSelect
										ariaLabel=''
										placeholder='Select Event Type'
										onChange={selectEventType}
										value={calenderEventTypeId}
										onBlur={assignmentTypeForm.handleBlur}
										isValid={assignmentTypeForm.isValid}
										isTouched={assignmentTypeForm.touched.calenderEventTypeId}
										invalidFeedback={
											assignmentTypeForm.errors.calenderEventTypeId
										}
										list={calenderEventTypeData.map((data: any) => ({
											value: data.calenderEventTypeId,
											label: data.eventType,
										}))}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='particulars' label='Particulars'>
									<Input
										onChange={assignmentTypeForm.handleChange}
										value={assignmentTypeForm.values.particulars}
										onBlur={assignmentTypeForm.handleBlur}
										isValid={assignmentTypeForm.isValid}
										isTouched={assignmentTypeForm.touched.particulars}
										invalidFeedback={assignmentTypeForm.errors.particulars}
										placeholder='Enter Particulars'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<Card className='mb-0 bg-l10-info' shadow='sm'>
									<CardHeader className='bg-l25-info'>
										<CardLabel icon='DateRange' iconColor='info'>
											<CardTitle className='text-info'>
												Date Options
											</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<div className='row g-3'>
											<div className='col-12'>
												<FormGroup id='eventStart' label={'Start Date'}>
													<Input
														type={'datetime-local'}
														value={dayjs(
															formik.values.eventStart,
														).format('YYYY-MM-DDTHH:mm')}
														onChange={formik.handleChange}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup id='eventEnd' label='End Date'>
													<Input
														type='datetime-local'
														value={dayjs(formik.values.eventEnd).format(
															'YYYY-MM-DDTHH:mm',
														)}
														onChange={formik.handleChange}
													/>
												</FormGroup>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row m-0'>
						<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								isDisable={
									!assignmentTypeForm.isValid && !!assignmentTypeForm.submitCount
								}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<OffCanvas
					setOpen={(status: boolean) => {
						setToggleInfoEventCanvas(status);
						setEventAdding(status);
						closeAndReset();
					}}
					isOpen={toggleInfoEventCanvas}
					titleId='canvas-title'>
					<OffCanvasHeader
						setOpen={() => {
							setToggleInfoEventCanvas(!toggleInfoEventCanvas);
							setEventAdding(true);
							closeAndReset();
						}}>
						<OffCanvasTitle id='canvas-title'>
							{eventAdding ? 'Add Task' : 'Edit Task'}
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-3'>
							<div className='col-12'>
								<FormGroup id='calenderEventTypeId' label='Event Type'>
									<SearchableSelect
										ariaLabel=''
										placeholder='Select Event Type'
										onChange={selectEventType}
										value={calenderEventTypeId}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched.calenderEventTypeId}
										invalidFeedback={formik.errors.calenderEventTypeId}
										list={calenderEventTypeData.map((data: any) => ({
											value: data.calenderEventTypeId,
											label: data.eventType,
										}))}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='particulars' label='Particulars'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.particulars}
										onBlur={formik.handleBlur}
										isValid={formik.isValid}
										isTouched={formik.touched.particulars}
										invalidFeedback={formik.errors.particulars}
										placeholder='Enter Particulars'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<Card className='mb-0 bg-l10-info' shadow='sm'>
									<CardHeader className='bg-l25-info'>
										<CardLabel icon='DateRange' iconColor='info'>
											<CardTitle className='text-info'>
												Date Options
											</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<div className='row g-3'>
											<div className='col-12'>
												<FormGroup id='eventStart' label={'Start Date'}>
													<Input
														type={'datetime-local'}
														value={dayjs(
															formik.values.eventStart,
														).format('YYYY-MM-DDTHH:mm')}
														onChange={formik.handleChange}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup id='eventEnd' label='End Date'>
													<Input
														type='datetime-local'
														value={dayjs(formik.values.eventEnd).format(
															'YYYY-MM-DDTHH:mm',
														)}
														onChange={formik.handleChange}
													/>
												</FormGroup>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row m-0'>
						<div className='d-flex justify-content-center position-absolute top-65 start-50 translate-middle'>
							<DeleteComponents
								isDeleteOpen={isDeleteOpen}
								setIsDeleteOpen={setIsDeleteOpen}
								deleteFunction={deleteStaffCalenderSubmit}
							/>
						</div>
						<div className='col-6 position-absolute bottom-0 start-0 p-3'>
							<Button
								color='danger'
								className='w-100'
								icon='Delete'
								onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
								Delete
							</Button>
						</div>
						<div className='col-6 position-absolute bottom-0 end-0 p-3'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								onClick={formik.handleSubmit}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</Page>
		</PageWrapper>
	);
};

export default CalenderViewForStaff;
